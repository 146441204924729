import { IChip } from "@/components/Chips/Chip";
import { CompetenceChip } from "@/components/Chips/CompetenceChip";
import { IMedicalCompetence, IScheduledShift, shiftName } from "@models/shifts";

export const AssignedShiftChip = ({
  shift,
  medicalCompetence,
  onRemove,
  size = "medium",
  disabled = false,
}: {
  shift: IScheduledShift;
  medicalCompetence: IMedicalCompetence;
  onRemove?: () => void;
  disabled?: boolean;
} & Pick<IChip, "size">) => {
  const content = shiftName({
    employee: shift.employee,
    competence: medicalCompetence,
    options: { length: "short" },
  });

  return (
    <CompetenceChip
      competence={medicalCompetence}
      state="assigned"
      content={content}
      onRemove={onRemove}
      size={size}
      disabled={disabled}
    />
  );
};
