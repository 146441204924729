import React from "react";
import styles from "./TabList.module.scss";

interface ITabList {
  children: JSX.Element[];
  stateOwner?: "url" | "react";
}

const InnerContent = ({ children }: Pick<ITabList, "children">) => {
  return (
    <ul className={styles.tabList}>
      {React.Children.map(children, (child, index) => {
        const isProbablyReactFragment = typeof child.type === "symbol";
        return isProbablyReactFragment ? <></> : <li key={index}>{child}</li>;
      })}
    </ul>
  );
};

const TabList = ({ children, stateOwner = "url" }: ITabList) => {
  return stateOwner === "url" ? (
    <nav className={styles.tabListWrapper}>
      <InnerContent>{children}</InnerContent>
    </nav>
  ) : (
    <div className={styles.tabListWrapper}>
      <InnerContent>{children}</InnerContent>
    </div>
  );
};

export default TabList;
