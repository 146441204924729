import { VISIT_KEY_ROOT, routeKeys, visitKeys } from "@/api/Routes";
import { activityKeys, activityOccurrenceAndGroupKeys } from "@/api/Activities";
import { useEventSource } from "@/Utils/sse";
import { useQueryClient } from "@tanstack/react-query";
import { EventSourceMessage } from "@microsoft/fetch-event-source";
import { z } from "zod";
import { useState } from "react";
import { patientsWithUnhandledMeasurementsQueryOptions } from "@/api/Patients";
import { shiftKeys } from "@/api/Shifts";

const routeSchema = z.object({
  routeId: z.string().uuid(),
});
const visitSchema = z.object({
  routeId: z.string().uuid(),
  visitId: z.string(),
});
const activitySchema = z.object({
  activityId: z.string().uuid(),
});
const activityOccurrenceSchema = z.object({
  activityId: z.string().uuid(),
  activityOccurrenceId: z.string().uuid(),
});
const activityOccurrenceAssignedSchema = z.object({
  activityId: z.string().uuid(),
  activityOccurrenceId: z.string().uuid(),
  routeId: z.string().uuid(),
  visitId: z.string(),
});
const allocationToActivityOccurrenceSchema = z.object({
  activityId: z.string().uuid(),
  activityOccurrenceId: z.string().uuid(),
  // Discrimiated union, choosing not to use for now.
  assignee: z.unknown(),
});

export const useGoLogisticsSSEHandlers = () => {
  const queryClient = useQueryClient();
  // https://react.dev/reference/react/useMemo#caveats says to prefer useState for safely persisting state between renders: https://react.dev/reference/react/useState#avoiding-recreating-the-initial-state
  const [eventHandlers] = useState(() => [
    // routeCreated is not relevant in Go.
    {
      relevantEvents: ["routeRemoved"],
      handler: (message: EventSourceMessage) => {
        const { routeId } = routeSchema.parse(JSON.parse(message.data));
        queryClient.invalidateQueries({ queryKey: routeKeys.lists() });
        queryClient.invalidateQueries({ queryKey: routeKeys.detail(routeId) });
      },
    },
    {
      relevantEvents: ["visitOrderChanged"],
      handler: (message: EventSourceMessage) => {
        const { routeId } = routeSchema.parse(JSON.parse(message.data));
        queryClient.invalidateQueries({ queryKey: routeKeys.detail(routeId) });
      },
    },
    {
      relevantEvents: ["routeFinished"],
      handler: (message: EventSourceMessage) => {
        const { routeId } = routeSchema.parse(JSON.parse(message.data));
        queryClient.invalidateQueries({ queryKey: routeKeys.lists() });
        queryClient.invalidateQueries({ queryKey: routeKeys.detail(routeId) });
      },
    },
    {
      relevantEvents: ["routeNameChanged"],
      handler: (message: EventSourceMessage) => {
        const { routeId } = routeSchema.parse(JSON.parse(message.data));
        queryClient.invalidateQueries({ queryKey: routeKeys.lists() });
        queryClient.invalidateQueries({ queryKey: routeKeys.detail(routeId) });
      },
    },
    // visitCreated is not relevant in Go.
    {
      relevantEvents: ["visitRemoved"],
      handler: (message: EventSourceMessage) => {
        const { routeId, visitId } = visitSchema.parse(
          JSON.parse(message.data),
        );
        queryClient.invalidateQueries({ queryKey: routeKeys.detail(routeId) });
        queryClient.invalidateQueries({
          queryKey: visitKeys(routeId).detail(visitId),
        });
        queryClient.invalidateQueries({ queryKey: visitKeys(routeId).lists() });
      },
    },
    {
      relevantEvents: ["travelToVisitStarted"],
      handler: (message: EventSourceMessage) => {
        const { routeId, visitId } = visitSchema.parse(
          JSON.parse(message.data),
        );
        // VisitCard displays `ongoing` based on data from route, so we need to invalidate the whole route
        queryClient.invalidateQueries({ queryKey: routeKeys.detail(routeId) });
        queryClient.invalidateQueries({
          queryKey: visitKeys(routeId).detail(visitId),
        });
        queryClient.invalidateQueries({ queryKey: visitKeys(routeId).lists() });
      },
    },
    {
      relevantEvents: ["visitStarted"],
      handler: (message: EventSourceMessage) => {
        const { routeId, visitId } = visitSchema.parse(
          JSON.parse(message.data),
        );
        // VisitCard displays ongoing `trip/visit` based on data from route, so we need to invalidate the whole route
        queryClient.invalidateQueries({ queryKey: routeKeys.detail(routeId) });
        queryClient.invalidateQueries({
          queryKey: visitKeys(routeId).detail(visitId),
        });
        queryClient.invalidateQueries({ queryKey: visitKeys(routeId).lists() });
      },
    },
    {
      relevantEvents: ["visitFinished"],
      handler: (message: EventSourceMessage) => {
        const { routeId, visitId } = visitSchema.parse(
          JSON.parse(message.data),
        );
        // VisitCard displays ongoing `trip/visit` based on data from route, so we need to invalidate the whole route
        queryClient.invalidateQueries({ queryKey: routeKeys.detail(routeId) });
        queryClient.invalidateQueries({
          queryKey: visitKeys(routeId).detail(visitId),
        });
        queryClient.invalidateQueries({ queryKey: visitKeys(routeId).lists() });
      },
    },
    {
      relevantEvents: ["travellingToVisitReverted"],
      handler: (message: EventSourceMessage) => {
        const { routeId, visitId } = visitSchema.parse(
          JSON.parse(message.data),
        );
        // VisitCard displays ongoing `trip/visit` based on data from route, so we need to invalidate the whole route
        queryClient.invalidateQueries({ queryKey: routeKeys.detail(routeId) });
        queryClient.invalidateQueries({
          queryKey: visitKeys(routeId).detail(visitId),
        });
        queryClient.invalidateQueries({ queryKey: visitKeys(routeId).lists() });
      },
    },
    // homeVisitActivityRegistered is not relevant in Go.
    // videoCallActivityRegistered is not relevant in Go.
    // patientTaskActivityRegistered is not relevant in Go.
    // patientMeasurementTaskActivityRegistered is not relevant in Go.
    // adminTaskActivityRegistered is not relevant in Go.

    // Consider https://linear.app/medoma/issue/MED-2061/granular-cache-invalidation#comment-88bdfa24
    // Leave for now.
    // "activityVisibilitySet"
    // "titleChanged"
    // "descriptionChanged"
    // "durationChanged"

    // activityEndDateSet is not relevant in Go.
    // activityClosed is not relevant in Go.
    // activityOccurrenceAssigned is not relevant in Go.
    // activityOccurrenceUnassigned is not relevant in Go.
    {
      relevantEvents: ["occurrenceAllocated"],
      handler: (message: EventSourceMessage) => {
        const { activityId, activityOccurrenceId } =
          activityOccurrenceSchema.parse(JSON.parse(message.data));
        queryClient.invalidateQueries({
          queryKey: activityOccurrenceAndGroupKeys.lists(),
        });
        queryClient.invalidateQueries({
          queryKey: activityOccurrenceAndGroupKeys.detail(
            `${activityId}${activityOccurrenceId}`,
          ),
        });
        queryClient.invalidateQueries({
          queryKey: routeKeys.all,
        });
      },
    },
    {
      relevantEvents: ["occurrenceDeallocated"],
      handler: (message: EventSourceMessage) => {
        const { activityId, activityOccurrenceId } =
          activityOccurrenceSchema.parse(JSON.parse(message.data));
        queryClient.invalidateQueries({
          predicate: (query) => {
            const stringifiedQueryData = JSON.stringify(query.state.data);
            return (
              stringifiedQueryData.includes(activityOccurrenceId) &&
              stringifiedQueryData.includes(activityId)
            );
          },
        });
      },
    },
    // occurrenceRemoved is not relevant in Go.
    {
      relevantEvents: ["occurrenceReset"],
      handler: (message: EventSourceMessage) => {
        const { activityId, activityOccurrenceId } =
          activityOccurrenceSchema.parse(JSON.parse(message.data));
        queryClient.invalidateQueries({
          queryKey: activityOccurrenceAndGroupKeys.detail(
            `${activityId}${activityOccurrenceId}`,
          ),
        });
        queryClient.invalidateQueries({
          predicate: (query) => {
            const stringifiedQueryData = JSON.stringify(query.state.data);
            return (
              query.queryKey.includes(VISIT_KEY_ROOT) &&
              stringifiedQueryData.includes(activityOccurrenceId) &&
              stringifiedQueryData.includes(activityId)
            );
          },
        });
      },
    },
    {
      relevantEvents: ["occurrenceFinished"],
      handler: (message: EventSourceMessage) => {
        const { activityId, activityOccurrenceId } =
          activityOccurrenceSchema.parse(JSON.parse(message.data));
        queryClient.invalidateQueries({
          queryKey: activityOccurrenceAndGroupKeys.detail(
            `${activityId}${activityOccurrenceId}`,
          ),
        });
        queryClient.invalidateQueries({
          predicate: (query) => {
            const stringifiedQueryData = JSON.stringify(query.state.data);
            return (
              query.queryKey.includes(VISIT_KEY_ROOT) &&
              stringifiedQueryData.includes(activityOccurrenceId) &&
              stringifiedQueryData.includes(activityId)
            );
          },
        });
      },
    },
    // occurrenceCreated is not relevant in Go.
    {
      relevantEvents: ["occurrenceRescheduled"],
      handler: (message: EventSourceMessage) => {
        const { activityId, activityOccurrenceId } =
          activityOccurrenceSchema.parse(JSON.parse(message.data));
        queryClient.invalidateQueries({
          predicate: (query) => {
            const stringifiedQueryData = JSON.stringify(query.state.data);
            return (
              stringifiedQueryData.includes(activityOccurrenceId) &&
              stringifiedQueryData.includes(activityId)
            );
          },
        });
      },
    },
    // scheduleFrequencyChanged is not relevant in Go.
    // homeVisitCompetenceChanged is not relevant in Go.
    // videoCallCompetenceChanged is not relevant in Go.
    // homeVisitDoubleStaffingChanged is not relevant in Go.
    // adminTaskPatientConnected is not relevant in Go.
    // patientMeasurementTaskMeasurementsChanged is not relevant in Go.
  ]);
  useEventSource("logistics", eventHandlers);
};

export const useCenterLogisticsSSEHandlers = () => {
  const queryClient = useQueryClient();
  // https://react.dev/reference/react/useMemo#caveats says to prefer useState for safely persisting state between renders: https://react.dev/reference/react/useState#avoiding-recreating-the-initial-state
  const [eventHandlers] = useState(() => [
    {
      relevantEvents: ["routeCreated"],
      handler: () => {
        queryClient.invalidateQueries({ queryKey: routeKeys.lists() });
      },
    },
    {
      relevantEvents: ["routeRemoved"],
      handler: (message: EventSourceMessage) => {
        const { routeId } = routeSchema.parse(JSON.parse(message.data));
        queryClient.invalidateQueries({ queryKey: routeKeys.lists() });
        queryClient.invalidateQueries({ queryKey: routeKeys.detail(routeId) });
      },
    },
    {
      relevantEvents: ["visitOrderChanged"],
      handler: (message: EventSourceMessage) => {
        const { routeId } = routeSchema.parse(JSON.parse(message.data));
        queryClient.invalidateQueries({ queryKey: routeKeys.lists() });
        queryClient.invalidateQueries({ queryKey: routeKeys.detail(routeId) });
      },
    },
    {
      relevantEvents: ["routeFinished"],
      handler: (message: EventSourceMessage) => {
        const { routeId } = routeSchema.parse(JSON.parse(message.data));
        queryClient.invalidateQueries({ queryKey: routeKeys.lists() });
        queryClient.invalidateQueries({ queryKey: routeKeys.detail(routeId) });
      },
    },
    {
      relevantEvents: ["routeNameChanged"],
      handler: (message: EventSourceMessage) => {
        const { routeId } = routeSchema.parse(JSON.parse(message.data));
        queryClient.invalidateQueries({ queryKey: routeKeys.lists() });
        queryClient.invalidateQueries({ queryKey: routeKeys.detail(routeId) });
      },
    },
    {
      relevantEvents: ["visitCreated"],
      handler: (message: EventSourceMessage) => {
        const { routeId } = visitSchema.parse(JSON.parse(message.data));
        queryClient.invalidateQueries({ queryKey: routeKeys.lists() });
        queryClient.invalidateQueries({ queryKey: routeKeys.detail(routeId) });
        queryClient.invalidateQueries({ queryKey: visitKeys(routeId).lists() });
      },
    },
    {
      relevantEvents: ["visitRemoved"],
      handler: (message: EventSourceMessage) => {
        const { routeId, visitId } = visitSchema.parse(
          JSON.parse(message.data),
        );
        queryClient.invalidateQueries({ queryKey: routeKeys.lists() });
        queryClient.invalidateQueries({ queryKey: routeKeys.detail(routeId) });
        queryClient.invalidateQueries({ queryKey: visitKeys(routeId).lists() });
        queryClient.invalidateQueries({
          queryKey: visitKeys(routeId).detail(visitId),
        });
      },
    },
    {
      relevantEvents: ["travelToVisitStarted"],
      handler: (message: EventSourceMessage) => {
        const { routeId, visitId } = visitSchema.parse(
          JSON.parse(message.data),
        );
        queryClient.invalidateQueries({ queryKey: routeKeys.lists() });
        queryClient.invalidateQueries({ queryKey: routeKeys.detail(routeId) });
        queryClient.invalidateQueries({ queryKey: visitKeys(routeId).lists() });
        queryClient.invalidateQueries({
          queryKey: visitKeys(routeId).detail(visitId),
        });
        queryClient.invalidateQueries({
          queryKey: activityOccurrenceAndGroupKeys.all(),
        });
      },
    },
    {
      relevantEvents: ["visitStarted"],
      handler: (message: EventSourceMessage) => {
        const { routeId, visitId } = visitSchema.parse(
          JSON.parse(message.data),
        );
        queryClient.invalidateQueries({ queryKey: routeKeys.lists() });
        queryClient.invalidateQueries({ queryKey: routeKeys.detail(routeId) });
        queryClient.invalidateQueries({ queryKey: visitKeys(routeId).lists() });
        queryClient.invalidateQueries({
          queryKey: visitKeys(routeId).detail(visitId),
        });
        queryClient.invalidateQueries({
          queryKey: activityOccurrenceAndGroupKeys.all(),
        });
      },
    },
    {
      relevantEvents: ["visitFinished"],
      handler: (message: EventSourceMessage) => {
        const { routeId, visitId } = visitSchema.parse(
          JSON.parse(message.data),
        );
        queryClient.invalidateQueries({ queryKey: routeKeys.lists() });
        queryClient.invalidateQueries({ queryKey: routeKeys.detail(routeId) });
        queryClient.invalidateQueries({ queryKey: visitKeys(routeId).lists() });
        queryClient.invalidateQueries({
          queryKey: visitKeys(routeId).detail(visitId),
        });
        queryClient.invalidateQueries({
          queryKey: activityOccurrenceAndGroupKeys.all(),
        });
      },
    },
    {
      relevantEvents: ["travellingToVisitReverted"],
      handler: (message: EventSourceMessage) => {
        const { routeId, visitId } = visitSchema.parse(
          JSON.parse(message.data),
        );
        queryClient.invalidateQueries({ queryKey: routeKeys.lists() });
        queryClient.invalidateQueries({ queryKey: routeKeys.detail(routeId) });
        queryClient.invalidateQueries({ queryKey: visitKeys(routeId).lists() });
        queryClient.invalidateQueries({
          queryKey: visitKeys(routeId).detail(visitId),
        });
        queryClient.invalidateQueries({
          queryKey: activityOccurrenceAndGroupKeys.all(),
        });
      },
    },
    {
      relevantEvents: ["homeVisitActivityRegistered"],
      handler: () => {
        queryClient.invalidateQueries({ queryKey: activityKeys.lists() });
        queryClient.invalidateQueries({
          queryKey: activityOccurrenceAndGroupKeys.lists(),
        });
      },
    },
    {
      relevantEvents: ["videoCallActivityRegistered"],
      handler: () => {
        queryClient.invalidateQueries({ queryKey: activityKeys.lists() });
        queryClient.invalidateQueries({
          queryKey: activityOccurrenceAndGroupKeys.lists(),
        });
      },
    },
    {
      relevantEvents: ["patientTaskActivityRegistered"],
      handler: () => {
        queryClient.invalidateQueries({ queryKey: activityKeys.lists() });
        queryClient.invalidateQueries({
          queryKey: activityOccurrenceAndGroupKeys.lists(),
        });
      },
    },
    {
      relevantEvents: ["patientMeasurementTaskActivityRegistered"],
      handler: () => {
        queryClient.invalidateQueries({ queryKey: activityKeys.lists() });
        queryClient.invalidateQueries({
          queryKey: activityOccurrenceAndGroupKeys.lists(),
        });
      },
    },
    {
      relevantEvents: ["adminTaskActivityRegistered"],
      handler: () => {
        queryClient.invalidateQueries({ queryKey: activityKeys.lists() });
        queryClient.invalidateQueries({
          queryKey: activityOccurrenceAndGroupKeys.lists(),
        });
      },
    },
    {
      relevantEvents: ["activityVisibilitySet"],
      handler: (message: EventSourceMessage) => {
        const { activityId } = activitySchema.parse(JSON.parse(message.data));
        queryClient.invalidateQueries({ queryKey: activityKeys.lists() });
        queryClient.invalidateQueries({
          queryKey: activityKeys.detail(activityId),
        });
        queryClient.invalidateQueries({
          queryKey: activityOccurrenceAndGroupKeys.all(),
        });
        queryClient.invalidateQueries({ queryKey: routeKeys.all });
        queryClient.invalidateQueries({
          queryKey: shiftKeys.listWithContents({}),
        });
      },
    },
    {
      relevantEvents: ["titleChanged"],
      handler: (message: EventSourceMessage) => {
        const { activityId } = activitySchema.parse(JSON.parse(message.data));
        queryClient.invalidateQueries({ queryKey: activityKeys.lists() });
        queryClient.invalidateQueries({
          queryKey: activityKeys.detail(activityId),
        });
        queryClient.invalidateQueries({
          queryKey: activityOccurrenceAndGroupKeys.all(),
        });
        queryClient.invalidateQueries({ queryKey: routeKeys.all });
        queryClient.invalidateQueries({
          queryKey: shiftKeys.listWithContents({}),
        });
      },
    },
    {
      relevantEvents: ["descriptionChanged"],
      handler: (message: EventSourceMessage) => {
        const { activityId } = activitySchema.parse(JSON.parse(message.data));
        queryClient.invalidateQueries({ queryKey: activityKeys.lists() });
        queryClient.invalidateQueries({
          queryKey: activityKeys.detail(activityId),
        });
        queryClient.invalidateQueries({
          queryKey: activityOccurrenceAndGroupKeys.all(),
        });
        queryClient.invalidateQueries({ queryKey: routeKeys.all });
        queryClient.invalidateQueries({
          queryKey: shiftKeys.listWithContents({}),
        });
      },
    },
    {
      relevantEvents: ["durationChanged"],
      handler: (message: EventSourceMessage) => {
        const { activityId } = activitySchema.parse(JSON.parse(message.data));
        queryClient.invalidateQueries({ queryKey: activityKeys.lists() });
        queryClient.invalidateQueries({
          queryKey: activityKeys.detail(activityId),
        });
        queryClient.invalidateQueries({
          queryKey: activityOccurrenceAndGroupKeys.all(),
        });
        queryClient.invalidateQueries({ queryKey: routeKeys.all });
        queryClient.invalidateQueries({
          queryKey: shiftKeys.listWithContents({}),
        });
      },
    },
    {
      relevantEvents: ["activityEndDateSet"],
      handler: (message: EventSourceMessage) => {
        const { activityId } = activitySchema.parse(JSON.parse(message.data));
        queryClient.invalidateQueries({ queryKey: activityKeys.lists() });
        queryClient.invalidateQueries({
          queryKey: activityKeys.detail(activityId),
        });
        queryClient.invalidateQueries({
          queryKey: activityOccurrenceAndGroupKeys.all(),
        });
        queryClient.invalidateQueries({ queryKey: routeKeys.all });
        queryClient.invalidateQueries({
          queryKey: shiftKeys.listWithContents({}),
        });
      },
    },
    {
      relevantEvents: ["activityClosed"],
      handler: (message: EventSourceMessage) => {
        const { activityId } = activitySchema.parse(JSON.parse(message.data));
        queryClient.invalidateQueries({ queryKey: activityKeys.lists() });
        queryClient.invalidateQueries({
          queryKey: activityKeys.detail(activityId),
        });
        queryClient.invalidateQueries({
          queryKey: activityOccurrenceAndGroupKeys.all(),
        });
        queryClient.invalidateQueries({ queryKey: routeKeys.all });
        queryClient.invalidateQueries({
          queryKey: shiftKeys.listWithContents({}),
        });
      },
    },
    {
      relevantEvents: ["activityOccurrenceAssigned"],
      handler: (message: EventSourceMessage) => {
        const { activityId, activityOccurrenceId, routeId, visitId } =
          activityOccurrenceAssignedSchema.parse(JSON.parse(message.data));
        queryClient.invalidateQueries({
          queryKey: activityOccurrenceAndGroupKeys.lists(),
        });
        queryClient.invalidateQueries({
          queryKey: activityOccurrenceAndGroupKeys.detail(
            `${activityId}${activityOccurrenceId}`,
          ),
        });
        queryClient.invalidateQueries({ queryKey: routeKeys.lists() });
        queryClient.invalidateQueries({ queryKey: routeKeys.detail(routeId) });
        queryClient.invalidateQueries({ queryKey: visitKeys(routeId).lists() });
        queryClient.invalidateQueries({
          queryKey: visitKeys(routeId).detail(visitId),
        });
        queryClient.invalidateQueries({
          queryKey: shiftKeys.listWithContents({}),
        });
      },
    },
    {
      relevantEvents: ["activityOccurrenceUnassigned"],
      handler: (message: EventSourceMessage) => {
        const { activityId, activityOccurrenceId, routeId, visitId } =
          activityOccurrenceAssignedSchema.parse(JSON.parse(message.data));
        queryClient.invalidateQueries({
          queryKey: activityOccurrenceAndGroupKeys.lists(),
        });
        queryClient.invalidateQueries({
          queryKey: activityOccurrenceAndGroupKeys.detail(
            `${activityId}${activityOccurrenceId}`,
          ),
        });
        queryClient.invalidateQueries({ queryKey: routeKeys.lists() });
        queryClient.invalidateQueries({ queryKey: routeKeys.detail(routeId) });
        queryClient.invalidateQueries({ queryKey: visitKeys(routeId).lists() });
        queryClient.invalidateQueries({
          queryKey: visitKeys(routeId).detail(visitId),
        });
        queryClient.invalidateQueries({
          queryKey: shiftKeys.listWithContents({}),
        });
      },
    },
    {
      relevantEvents: ["occurrenceAllocated"],
      handler: (message: EventSourceMessage) => {
        const { activityId, activityOccurrenceId } =
          allocationToActivityOccurrenceSchema.parse(JSON.parse(message.data));
        queryClient.invalidateQueries({
          queryKey: activityOccurrenceAndGroupKeys.lists(),
        });
        queryClient.invalidateQueries({
          queryKey: activityOccurrenceAndGroupKeys.detail(
            `${activityId}${activityOccurrenceId}`,
          ),
        });
        queryClient.invalidateQueries({ queryKey: routeKeys.all });
        queryClient.invalidateQueries({
          queryKey: shiftKeys.listWithContents({}),
        });
      },
    },
    {
      relevantEvents: ["occurrenceDeallocated"],
      handler: (message: EventSourceMessage) => {
        const { activityId, activityOccurrenceId } =
          allocationToActivityOccurrenceSchema.parse(JSON.parse(message.data));
        queryClient.invalidateQueries({
          queryKey: activityOccurrenceAndGroupKeys.lists(),
        });
        queryClient.invalidateQueries({
          queryKey: activityOccurrenceAndGroupKeys.detail(
            `${activityId}${activityOccurrenceId}`,
          ),
        });
        queryClient.invalidateQueries({ queryKey: routeKeys.all });
        queryClient.invalidateQueries({
          queryKey: shiftKeys.listWithContents({}),
        });
      },
    },
    {
      relevantEvents: ["occurrenceRemoved"],
      handler: (message: EventSourceMessage) => {
        const { activityId, activityOccurrenceId } =
          activityOccurrenceSchema.parse(JSON.parse(message.data));
        queryClient.invalidateQueries({
          queryKey: activityOccurrenceAndGroupKeys.lists(),
        });
        queryClient.invalidateQueries({
          queryKey: activityOccurrenceAndGroupKeys.detail(
            `${activityId}${activityOccurrenceId}`,
          ),
        });
        queryClient.invalidateQueries({ queryKey: routeKeys.all });
        queryClient.invalidateQueries({
          queryKey: shiftKeys.listWithContents({}),
        });
      },
    },
    {
      relevantEvents: ["occurrenceReset"],
      handler: (message: EventSourceMessage) => {
        const { activityId, activityOccurrenceId } =
          activityOccurrenceSchema.parse(JSON.parse(message.data));
        queryClient.invalidateQueries({
          queryKey: activityOccurrenceAndGroupKeys.lists(),
        });
        queryClient.invalidateQueries({
          queryKey: activityOccurrenceAndGroupKeys.detail(
            `${activityId}${activityOccurrenceId}`,
          ),
        });
        queryClient.invalidateQueries({ queryKey: routeKeys.all });
        queryClient.invalidateQueries({
          queryKey: shiftKeys.listWithContents({}),
        });
      },
    },
    {
      relevantEvents: ["occurrenceFinished"],
      handler: (message: EventSourceMessage) => {
        const { activityId, activityOccurrenceId } =
          activityOccurrenceSchema.parse(JSON.parse(message.data));
        queryClient.invalidateQueries({
          queryKey: activityOccurrenceAndGroupKeys.lists(),
        });
        queryClient.invalidateQueries({
          queryKey: activityOccurrenceAndGroupKeys.detail(
            `${activityId}${activityOccurrenceId}`,
          ),
        });
        queryClient.invalidateQueries({ queryKey: routeKeys.all });
        queryClient.invalidateQueries({
          queryKey: shiftKeys.listWithContents({}),
        });
      },
    },
    {
      relevantEvents: ["occurrenceCreated"],
      handler: (message: EventSourceMessage) => {
        const { activityId, activityOccurrenceId } =
          activityOccurrenceSchema.parse(JSON.parse(message.data));
        queryClient.invalidateQueries({
          queryKey: activityOccurrenceAndGroupKeys.lists(),
        });
        queryClient.invalidateQueries({
          queryKey: activityOccurrenceAndGroupKeys.detail(
            `${activityId}${activityOccurrenceId}`,
          ),
        });
        queryClient.invalidateQueries({ queryKey: routeKeys.all });
        queryClient.invalidateQueries({
          queryKey: shiftKeys.listWithContents({}),
        });
      },
    },
    {
      relevantEvents: ["occurrenceRescheduled"],
      handler: (message: EventSourceMessage) => {
        const { activityId, activityOccurrenceId } =
          activityOccurrenceSchema.parse(JSON.parse(message.data));
        queryClient.invalidateQueries({
          queryKey: activityOccurrenceAndGroupKeys.lists(),
        });
        queryClient.invalidateQueries({
          queryKey: activityOccurrenceAndGroupKeys.detail(
            `${activityId}${activityOccurrenceId}`,
          ),
        });
        queryClient.invalidateQueries({ queryKey: routeKeys.all });
        queryClient.invalidateQueries({
          queryKey: shiftKeys.listWithContents({}),
        });
      },
    },
    {
      relevantEvents: ["scheduleFrequencyChanged"],
      handler: (message: EventSourceMessage) => {
        const { activityId } = activitySchema.parse(JSON.parse(message.data));
        queryClient.invalidateQueries({
          queryKey: activityKeys.lists(),
        });
        queryClient.invalidateQueries({
          queryKey: activityKeys.detail(activityId),
        });
        queryClient.invalidateQueries({
          queryKey: activityOccurrenceAndGroupKeys.all(),
        });
      },
    },
    {
      relevantEvents: ["homeVisitCompetenceChanged"],
      handler: (message: EventSourceMessage) => {
        const { activityId } = activitySchema.parse(JSON.parse(message.data));
        queryClient.invalidateQueries({
          queryKey: activityKeys.lists(),
        });
        queryClient.invalidateQueries({
          queryKey: activityKeys.detail(activityId),
        });
        queryClient.invalidateQueries({
          queryKey: activityOccurrenceAndGroupKeys.all(),
        });
        queryClient.invalidateQueries({ queryKey: routeKeys.all });
        queryClient.invalidateQueries({
          queryKey: shiftKeys.listWithContents({}),
        });
      },
    },
    {
      relevantEvents: ["videoCallCompetenceChanged"],
      handler: (message: EventSourceMessage) => {
        const { activityId } = activitySchema.parse(JSON.parse(message.data));
        queryClient.invalidateQueries({
          queryKey: activityKeys.lists(),
        });
        queryClient.invalidateQueries({
          queryKey: activityKeys.detail(activityId),
        });
        queryClient.invalidateQueries({
          queryKey: activityOccurrenceAndGroupKeys.all(),
        });
        queryClient.invalidateQueries({
          queryKey: shiftKeys.listWithContents({}),
        });
      },
    },
    {
      relevantEvents: ["homeVisitDoubleStaffingChanged"],
      handler: (message: EventSourceMessage) => {
        const { activityId } = activitySchema.parse(JSON.parse(message.data));
        queryClient.invalidateQueries({
          queryKey: activityKeys.lists(),
        });
        queryClient.invalidateQueries({
          queryKey: activityKeys.detail(activityId),
        });
        queryClient.invalidateQueries({
          queryKey: activityOccurrenceAndGroupKeys.all(),
        });
        queryClient.invalidateQueries({ queryKey: routeKeys.all });
        queryClient.invalidateQueries({
          queryKey: shiftKeys.listWithContents({}),
        });
      },
    },
    {
      relevantEvents: ["adminTaskPatientConnected"],
      handler: (message: EventSourceMessage) => {
        const { activityId } = activitySchema.parse(JSON.parse(message.data));
        queryClient.invalidateQueries({
          queryKey: activityKeys.lists(),
        });
        queryClient.invalidateQueries({
          queryKey: activityKeys.detail(activityId),
        });
        queryClient.invalidateQueries({
          queryKey: activityOccurrenceAndGroupKeys.all(),
        });
        queryClient.invalidateQueries({
          queryKey: shiftKeys.listWithContents({}),
        });
      },
    },
    {
      relevantEvents: ["adminTaskCompetenceChanged"],
      handler: (message: EventSourceMessage) => {
        const { activityId } = activitySchema.parse(JSON.parse(message.data));
        queryClient.invalidateQueries({
          queryKey: activityKeys.lists(),
        });
        queryClient.invalidateQueries({
          queryKey: activityKeys.detail(activityId),
        });
        queryClient.invalidateQueries({
          queryKey: activityOccurrenceAndGroupKeys.all(),
        });
        queryClient.invalidateQueries({
          queryKey: shiftKeys.listWithContents({}),
        });
      },
    },
    {
      relevantEvents: ["patientMeasurementTaskMeasurementsChanged"],
      handler: (message: EventSourceMessage) => {
        const { activityId } = activitySchema.parse(JSON.parse(message.data));
        queryClient.invalidateQueries({
          queryKey: activityKeys.lists(),
        });
        queryClient.invalidateQueries({
          queryKey: activityKeys.detail(activityId),
        });
        queryClient.invalidateQueries({
          queryKey: activityOccurrenceAndGroupKeys.all(),
        });
      },
    },
    {
      relevantEvents: ["occurrenceGroupProcessingCompleted"],
      handler: () => {
        queryClient.invalidateQueries({
          queryKey: activityKeys.all,
        });
        queryClient.invalidateQueries({
          queryKey: routeKeys.all,
        });
      },
    },
  ]);

  useEventSource("logistics", eventHandlers);
};

export const useCenterPatientSSEHandlers = () => {
  const queryClient = useQueryClient();
  // https://react.dev/reference/react/useMemo#caveats says to prefer useState for safely persisting state between renders: https://react.dev/reference/react/useState#avoiding-recreating-the-initial-state
  const [eventHandlers] = useState(() => [
    // Ensure unhandled measurements-collection is refreshed when measurements are changed
    {
      relevantEvents: [
        "bloodPressureMeasurementRegistered",
        "pulseMeasurementRegistered",
        "saturationMeasurementRegistered",
        "temperatureMeasurementRegistered",
        "weightMeasurementRegistered",
        "bloodGlucoseMeasurementRegistered",
        "measurementAcknowledged",
        "measurementDiscarded",
      ],
      handler: () => {
        queryClient.invalidateQueries({
          queryKey: patientsWithUnhandledMeasurementsQueryOptions.queryKey,
        });
      },
    },
  ]);
  useEventSource("patient", eventHandlers);
};
