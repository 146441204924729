import TextArea from "@/components/TextArea/TextArea";
import { IAddActivityFormData } from "./AddActivityForm";
import { useFormContext } from "react-hook-form";

export const DescriptionInput = () => {
  const {
    formState: { errors },
    register,
  } = useFormContext<IAddActivityFormData>();

  return (
    <TextArea
      label="Beskrivning (valfri)"
      description="Visas inte för patienten"
      errorMessage={errors.description?.message}
      {...register(`description`)}
    />
  );
};
