import styles from "./AllocateToShiftTile.module.scss";
import PlusIcon from "@components/icons/PlusIcon";
import clsx from "clsx";
import { IPlainButton, PlainButton } from "@components/Button/Button";

type IAllocateToShiftButton = Omit<IPlainButton, "children">;

const AllocateToShiftButton = (props: IAllocateToShiftButton) => {
  return (
    <PlainButton contentType="icon" {...props}>
      <PlusIcon />
    </PlainButton>
  );
};

export const AllocateToShiftTile = ({
  activeActivityGroupId,
  variant,
}: {
  activeActivityGroupId: string;
  variant: "default" | "compact";
}) => {
  return (
    <article
      className={clsx(styles.iconContainer, styles[`variant-${variant}`])}
    >
      <AllocateToShiftButton
        aria-label="Lägg till valt besök i rutt"
        onClick={() => console.log(activeActivityGroupId)}
      ></AllocateToShiftButton>
    </article>
  );
};
