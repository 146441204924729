import { Tabs, Tab, TabList, TabPanel } from "@components/Tabs";
import { Outlet, useLocation } from "react-router-dom";
import { RoutesContext } from "./RoutesContext";
import { IRouteStatus, useRoutes } from "@/api/Routes";
import { useSelectedDate } from "@/Utils/useSelectedDate";
import { useContext } from "react";

export const RoutesTabs = () => {
  const { search } = useLocation();
  const { setSelectedRouteId } = useContext(RoutesContext);
  const numberPlannedRoutes = useCountRoutes("draft");
  const numberOngoingRoutes = useCountRoutes("ongoing");
  const numberFinishedRoutes = useCountRoutes("finished");

  return (
    <Tabs>
      <TabList>
        <Tab
          // Include search params in the URL to keep the selected date when switching tabs
          to={`planned${search}`}
          onClick={() => setSelectedRouteId(undefined)}
        >
          {numberPlannedRoutes !== undefined
            ? `Planerade (${numberPlannedRoutes})`
            : "Planerade"}
        </Tab>
        <Tab
          to={`ongoing${search}`}
          onClick={() => setSelectedRouteId(undefined)}
        >
          {numberOngoingRoutes !== undefined
            ? `Pågående (${numberOngoingRoutes})`
            : "Pågående"}
        </Tab>
        <Tab
          to={`finished${search}`}
          onClick={() => setSelectedRouteId(undefined)}
        >
          {numberFinishedRoutes !== undefined
            ? `Avslutade (${numberFinishedRoutes})`
            : "Avslutade"}
        </Tab>
      </TabList>
      <TabPanel>
        <Outlet />
      </TabPanel>
    </Tabs>
  );
};

const useCountRoutes = (status: IRouteStatus) => {
  const selectedDate = useSelectedDate();
  const { data: allRoutes, isPending, isError } = useRoutes(selectedDate);

  if (isPending) return undefined;
  if (isError) return "-";

  return allRoutes.filter((route) => route.status === status).length.toString();
};
