import RadioButton from "@/components/RadioButton/RadioButton";
import RadioButtons from "@/components/RadioButton/RadioButtons";
import { useFormContext } from "react-hook-form";
import { ITimeSlotsFields, TimeSlots } from "./TimeSlots";
import { useEffect } from "react";
import {
  IRecurrencesPerDayOption,
  ITimeSensitivityOption,
  recurrencesPerDayOptions,
  timeSensitivityMap,
  timeSensitivityOptions,
} from "./timeSlotsUtils";
import { timeOfDayDictionary } from "@models/activities";

type ITimeCategoryOption = "Vid specifik tid" | "När som helst under dagen";

export type ITimeSlotsPickerFields = ITimeSlotsFields & {
  timeCategory: ITimeCategoryOption;
  recurrencesPerDay: IRecurrencesPerDayOption;
  timeSensitivity: ITimeSensitivityOption;
};

export const TimeSlotsPicker = ({
  isRecurring = false,
}: {
  isRecurring: boolean;
}) => {
  const {
    formState: { errors },
    register,
    resetField,
    watch,
  } = useFormContext<ITimeSlotsPickerFields>();

  const isAnyTimeOfDay = watch("timeCategory") === timeOfDayDictionary.Any.long;
  const numberOfTimeSlots = Number(watch("recurrencesPerDay"));

  useEffect(() => {
    if (!isRecurring) {
      resetField(`recurrencesPerDay`);
      resetField(`timeslots`);
    }
  }, [isRecurring, resetField]);

  return (
    <>
      <RadioButtons
        legend="Inträffar"
        errorMessage={errors.timeCategory?.message}
        orientation="horizontal"
      >
        <RadioButton
          label={{ text: timeOfDayDictionary.Specific.long }}
          visualStyle="framed"
          value={timeOfDayDictionary.Specific.long}
          {...register(`timeCategory`, {
            required: {
              value: true,
              message: "Aktiviteten behöver ett val för tidpunkt.",
            },
            onChange: () => {
              resetField(`timeSensitivity`);
              resetField(`recurrencesPerDay`);
              resetField(`timeslots`);
            },
          })}
        />
        <RadioButton
          label={{ text: timeOfDayDictionary.Any.long }}
          visualStyle="framed"
          value={timeOfDayDictionary.Any.long}
          {...register(`timeCategory`)}
        />
      </RadioButtons>
      {isRecurring && !isAnyTimeOfDay ? (
        <>
          <RadioButtons
            legend="Antal tillfällen per dygn"
            orientation="horizontal"
          >
            {recurrencesPerDayOptions.map((recurrencesPerDay) => (
              <RadioButton
                key={recurrencesPerDay}
                label={{ text: recurrencesPerDay }}
                {...register(`recurrencesPerDay`)}
                value={recurrencesPerDay}
                visualStyle="framed"
              />
            ))}
          </RadioButtons>
        </>
      ) : (
        <></>
      )}
      {!isAnyTimeOfDay ? (
        <>
          <TimeSlots recurrences={numberOfTimeSlots} />
          <RadioButtons
            legend="Tidsflexibilitet"
            errorMessage={errors.timeSensitivity?.message}
            orientation="horizontal"
          >
            {timeSensitivityOptions.map((timeSensitivity) => (
              <RadioButton
                key={timeSensitivity}
                label={{ text: timeSensitivityMap[timeSensitivity] }}
                value={timeSensitivity}
                visualStyle="framed"
                {...register(`timeSensitivity`, {
                  required: {
                    value: true,
                    message: "Aktiviteten behöver en tidsflexibilitet.",
                  },
                })}
              />
            ))}
          </RadioButtons>
        </>
      ) : (
        <></>
      )}
    </>
  );
};
