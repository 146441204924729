import { useForm, FormProvider } from "react-hook-form";
import Form from "@/components/Form/Form";
import { FilledButton, OutlinedButton } from "@components/Button/Button";
import TextArea from "@/components/TextArea/TextArea";
import { Heading } from "@components/Heading/Heading";
import { Text } from "@components/Text/Text";
import { useEffect, useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { displayErrorMessageAlert } from "@/Utils/ErrorUtils";
import {
  acceptConvertedPatientNote,
  IAcceptedConvertedPatientNote,
} from "@/api/PatientNote";
import styles from "./ConvertedPatientNoteForm.module.scss";
import { format } from "@models/date-and-time";
import { NoteStatusChip } from "@/components/Chips/NoteStatusChip";
import {
  IConvertedPatientNoteWithPatientId,
  IPatientNoteStatus,
  patientNoteStates,
  patientNoteStatusSchema,
} from "@models/patientNotes";

const ConvertedPatientNoteForm = ({
  patientNote,
  convertedPatientNoteState,
}: {
  patientNote: IConvertedPatientNoteWithPatientId;
  isLoadingConvertedNote: boolean;
  convertedPatientNoteState: (noteState: string) => void;
}) => {
  const methods = useForm<IConvertedPatientNoteWithPatientId>({
    defaultValues: {
      id: patientNote.id,
      convertedText: patientNote.convertedText,
      acceptedBy: patientNote.acceptedBy,
      status: patientNote.status,
    },
  });

  const [isCurrentlyEditingAlreadyAccepted, setIsCurrentlyEditing] =
    useState(false);

  const conversionAccepted =
    patientNote.status === patientNoteStatusSchema.Values.accepted;

  const {
    handleSubmit,
    register,
    getValues,
    reset,
    watch,
    formState: { errors },
  } = methods;

  useEffect(() => {
    reset({
      id: patientNote.id,
      convertedText: patientNote.convertedText,
      acceptedBy: patientNote.acceptedBy,
      status: patientNote.status,
    });
  }, [patientNote, reset]);

  const queryClient = useQueryClient();

  const acceptedInfoText =
    patientNote.acceptedBy && patientNote.conversionTimeStamp
      ? `${patientNote.acceptedBy}, ${format(new Date(patientNote.conversionTimeStamp), "yyyy-MM-dd")}`
      : undefined;

  const { mutate, isPending } = useMutation({
    mutationFn: ({
      convertedPatientNote,
    }: {
      convertedPatientNote: IAcceptedConvertedPatientNote & {
        status: IPatientNoteStatus;
      };
    }) => acceptConvertedPatientNote(convertedPatientNote),
    onError: () => {
      displayErrorMessageAlert(
        "Det gick inte att godkänna och visa den översatta texten för patient. Försök igen om en stund, och rapportera ett fel om det ändå inte fungerar.",
      );
      reset(getValues(), {
        keepErrors: true,
        keepIsSubmitted: true,
        keepTouched: true,
        keepIsValid: true,
        keepSubmitCount: true,
      });
    },
    onSuccess: () => {
      convertedPatientNoteState(patientNoteStates.SUBMITTED);
      return queryClient.invalidateQueries({ queryKey: ["conversion"] });
    },
  });

  const validateAndSubmit = handleSubmit((formData) => {
    const { id, convertedText, status } = formData;

    const convertedPatientNote = {
      id,
      updatedText: convertedText,
      status,
    };

    setIsCurrentlyEditing(false);

    mutate({ convertedPatientNote });
  });

  const currentConvertedText = watch().convertedText;

  const editConvertedPatientNote = () => {
    setIsCurrentlyEditing(true);
  };

  const cancelEditingConvertedPatientNote = () => {
    setIsCurrentlyEditing(false);
  };

  const splitConvertedTextInParagraphs = patientNote.convertedText
    .split("\n")
    .filter((paragraph) => paragraph.trim() !== "");

  return (
    <FormProvider {...methods}>
      <Form onSubmit={validateAndSubmit}>
        <Heading level="h2">Förenklad text</Heading>
        <NoteStatusChip status={patientNote.status}>
          {acceptedInfoText}
        </NoteStatusChip>
        {!isCurrentlyEditingAlreadyAccepted && conversionAccepted ? (
          <>
            {splitConvertedTextInParagraphs.map((paragraph, index) => (
              <Text element="p" key={index}>
                {paragraph}
              </Text>
            ))}
          </>
        ) : (
          <Form.Section>
            <TextArea
              label="Ändra förenklad text"
              rows={10}
              errorMessage={errors.convertedText?.message}
              {...register("convertedText", {
                required: {
                  value: currentConvertedText?.length === 0,
                  message: "Det finns ingen förenklad text att spara",
                },
              })}
            />
          </Form.Section>
        )}
        <Form.SubmitButtonWrapper>
          <div className={styles.buttons}>
            {isCurrentlyEditingAlreadyAccepted ? (
              <>
                {conversionAccepted && (
                  <OutlinedButton onClick={cancelEditingConvertedPatientNote}>
                    Avbryt
                  </OutlinedButton>
                )}
                <FilledButton type="submit" disabled={isPending}>
                  Godkänn och visa för patient
                </FilledButton>
              </>
            ) : (
              <>
                {conversionAccepted ? (
                  <OutlinedButton onClick={editConvertedPatientNote}>
                    Ändra förenklad text
                  </OutlinedButton>
                ) : (
                  <FilledButton type="submit" disabled={isPending}>
                    Godkänn och visa för patient
                  </FilledButton>
                )}
              </>
            )}
          </div>
        </Form.SubmitButtonWrapper>
      </Form>
    </FormProvider>
  );
};

export default ConvertedPatientNoteForm;
