import { useParentRoutesPatient } from "@/api/Patients";
import { LOGISTICS_API_URL } from "@/Utils/EnvUtils";
import { Iframe } from "@components/Iframe/Iframe";

const Equipment = () => {
  const patient = useParentRoutesPatient();

  return (
    <Iframe
      title="Hantera utrustning"
      src={LOGISTICS_API_URL + `/center/htmx/patient/${patient.id}/equipment`}
      loadingMessage="Hämtar utrustningsinformation"
      padding={24}
    />
  );
};

export { Equipment };
