import {
  ANY_TIME_OF_DAY,
  INewActivity,
  INewAdminTaskActivity,
  INewHomeVisitActivity,
  INewPatientMeasurementTaskActivity,
  INewPatientTaskActivity,
  INewVideoActivity,
} from "@/api/Activities";
import { ITemplate } from "./templates";
import { recurringDictionary } from "@/forms/AddActivityForm/Scheduling/recurrenceUtils";
import { generateRandomUUID } from "@/Utils/UniqueId";
import {
  getTimespan,
  getTimeFields,
} from "@/forms/AddActivityForm/activityTimeUtils";
import { timeOfDayDictionary, videoTypeSchema } from "@models/activities";

export const newActivityFromTemplate = ({
  template,
  startDate,
  endDate,
  patientId,
}: {
  template: ITemplate;
  startDate: string;
  endDate?: string;
  patientId: string;
}): INewActivity => {
  const {
    title,
    category,
    description,
    duration,
    doubleStaffing,
    recurrence,
    requiredCompetences,
    timeCategory,
    weekdays,
    hidden,
    measurements,
  } = template;

  const timeSensitivity =
    "timeSensitivity" in template ? template.timeSensitivity : undefined;

  const timeslots = "timeslots" in template ? template.timeslots : [];

  const isAnyTimeOfDay = timeCategory === timeOfDayDictionary.Any.long;
  const isRecurring = recurrence !== recurringDictionary.never;
  const isRecurringAsInterval =
    recurrence !== recurringDictionary.custom &&
    recurrence !== recurringDictionary.never;

  const commonActivityFields: Pick<
    INewActivity,
    | "activityId"
    | "category"
    | "description"
    | "duration"
    | "hidden"
    | "startDate"
    | "timespan"
    | "title"
  > = {
    activityId: generateRandomUUID(),
    category,
    description,
    duration: duration ?? 15,
    hidden,
    startDate,
    timespan: getTimespan({
      isAnyTimeOfDay,
      timeSensitivity,
    }),
    title,
  };

  const timeFields = isRecurring
    ? {
        ...getTimeFields({
          isRecurringAsInterval,
          isAnyTimeOfDay,
          timeslots,
          weekdays,
          recurrence,
        }),
        endDate: endDate === "" || endDate === undefined ? undefined : endDate,
      }
    : // @ts-expect-error we know that there is exactly one timeslot, but TS doesn't.
      { time: isAnyTimeOfDay ? ANY_TIME_OF_DAY : timeslots[0].time }; // We only have one timeslot if it's not recurring.

  if (category === "VideoCall") {
    const newActivity: INewVideoActivity = {
      ...commonActivityFields,
      patientId,
      category: "VideoCall",
      requiredCompetences,
      ...timeFields,
      type: videoTypeSchema.Values.DigitalVisit,
    };

    return newActivity;
  } else if (category === "HomeVisit") {
    const newActivity: INewHomeVisitActivity = {
      ...commonActivityFields,
      patientId,
      category: "HomeVisit",
      requiredCompetences,
      ...timeFields,
      doubleStaffing,
    };

    return newActivity;
  } else if (category === "PatientTask") {
    const newActivity: INewPatientTaskActivity = {
      ...commonActivityFields,
      patientId,
      category: "PatientTask",
      ...timeFields,
    };

    return newActivity;
  } else if (category === "AdminTask") {
    const newActivity: INewAdminTaskActivity = {
      ...commonActivityFields,
      patientId: patientId ? patientId : null, // Represent lack of patient id as null.
      category: "AdminTask",
      requiredCompetences: requiredCompetences ? requiredCompetences : [],
      ...timeFields,
    };

    return newActivity;
  } else {
    const newActivity: INewPatientMeasurementTaskActivity = {
      ...commonActivityFields,
      patientId,
      category: "PatientMeasurementTask",
      measurements: measurements,
      ...timeFields,
    };

    return newActivity;
  }
};
