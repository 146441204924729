import MapboxMap, {
  FullscreenControl,
  LngLatLike,
  MapRef,
  ViewState,
} from "react-map-gl";
import { HOSPITAL_COORDINATES } from "@/Utils/EnvUtils";
import { useCallback, useEffect, useRef } from "react";

export type ICoordinatePair = { latitude: number; longitude: number };
export type IMapState = Omit<ViewState, "longitude" | "latitude"> & {
  coordinates: ICoordinatePair;
};
interface IMap {
  disabled?: boolean;
  id: string;
  coordinatesToShow?: ICoordinatePair[];
  viewState?: ViewState;
  setViewState: (viewState: ViewState) => void;
  children?: JSX.Element | JSX.Element[];
  style: Omit<React.CSSProperties, "minHeight" | "minWidth" | "opacity"> & {
    height: string;
  };
}

const PADDING = 100;

const Map = ({
  disabled,
  id,
  coordinatesToShow,
  viewState = {
    longitude: HOSPITAL_COORDINATES.longitude,
    latitude: HOSPITAL_COORDINATES.latitude,
    zoom: 12,
    bearing: 0,
    pitch: 0,
    padding: { top: 0, left: 0, right: 0, bottom: 0 },
  },
  setViewState,
  children,
  style,
}: IMap) => {
  // Disable map handlers if map is disabled. Solution in the spirit of https://stackoverflow.com/a/38774303/4102048
  const adjustedHandlers = disabled
    ? {
        boxZoom: false,
        doubleClickZoom: false,
        dragRotate: false,
        dragPan: false,
        keyboard: false,
        scrollZoom: false,
        touchPitch: false,
        touchZoomRotate: false,
      }
    : {};

  const mapRef = useRef<MapRef>(null);

  const fitToBounds = useCallback(() => {
    if (coordinatesToShow) {
      const initialBounds = [
        {
          lat: Math.min(...coordinatesToShow.map(({ latitude }) => latitude)),
          lon: Math.min(...coordinatesToShow.map(({ longitude }) => longitude)),
        },
        {
          lat: Math.max(...coordinatesToShow.map(({ latitude }) => latitude)),
          lon: Math.max(...coordinatesToShow.map(({ longitude }) => longitude)),
        },
      ] as [LngLatLike, LngLatLike];

      mapRef.current?.fitBounds(initialBounds, {
        padding: {
          top: PADDING,
          bottom: PADDING,
          left: PADDING,
          right: PADDING,
        },
        maxZoom: 12,
      });
    } else {
      // Re-center.
      mapRef.current?.setCenter([
        HOSPITAL_COORDINATES.latitude,
        HOSPITAL_COORDINATES.longitude,
      ]);
    }
  }, [coordinatesToShow]);

  useEffect(() => {
    fitToBounds();
  }, [fitToBounds]);

  return (
    <MapboxMap
      onLoad={() => {
        // TODO: Follow up on https://github.com/visgl/react-map-gl/issues/2412 to remove workaround
        setTimeout(() => fitToBounds(), 10);
      }}
      reuseMaps
      onError={(error) => console.error("Map error", error)}
      id={id}
      mapboxAccessToken="pk.eyJ1IjoibWVkb21hIiwiYSI6ImNsN2Z6c2J0bTAwOHozd25xM3JlNzZ6bjUifQ.v2XsEAYwGo00Vk0XZteAZQ"
      {...viewState}
      onMove={(event) => setViewState(event.viewState)}
      mapStyle="mapbox://styles/mapbox/streets-v11"
      style={{
        ...style,
        opacity: disabled ? 0.5 : 1,
        minHeight: `${PADDING * 2 + 50}px`,
        minWidth: `${PADDING * 2 + 50}px`,
      }}
      minZoom={10}
      {...adjustedHandlers}
      ref={mapRef}
    >
      {children}
      {!disabled ? <FullscreenControl /> : null}
    </MapboxMap>
  );
};

export default Map;
