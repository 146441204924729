import { DaySwitcher } from "@/components/DaySwitcher/DaySwitcher";
import styles from "./Shifts.module.scss";
import Portrait from "@components/Portrait/Portrait";
import { Text } from "@components/Text/Text";
import {
  TimelineActivityTile,
  TimelineVisitTile,
} from "./TimelineTile/TimelineTile";
import { formattedTimeSpan } from "@/components/Time/timeUtils";
import { UnplannedGroupTile } from "./UnplannedGroup/UnplannedGroupTile";
import { useLocation, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import {
  IShiftWithContents,
  shiftsWithContentsQueryOptions,
} from "@/api/Shifts";
import { useSelectedDate } from "@/Utils/useSelectedDate";
import { Loading } from "@components/Loading/Loading";
import ErrorMessage from "@components/ErrorMessage/ErrorMessage";
import {
  medicalCompetenceDictionary,
  shiftName,
  shiftTimeDisplayValues,
} from "@models/shifts";
import { Heading } from "@components/Heading/Heading";
import NoResults from "@/components/NoResults/NoResults";
import * as Sentry from "@sentry/react";
import { deducedError } from "@/Utils/ErrorUtils";
import { CompetenceChip } from "@/components/Chips/CompetenceChip";
import { useShiftsViewExpandedMode } from "./useShiftsViewExpandedMode";
import StandaloneCheckbox from "@/components/Checkbox/StandaloneCheckbox";
import { useFeatureFlag } from "@/api/FeatureFlags";
import clsx from "clsx";
import { AllocateToShiftTile } from "./TimelineTile/AllocateToShiftTile";
import { IActivityOccurrenceOrGroup, isGroup } from "@models/activities";
import { useUnplannedOccurrencesAndGroups } from "@/api/Activities";
import RadioButtons from "@/components/RadioButton/RadioButtons";

const ShiftRow = ({
  shiftWithContents: { shift, items },
  isExpanded,
  activeActivityGroupId,
}: {
  shiftWithContents: IShiftWithContents;
  isExpanded: boolean;
  activeActivityGroupId: string | null;
}) => {
  const name = shiftName({ ...shift, options: { length: "long" } });
  const competence = shift.employee?.competence || shift.competence;
  const selectedDate = new Date(useSelectedDate());
  const { startDateTime, endDateTime } = shift;
  const {
    startedDayBefore,
    hasEndTimeAfterToday,
    formattedDayBefore,
    formattedDayToday,
  } = shiftTimeDisplayValues({
    selectedDate,
    startDateTime,
    endDateTime,
  });
  const displayShiftTime = `${
    startedDayBefore
      ? `Start ${formattedDayBefore}`
      : hasEndTimeAfterToday
        ? `Start ${formattedDayToday}`
        : ""
  } ${formattedTimeSpan(startDateTime, endDateTime)}`;

  const visitItems = items.filter((item) => "occurrences" in item);
  const activityItems = items.filter((item) => "activityId" in item);

  return (
    <li className={styles.shiftRow}>
      <div className={styles.shiftPerson}>
        <div className={styles.portraitAndShift}>
          <Portrait
            name={name}
            photoUrl={shift?.employee?.imageURL}
            size="medium"
          />
          <div className={styles.text}>
            <Text element="span" weight="medium">
              {name}
            </Text>
            <CompetenceChip
              competence={competence}
              state="neutral"
              content={`${medicalCompetenceDictionary[competence].sv.short}, ${displayShiftTime}`}
            />
          </div>
        </div>
      </div>
      <div className={styles.routesAndActivities}>
        {visitItems.length !== 0 ? (
          <div className={styles.route}>
            <div className={styles.tiles}>
              {visitItems.map((item) => {
                const visit = item;
                return (
                  <TimelineVisitTile
                    key={visit.id}
                    visit={visit}
                    variant={isExpanded ? "default" : "compact"}
                  />
                );
              })}
            </div>
          </div>
        ) : null}
        {activityItems.length !== 0 ? (
          <div className={styles.activities}>
            <div className={styles.tiles}>
              {activityItems.map((item) => {
                const occurrence = item;
                return (
                  <TimelineActivityTile
                    key={occurrence.id}
                    activityOccurrence={occurrence}
                    variant={isExpanded ? "default" : "compact"}
                  />
                );
              })}
            </div>
          </div>
        ) : null}
        {activeActivityGroupId && (
          <AllocateToShiftTile
            activeActivityGroupId={activeActivityGroupId}
            variant={isExpanded ? "default" : "compact"}
          />
        )}
      </div>
    </li>
  );
};

const ShiftsSection = ({
  isExpanded,
  activeActivityGroupId,
}: {
  isExpanded: boolean;
  activeActivityGroupId: string | null;
}) => {
  const selectedDate = useSelectedDate();

  const {
    data: shiftsWithContents,
    isPending,
    isError,
    error,
  } = useQuery(shiftsWithContentsQueryOptions(selectedDate));

  if (isPending) {
    return <Loading message="Hämtar arbetspass" padding={24} />;
  }

  if (isError) {
    Sentry.captureException(error);
    return (
      <ErrorMessage
        message={`Gick inte att hämta arbetspassen. ${deducedError(error)}`}
        padding={24}
      />
    );
  }

  const ambulatingShiftsWithContents = shiftsWithContents.filter(
    ({ shift }) => shift.type === "Ambulating",
  );
  const otherShiftsWithContents = shiftsWithContents.filter(
    ({ shift }) => shift.type !== "Ambulating",
  );

  return (
    <>
      <section className={styles.shiftsSection}>
        <div className={styles.shiftsListHeader}>
          <Heading level="h2">Ambulerande</Heading>
        </div>
        {ambulatingShiftsWithContents.length === 0 ? (
          <NoResults
            message="Inga ambulerande pass schemalagda för dagen."
            padding={24}
          />
        ) : (
          <ul className={styles.shiftsList}>
            {ambulatingShiftsWithContents.map((shiftWithContents) => (
              <ShiftRow
                key={shiftWithContents.shift.id}
                shiftWithContents={shiftWithContents}
                isExpanded={isExpanded}
                activeActivityGroupId={activeActivityGroupId}
              />
            ))}
          </ul>
        )}
      </section>
      <section className={styles.shiftsSection}>
        <div className={styles.shiftsListHeader}>
          <Heading level="h2">Övriga</Heading>
        </div>
        {otherShiftsWithContents.length === 0 ? (
          <NoResults
            message="Inga övriga pass schemalagda för dagen."
            padding={24}
          />
        ) : (
          <ul className={styles.shiftsList}>
            {otherShiftsWithContents.map((shiftWithContents) => (
              <ShiftRow
                key={shiftWithContents.shift.id}
                shiftWithContents={shiftWithContents}
                isExpanded={isExpanded}
                activeActivityGroupId={activeActivityGroupId}
              />
            ))}
          </ul>
        )}
      </section>
    </>
  );
};

const ShiftsSideBar = ({
  setActiveActivityId,
  activeId,
}: {
  setActiveActivityId: (uniqueId: string) => void;
  activeId: string | null;
}) => {
  const selectedDate = new Date(useSelectedDate());

  const {
    data: unplannedOccurrencesAndGroups,
    isPending,
    isError,
    error,
  } = useUnplannedOccurrencesAndGroups(
    selectedDate.toDateString(),
    selectedDate.toDateString(),
  );

  const getUniqueId = (occurrenceOrGroup: IActivityOccurrenceOrGroup) => {
    return isGroup(occurrenceOrGroup)
      ? occurrenceOrGroup.id
      : occurrenceOrGroup.id + occurrenceOrGroup.activityId;
  };

  if (isPending) {
    return <Loading message="Hämtar oplanerade aktiviteter" padding={24} />;
  }

  if (isError) {
    Sentry.captureException(error);
    return (
      <ErrorMessage
        message={`Gick inte att hämta oplanerade aktiviteter. ${deducedError(error)}`}
        padding={24}
      />
    );
  }

  return (
    <section className={styles.toPlan}>
      <header className={styles.shiftsSideBoardHeader}>
        <Heading level="h2">Att planera</Heading>
      </header>
      {unplannedOccurrencesAndGroups.length === 0 ? (
        <NoResults message="Inga aktiviteter att planera." padding={24} />
      ) : (
        <RadioButtons orientation="vertical" width="fill-container">
          {unplannedOccurrencesAndGroups.map((occurrenceOrGroup) => {
            return (
              <UnplannedGroupTile
                key={`${isGroup(occurrenceOrGroup) ? occurrenceOrGroup.id : `${occurrenceOrGroup.activityId}-${occurrenceOrGroup.id}`}`}
                isActiveActivity={activeId === getUniqueId(occurrenceOrGroup)}
                activityOccurrenceOrGroup={occurrenceOrGroup}
                setActiveActivityId={setActiveActivityId}
              />
            );
          })}
        </RadioButtons>
      )}
    </section>
  );
};

const Shifts = () => {
  const { state }: { state?: { day?: string } } = useLocation();
  const [, setSearchParams] = useSearchParams();
  const [isExpanded, setIsExpanded] = useShiftsViewExpandedMode();
  const NO_ACTIVE_ACTIVITY_ID = null;
  const [activeActivityGroupId, setActiveActivityId] = useState<string | null>(
    NO_ACTIVE_ACTIVITY_ID,
  );

  const { data: allocateInShiftsView } = useFeatureFlag("AllocateInShiftsView");

  // If the state object has a day property, set the search params to that day
  // Passed from CommandCenterContainer.tsx
  useEffect(() => {
    if (state?.day) setSearchParams({ day: state?.day });
  }, [setSearchParams, state]);

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsExpanded(e.target.checked);
  };

  return (
    <section className={styles.container}>
      <section className={styles.toolbar}>
        <DaySwitcher size="h2" />
        <StandaloneCheckbox
          label="Expanderat läge"
          name="expanded-view"
          checked={isExpanded}
          onChange={handleCheckboxChange}
        />
      </section>
      <div className={styles.contentWrapper}>
        <section
          className={clsx(
            styles.content,
            allocateInShiftsView && styles.allocateInShiftsViewFlagActive,
          )}
        >
          {allocateInShiftsView ? (
            <ShiftsSideBar
              activeId={activeActivityGroupId}
              setActiveActivityId={setActiveActivityId}
            />
          ) : null}
          <div className={styles.shifts}>
            <ShiftsSection
              isExpanded={isExpanded}
              activeActivityGroupId={activeActivityGroupId}
            />
          </div>
        </section>
      </div>
    </section>
  );
};

export default Shifts;
