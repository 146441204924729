import * as Popover from "@radix-ui/react-popover";
import styles from "./DetailsPopover.module.scss";

type IDetailsPopover = {
  trigger: React.ReactNode;
  triggerProps?: Popover.PopoverTriggerProps;
  children: React.ReactNode;
  contentProps?: Popover.PopoverContentProps;
};

export const DetailsPopover = ({
  children,
  trigger,
  triggerProps,
  contentProps,
}: IDetailsPopover) => {
  return (
    <Popover.Root>
      <Popover.Trigger {...triggerProps}>{trigger}</Popover.Trigger>
      <Popover.Portal>
        <Popover.Content
          className={styles.content}
          sideOffset={4}
          align="start"
          {...contentProps}
        >
          {children}
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
};
