import { z } from "zod";

export const recurrencesPerDayOptions = [
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
] as const;

export const recurrencesPerDayOptionSchema = z.enum(recurrencesPerDayOptions);
export type IRecurrencesPerDayOption = z.infer<
  typeof recurrencesPerDayOptionSchema
>;

export const timeSensitivityOptions = [
  "0.25",
  "0.5",
  "1",
  "2",
  "4",
  "12",
] as const;

export const timeSensitivityOptionSchema = z.enum(timeSensitivityOptions);
export type ITimeSensitivityOption = z.infer<
  typeof timeSensitivityOptionSchema
>;

export const timeSensitivityMap: Record<ITimeSensitivityOption, string> = {
  "0.25": "+15min",
  "0.5": "+30min",
  "1": "+1h",
  "2": "+2h",
  "4": "+4h",
  "12": "+12h",
};
