import Form from "@/components/Form/Form";
import { useForm } from "react-hook-form";
import InputField from "@/components/InputField/InputField";
import { endOfDay } from "date-fns";
import { FilledButton } from "@components/Button/Button";
import styles from "./ActivityEndDateForm.module.scss";
import { activityKeys, updateEndDate, useActivity } from "@/api/Activities";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deducedError } from "@/Utils/ErrorUtils";
import { Loading } from "@components/Loading/Loading";
import { format } from "@models/date-and-time";

interface IFormData {
  endDate: string;
}

export const ActivityEndDateForm = ({
  activityId,
  onSubmitSuccess,
}: {
  activityId: string;
  onSubmitSuccess?: () => void;
}) => {
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<IFormData>();

  const {
    data: activity,
    isPending: isPendingActivity,
    isError: isActivityError,
  } = useActivity(activityId);

  const queryClient = useQueryClient();
  const { mutate, isPending } = useMutation({
    mutationFn: (endDateTime: Date) => updateEndDate(activityId, endDateTime),
    onError: (error) => {
      setError("endDate", {
        message: deducedError(error),
      });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: activityKeys.all });
      onSubmitSuccess?.();
    },
  });

  const validateAndSubmit = handleSubmit((formData) => {
    const { endDate } = formData;

    const endOfDayEndDateTime = endOfDay(new Date(endDate));

    mutate(endOfDayEndDateTime);
  });

  return isPendingActivity ? (
    <Loading message="Laddar" />
  ) : (
    <>
      <p className={styles.description}>
        Aktivitetsserien {/* Show activity name if succeeded to get activity */}
        {isActivityError ? (
          <></>
        ) : (
          <>
            för <strong>{activity.title}</strong>
          </>
        )}{" "}
        fortsätter fram till och med det datum du anger här.
      </p>
      <Form onSubmit={validateAndSubmit}>
        <InputField
          label="Datum"
          errorMessage={errors.endDate?.message}
          type="date"
          {...register("endDate", {
            required: {
              value: true,
              message: "Slutdatum behövs.",
            },
            min: {
              value: format(new Date(), "yyyy-MM-dd"),
              message: "Slutdatumet behöver vara idag eller senare.",
            },
          })}
        />
        <Form.SubmitButtonWrapper>
          <FilledButton type="submit" disabled={isPending}>
            Spara slutdatum
          </FilledButton>
        </Form.SubmitButtonWrapper>
      </Form>
    </>
  );
};
