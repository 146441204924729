import { IconButton, PlainButton } from "@components/Button/Button";
import styles from "./ActivityTemplates.module.scss";
import PlusIcon from "@components/icons/PlusIcon";
import { templates } from "./templates";
import { Heading } from "@components/Heading/Heading";
import CrossIcon from "@components/icons/CrossIcon";
import { useNavigate } from "react-router-dom";
import { useId } from "react";
import { Template } from "./Template";
import { DischargeTemplate } from "./GroupTemplates/DischargeTemplate";
import { AdmissionTemplate } from "./GroupTemplates/AdmissionTemplate";
import { MedicationPumpTemplate } from "./GroupTemplates/MedicationPumpTemplate";

export const ActivityTemplates = () => {
  const navigate = useNavigate();

  const listHeadingId = useId();

  const listedTemplates = Object.entries(templates).filter(([_, template]) => {
    return template.templateOptions.showInList;
  });

  const sortedTemplates = Object.fromEntries(
    listedTemplates.sort(([, aTemplate], [, bTemplate]) =>
      aTemplate.title.localeCompare(bTemplate.title, "sv"),
    ),
  );

  return (
    <article className={styles.activityTemplates}>
      <div className={styles.headingRow}>
        <Heading level="h1" size="h2">
          Ny aktivitet
        </Heading>
        <IconButton aria-label="Stäng" onClick={() => navigate("..")}>
          <CrossIcon />
        </IconButton>
      </div>
      <div className={styles.createCustomActivityButton}>
        <PlainButton onClick={() => navigate("../new")}>
          <PlusIcon />
          Skapa aktivitet
        </PlainButton>
      </div>

      <Heading
        id={listHeadingId}
        level="h2"
        weight="regular"
        className={styles.listHeading}
      >
        Välj mall
      </Heading>
      <ul className={styles.list} aria-labelledby={listHeadingId}>
        <div className={styles.listGroup}>
          <AdmissionTemplate />
          <DischargeTemplate />
          <MedicationPumpTemplate />
        </div>
        <div className={styles.listGroup}>
          {Object.entries(sortedTemplates).map(([name, template]) => {
            return (
              <li key={name}>
                <Template template={template} />
              </li>
            );
          })}
        </div>
      </ul>
    </article>
  );
};
