import { useLocalStorage } from "@/Utils/useLocalStorage";

export const useShiftsViewExpandedMode = () => {
  const [storedValue, setStoredValue] = useLocalStorage(
    "shiftsViewExpanded",
    "false",
  );

  const isExpanded = storedValue === "true";

  const setIsExpanded = (value: boolean) => {
    setStoredValue(value ? "true" : "false");
  };

  return [isExpanded, setIsExpanded] as const;
};
