import styles from "./Patients.module.scss";
import { Outlet, useNavigate } from "react-router-dom";
import PlusIcon from "@components/icons/PlusIcon";
import { PlainButton } from "@components/Button/Button";
import { Heading } from "@components/Heading/Heading";
import { Tab, TabList, TabPanel } from "@components/Tabs";
import { patientStatusDictionary } from "@models/patients";
import { useRoomNamesWithUnreadNotifications } from "@chat/useUnreadNotificationCounts";
import {
  patientsWithUnhandledMeasurementsQueryOptions,
  usePatients,
} from "@/api/Patients";
import { useQuery } from "@tanstack/react-query";
import UnreadChatMessagesIndicator from "@/components/IconWithIndicator/UnreadChatMessagesIndicator/UnreadChatMessagesIndicator";
import UnhandledMeasurementsIndicator from "@/components/IconWithIndicator/UnhandledMeasurementsIndicator/UnhandledMeasurementsIndicator";

const Header = () => {
  const navigate = useNavigate();
  const { data: prospectPatients } = usePatients({ statuses: ["prospect"] });
  const { data: admittedPatients } = usePatients({
    statuses: ["admitted"],
  });

  const roomIdsWithUnreadNotifications = useRoomNamesWithUnreadNotifications();
  const { data: patientsWithUnhandledMeasurements } = useQuery(
    patientsWithUnhandledMeasurementsQueryOptions,
  );

  const someProspectPatientHasUnreadChat =
    prospectPatients?.some((patient) =>
      roomIdsWithUnreadNotifications?.some((id) => id === patient.id),
    ) ?? false;
  const someAdmittedPatientHasUnreadChat =
    admittedPatients?.some((patient) =>
      roomIdsWithUnreadNotifications?.some((id) => id === patient.id),
    ) ?? false;

  const someProspectPatientHasUnhandledMeasurements =
    prospectPatients?.some((patient) =>
      patientsWithUnhandledMeasurements?.some(
        ({ id, unhandledMeasurementsCount }) =>
          id === patient.id && unhandledMeasurementsCount > 0,
      ),
    ) ?? false;
  const someAdmittedPatientHasUnhandledMeasurements =
    admittedPatients?.some((patient) =>
      patientsWithUnhandledMeasurements?.some(
        ({ id, unhandledMeasurementsCount }) =>
          id === patient.id && unhandledMeasurementsCount > 0,
      ),
    ) ?? false;

  return (
    <header className={styles.header}>
      <div className={styles.topRow}>
        <Heading level="h1">Patienter</Heading>

        <PlainButton onClick={() => navigate(`new-prospect`)}>
          <PlusIcon />
          Registrera patient
        </PlainButton>
      </div>
      <TabList>
        <Tab to="prospect">
          {`${patientStatusDictionary.prospect.plural.sv} (${prospectPatients?.length ?? "-"})`}
          <UnreadChatMessagesIndicator
            hasUnreadMessages={someProspectPatientHasUnreadChat}
          />

          <UnhandledMeasurementsIndicator
            hasUnhandledMeasurements={
              someProspectPatientHasUnhandledMeasurements
            }
          />
        </Tab>
        <Tab to="admitted">
          {`${patientStatusDictionary.admitted.plural.sv} (${admittedPatients?.length ?? "-"})`}
          <UnreadChatMessagesIndicator
            hasUnreadMessages={someAdmittedPatientHasUnreadChat}
          />
          <UnhandledMeasurementsIndicator
            hasUnhandledMeasurements={
              someAdmittedPatientHasUnhandledMeasurements
            }
          />
        </Tab>
        <Tab to="discharged">
          {patientStatusDictionary.discharged.plural.sv}
        </Tab>
      </TabList>
    </header>
  );
};

const Patients = () => {
  return (
    <>
      <Header />
      <TabPanel>
        <Outlet />
      </TabPanel>
    </>
  );
};

export default Patients;
