import InputField from "@/components/InputField/InputField";
import { useLayoutEffect } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import Form from "@/components/Form/Form";
import { timeSuggestions } from "./timeSuggestions";

export type ITimeSlotsFields = { timeslots: { time: string }[] };

export const TimeSlots = ({ recurrences }: { recurrences: number }) => {
  const {
    control,
    formState: { errors },
    register,
  } = useFormContext<ITimeSlotsFields>();

  const { fields, append, remove } = useFieldArray({
    control,
    name: "timeslots",
  });

  useLayoutEffect(() => {
    if (recurrences > fields.length) {
      append({ time: "" });
    }
    if (recurrences < fields.length) {
      remove(fields.length - 1);
    }
  }, [append, fields.length, recurrences, remove]);

  return (
    <Form.Row type="flex">
      {fields.map((field, index) => (
        <InputField
          key={field.id}
          label={`Tid`}
          errorMessage={errors.timeslots?.[index]?.time?.message}
          type="time"
          suggestions={timeSuggestions}
          {...register(`timeslots.${index}.time`, {
            required: {
              value: true,
              message: "Aktiviteten behöver en tid.",
            },
            pattern: {
              value: /^\d{1,2}:\d{2}$/,
              message: "Tid anges på formatet HH:mm.",
            },
          })}
        />
      ))}
    </Form.Row>
  );
};
