import { Popover } from "@/components/Popover/Popover";
import DocumentWithTextIcon from "@components/icons/DocumentWithTextIcon";

export const ActivityDescriptionPopover = ({
  description,
}: {
  description: string;
}) => {
  return (
    <Popover
      trigger={{
        icon: <DocumentWithTextIcon />,
        ariaLabel: "Visa beskrivning",
      }}
    >
      {description}
    </Popover>
  );
};
