import { ActivityTitle } from "@/components/ActivityTitle/ActivityTitle";
import { Link } from "@components/Link/Link";
import { IActivityOccurrenceOrGroup, isGroup } from "@models/activities";
import { useLocation } from "react-router-dom";

export const TitleCell = ({
  activityOccurrenceOrGroup,
}: {
  activityOccurrenceOrGroup: IActivityOccurrenceOrGroup;
}) => {
  const queryParams = useLocation().search;

  if (!isGroup(activityOccurrenceOrGroup)) {
    const { activityId, id } = activityOccurrenceOrGroup;

    return (
      <ActivityTitle
        activityOccurrence={activityOccurrenceOrGroup}
        linkTo={`${activityId}/occurrences/${id}${queryParams}`}
        size="small"
      />
    );
  }

  return (
    <>
      <Link
        to={`occurrence-groups/${activityOccurrenceOrGroup.id}${queryParams}`}
      >
        Hembesök
      </Link>
      <ul>
        {activityOccurrenceOrGroup.occurrences.map((activityOccurrence) => {
          const { id, activityId } = activityOccurrence;
          return (
            <li key={`${activityId}${id}`}>
              <ActivityTitle
                activityOccurrence={activityOccurrence}
                linkTo={`${activityId}/occurrences/${id}${queryParams}`}
                weight="regular"
                size="small"
              />
            </li>
          );
        })}
      </ul>
    </>
  );
};
