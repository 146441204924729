import { Helmet } from "react-helmet-async";
import { Outlet } from "react-router-dom";
import styles from "./AmbulatingContainer.module.scss";
import clsx from "clsx";
import { useUser } from "@/api/Users";
import { InlineAlert } from "@components/InlineAlert/InlineAlert";
import { useMatrixClient } from "@chat/matrixClient";
import { useCallback, useEffect, useState } from "react";
import { ClientEvent } from "matrix-js-sdk";
import ChatContext from "@chat/ChatContext";
import { fetchCurrentEmployee } from "@/api/Employee";
import { useUnreadNotifications } from "@chat/useUnreadNotifications";
import { usePushyRegistration } from "@/Utils/PushyUtils";
import { getEmployeeJwt } from "@/api/Chat";
import { useGoLogisticsSSEHandlers } from "@/Utils/sseHandlers";
import { LoadingOverlay } from "@components/Loading/Loading";
import Header from "@/pages/ambulating/Header";
import { useAppVisibilityBreadcrumbs } from "../useAppVisibilityBreadcrumbs";

const NotificationPermitter = () => {
  const { data: user } = useUser();
  const id = user?.employeeId;
  const { isRegistered, registerPushy } = usePushyRegistration();
  return isRegistered ? null : (
    <InlineAlert
      title="Tillåt aviseringar"
      message="Din tillåtelse för aviseringar krävs för att kunna få notiser om nya meddelanden i chatten."
      type="warning"
      actions={[
        {
          label: "Tillåt",
          onClick: () => registerPushy(id),
          isPrimary: true,
        },
      ]}
    />
  );
};

const useHasLoadedForSeconds = (seconds: number) => {
  const [
    hasLoadedLongerThanSuppliedTimeout,
    setHasLoadedLongerThanSuppliedTimeout,
  ] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setHasLoadedLongerThanSuppliedTimeout(true);
    }, seconds * 1000);
    return () => clearTimeout(timeout);
  }, [seconds]);

  return hasLoadedLongerThanSuppliedTimeout;
};

const ChatProvider = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  const getJwt = useCallback(
    () => fetchCurrentEmployee().then((employee) => getEmployeeJwt(employee)),
    [],
  );

  const matrixClient = useMatrixClient("Go", getJwt, true);
  const loadedForALongTime = useHasLoadedForSeconds(3);

  // Use debug chat
  useEffect(() => {
    if (matrixClient === null) {
      return;
    }

    if (import.meta.env.VITE_DEBUG_CHAT) {
      matrixClient.on(ClientEvent.Event, console.log);

      return () => {
        matrixClient.off(ClientEvent.Event, console.log);
      };
    }
  }, [matrixClient]);

  const unreadNotifications = useUnreadNotifications(matrixClient);
  const chatIsFullyLoaded = unreadNotifications !== undefined;

  return (
    <ChatContext.Provider
      value={{ matrixClient, unreadNotifications, chatEnabled: true }}
    >
      <LoadingOverlay show={!chatIsFullyLoaded && !loadedForALongTime}>
        {children}
      </LoadingOverlay>
    </ChatContext.Provider>
  );
};

const AmbulatingContainer = () => {
  useGoLogisticsSSEHandlers();
  useAppVisibilityBreadcrumbs();

  return (
    <ChatProvider>
      <div
        className={clsx([
          styles.ambulatingContainer,
          styles.componentVariableOverrides,
        ])}
      >
        <Header />
        <main className={styles.main}>
          <section className={styles.banners}>
            <NotificationPermitter />
          </section>
          <Helmet>
            <body className={styles.body} />
          </Helmet>
          <Outlet />
        </main>
      </div>
    </ChatProvider>
  );
};

export default AmbulatingContainer;
