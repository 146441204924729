import styles from "./index.module.scss";
import {
  useReactTable,
  createColumnHelper,
  flexRender,
  GroupingState,
  SortingState,
  Row,
  getSortedRowModel,
  getCoreRowModel,
} from "@tanstack/react-table";
import { useSelectedDate } from "@/Utils/useSelectedDate";
import {
  activityCategoryDictionary,
  useActivityOccurrencesAndGroups,
} from "@/api/Activities";
import { Loading } from "@components/Loading/Loading";
import ErrorMessage from "@components/ErrorMessage/ErrorMessage";
import { deducedError } from "@/Utils/ErrorUtils";
import { getDataFromActivityOccurrencesAndGroups } from "./getDataFromActivityOccurrences";
import { useMemo, useState } from "react";
import {
  activityOccurrenceStatusSchema,
  categorySchema,
  IActivityCategory,
  IActivityOccurrenceOrGroup,
  IActivityOccurrenceStatus,
  isGroup,
  ITimeOfDay,
  orderedStatuses,
} from "@models/activities";
import { ActorsCell } from "./ActorsCell";
import { StatusTag } from "@/components/StatusTag/StatusTag";
import { StatusTagWithDropdown } from "@/components/StatusTagWithDropdown/StatusTagWithDropdown";
import { getPatientNameWithStatus } from "@/api/Patients";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import { Link } from "@components/Link/Link";
import { CategoryIcon } from "@/components/CategoryIcon/CategoryIcon";
import { IPatient } from "@models/patients";
import RadioButtons from "@/components/RadioButton/RadioButtons";
import RadioButton from "@/components/RadioButton/RadioButton";
import { TitleCell } from "./TitleCell";
import { BlockCell } from "./BlockCell";
import * as Sentry from "@sentry/react";
import {
  IScheduledShift,
  shiftName,
  shiftTimeDisplayValues,
} from "@models/shifts";
import { customGetGroupedRowModel } from "./customGetGroupedRowModel";
import { TimeSpan } from "@/components/Time/TimeSpan";
import { z } from "zod";
import { statusDictionary } from "@/components/StatusTag/statusTagUtils";

type IActivityTableItem = {
  activityOccurrenceOrGroup: IActivityOccurrenceOrGroup;
  category: IActivityCategory;
  timespan: [Date, Date, ITimeOfDay];
  status: IActivityOccurrenceStatus[];
  title: IActivityOccurrenceOrGroup;
  patient?: Pick<IPatient, "id" | "name" | "status"> | null;
  actors: IActivityOccurrenceOrGroup;
  block: IActivityOccurrenceOrGroup;
};

const GROUPABLE_COLUMNS = ["category", "status", "patient", "actors"] as const;
const SORTABLE_COLUMNS = ["timespan", "status", "patient"] as const;
const COLUMN_DISPLAY_NAMES = {
  category: "Aktivitetstyp",
  patient: "Patient",
  status: "Status",
  timespan: "Tid",
  actors: "Utförare",
};

const NOT_ASSIGNABLE = "NOT_ASSIGNABLE";
const NO_ONE_ASSIGNED = "NO_ONE_ASSIGNED";

const columnHelper = createColumnHelper<IActivityTableItem>();

const columns = [
  columnHelper.accessor("category", {
    header: COLUMN_DISPLAY_NAMES["category"],
    cell: ({ getValue }) => {
      return <CategoryIcon category={getValue()} size="small" />;
    },
  }),
  columnHelper.accessor("timespan", {
    header: "Tid",
    cell: ({ getValue }) => {
      const [start, end, type] = getValue();
      return <TimeSpan start={start} end={end} timeOfDay={type} />;
    },
    sortingFn: (a, b) => {
      const aState = a.getValue<[Date, Date, ITimeOfDay]>("timespan");
      const bState = b.getValue<[Date, Date, ITimeOfDay]>("timespan");

      // Grouped rows are unlikely to have a timespan
      // If we don't have a timespan, we can't sort by it
      if (!aState || !bState) return 0;

      // Any time of day -> end of list
      if (aState[2] === "Any") {
        return 1;
      }
      // Any time of day -> end of list
      if (bState[2] === "Any") {
        return 1;
      }
      if (aState[0] < bState[0]) {
        return -1;
      } else if (aState[0] > bState[0]) {
        return 1;
      } else if (aState[1] < bState[1]) {
        return -1;
      } else if (aState[1] > bState[1]) {
        return 1;
      } else {
        return 0;
      }
    },
  }),
  columnHelper.accessor("status", {
    header: COLUMN_DISPLAY_NAMES["status"],
    cell: ({ getValue, row: { original } }) => {
      const { activityOccurrenceOrGroup } = original;
      if (!isGroup(activityOccurrenceOrGroup)) {
        const { category, activityId, id, status } = activityOccurrenceOrGroup;
        return (
          <StatusTagWithDropdown
            status={status}
            category={category}
            activityId={activityId}
            occurrenceId={id}
          />
        );
      }

      const statuses = getValue();
      const { occurrences } = activityOccurrenceOrGroup;
      return (
        <ul>
          {statuses.map((status, index) => {
            // Accessing occurrences here is temporary. When a group has its own status, it should be rendered here instead.
            // @ts-expect-error - Shouldn't direct access without checking, but here we know it's safe
            const { id, activityId } = occurrences[index];

            return (
              <li
                key={`${activityId}${id}`}
                className={styles.multipleStatusesListItem}
              >
                <StatusTag status={status} size="tiny" />
              </li>
            );
          })}
        </ul>
      );
    },
    getGroupingValue: ({ status }) => {
      return status.join(",");
    },
    sortingFn: (a, b) => {
      const aState = a.getValue<IActivityOccurrenceStatus[]>("status");
      const bState = b.getValue<IActivityOccurrenceStatus[]>("status");

      // For grouped rows, we take the first occurrence's status.
      // For non-grouped rows, we take the status directly.
      if (!aState[0] || !bState[0]) return 0;
      if (
        orderedStatuses.indexOf(aState[0]) < orderedStatuses.indexOf(bState[0])
      ) {
        return -1;
      } else if (
        orderedStatuses.indexOf(aState[0]) > orderedStatuses.indexOf(bState[0])
      ) {
        return 1;
      } else {
        return 0;
      }
    },
  }),
  columnHelper.accessor("title", {
    header: "Aktivitet",
    cell: ({ getValue }) => {
      const activityOccurrenceOrGroup = getValue();
      return (
        <TitleCell activityOccurrenceOrGroup={activityOccurrenceOrGroup} />
      );
    },
  }),
  columnHelper.accessor("patient", {
    header: COLUMN_DISPLAY_NAMES["patient"],
    cell: ({ getValue }) => {
      const patient = getValue();
      return patient ? (
        <Link to={`../patients/${patient.id}`} weight="regular">
          {getPatientNameWithStatus(patient)}
        </Link>
      ) : undefined;
    },
    getGroupingValue: ({ patient }) => {
      return patient ? patient.id : undefined;
    },
    sortingFn: (a, b) => {
      const aState = a.getValue<IPatient | undefined>("patient");
      const bState = b.getValue<IPatient | undefined>("patient");

      // Sort patient-less activities to the top
      if (!aState) {
        return -1;
      } else if (!bState) {
        return 1;
      }

      if (aState.name < bState.name) {
        return -1;
      } else if (aState.name > bState.name) {
        return 1;
      } else if (aState.id < bState.id) {
        return -1;
      } else if (aState.id > bState.id) {
        return 1;
      }
      return 0;
    },
  }),
  columnHelper.accessor("block", {
    header: "Rutt",
    cell: ({ getValue }) => {
      const activityOccurrenceOrGroup = getValue();
      if (isGroup(activityOccurrenceOrGroup)) {
        return <BlockCell groupId={activityOccurrenceOrGroup.id} />;
      }
      return activityOccurrenceOrGroup.category === "HomeVisit" ? (
        <BlockCell
          activityId={activityOccurrenceOrGroup.activityId}
          activityOccurrenceId={activityOccurrenceOrGroup.id}
        />
      ) : null;
    },
  }),
  columnHelper.accessor("actors", {
    header: "Utförare",
    cell: ({ getValue }) => {
      const activityOccurrenceOrGroup = getValue();
      return (
        <ActorsCell activityOccurrenceOrGroup={activityOccurrenceOrGroup} />
      );
    },
    getGroupingValue: ({ actors: activityOccurrence }) => {
      if (!("assignees" in activityOccurrence)) return NOT_ASSIGNABLE;
      if (activityOccurrence.assignees.length === 0) return NO_ONE_ASSIGNED;
      return activityOccurrence.assignees
        .map((assignee) => assignee.id)
        .join(",");
    },
    sortingFn: (a, b) => {
      // Only do assignees sorting on group level
      // On sub-row level, sort by next column, typically time
      if (a.depth > 0 && b.depth > 0) {
        return 0;
      }

      // Put unassignable activities at the bottom
      if (a.groupingValue === NOT_ASSIGNABLE) return 1;
      if (b.groupingValue === NOT_ASSIGNABLE) return -1;

      // Equivalent to checking `groupingValue` above.
      // TypeScript does not understand this relation, so we need this as typeguard.
      if (
        !("assignees" in a.original.actors) ||
        !("assignees" in b.original.actors)
      ) {
        return 0;
      }

      // Then put activities with no assignees at the bottom
      if (a.groupingValue === NO_ONE_ASSIGNED) return 1;
      if (b.groupingValue === NO_ONE_ASSIGNED) return -1;

      const getSortingValues = (
        assignees: IScheduledShift[],
        groupingValue: unknown,
      ) => {
        const assignee = assignees.find(
          (assignee) => assignee.id.toString() === groupingValue,
        );

        // Should never happen, since `groupingValue` is derived from assignees.
        // If it happens, just bail with default values.
        if (!assignee) {
          Sentry.captureException(new Error("Could not find assignee"));
          return { start: new Date(), end: new Date(), name: "" };
        }
        return {
          start: assignee.startDateTime,
          end: assignee.endDateTime,
          name: shiftName({ ...assignee, options: { length: "long" } }),
        };
      };

      const {
        start: aStart,
        end: aEnd,
        name: aName,
      } = getSortingValues(a.original.actors.assignees, a.groupingValue);
      const {
        start: bStart,
        end: bEnd,
        name: bName,
      } = getSortingValues(b.original.actors.assignees, b.groupingValue);

      return aStart < bStart
        ? -1
        : aStart > bStart
          ? 1
          : aEnd < bEnd
            ? -1
            : aEnd > bEnd
              ? 1
              : aName < bName
                ? -1
                : aName > bName
                  ? 1
                  : 0;
    },
  }),
];

const groupingSchema = z.union([
  z.object({ id: z.literal("actors"), value: z.string() }),
  z.object({ id: z.literal("category"), value: categorySchema }),
  z.object({ id: z.literal("patient"), value: z.string() }),
  z.object({ id: z.literal("status"), value: activityOccurrenceStatusSchema }),
]);
type IGrouping = z.infer<typeof groupingSchema>;

const GroupHeaderRow = ({
  columnCount,
  grouping,
}: {
  columnCount: number;
  grouping: IGrouping;
}) => {
  const groupHeaderDisplayValue = (grouping: IGrouping) => {
    if (grouping.id === "category") {
      return activityCategoryDictionary[grouping.value];
    }
    if (grouping.id === "status") {
      return statusDictionary[grouping.value].sv;
    }

    // For actors we group on list of ids, but we display the list of names
    if (grouping.id === "actors") {
      if (grouping.value === NOT_ASSIGNABLE) return "Inte tilldelbar";
      if (grouping.value === NO_ONE_ASSIGNED) return "Ingen tilldelad";
      return grouping.value;
    }

    return grouping.value;
  };
  return (
    <tr>
      <th colSpan={columnCount} className={styles.groupHeaderCell}>
        {groupHeaderDisplayValue(grouping)}
      </th>
    </tr>
  );
};

const groupValue = (row: Row<IActivityTableItem>, selectedDate: Date) => {
  // For patients we group on id, but we display the name (which in rare cases may be duplicated)
  if (row.groupingColumnId === "patient") {
    return row.original.patient
      ? row.original.patient.name
      : // If there is no patient, fallback to a hyphen
        "-";
  }

  // For actors we group on list of ids, but we display the list of names
  if (row.groupingColumnId === "actors") {
    if (row.groupingValue === NOT_ASSIGNABLE) return row.groupingValue;
    if (row.groupingValue === NO_ONE_ASSIGNED) return row.groupingValue;

    // Equivalent to checking `groupingValue` above.
    // TypeScript does not understand this relation, so we need this as typeguard.
    if (!("assignees" in row.original.actors)) {
      return NOT_ASSIGNABLE;
    }

    const assignedShift = row.original.actors.assignees.find((assignee) => {
      return row.groupingValue === assignee.id.toString();
    });

    // Should never happen, since `groupingValue` is derived from assignees.
    if (!assignedShift) {
      Sentry.captureException(new Error("Could not find any assigned shifts"));
      return NO_ONE_ASSIGNED;
    }

    const {
      startedDayBefore,
      hasEndTimeAfterToday,
      formattedDayBefore,
      formattedDayToday,
    } = shiftTimeDisplayValues({
      selectedDate,
      startDateTime: assignedShift.startDateTime,
      endDateTime: assignedShift.endDateTime,
    });

    const suffix = startedDayBefore
      ? ` Start ${formattedDayBefore}`
      : hasEndTimeAfterToday
        ? ` Start ${formattedDayToday}`
        : "";

    return `${shiftName({ ...assignedShift, options: { length: "long" } })} (${assignedShift.startDateTime.getHours()}-${assignedShift.endDateTime.getHours()})${suffix}`;
  }

  return row.groupingValue;
};

const Group = ({ groupRow }: { groupRow: Row<IActivityTableItem> }) => {
  const [parent] = useAutoAnimate();
  const selectedDate = new Date(useSelectedDate());
  const typeSafeGrouping = groupingSchema.parse({
    id: groupRow.groupingColumnId,
    value: groupValue(groupRow, selectedDate),
  });
  return (
    <tbody ref={parent}>
      <GroupHeaderRow
        columnCount={groupRow.getVisibleCells().length}
        grouping={typeSafeGrouping}
      />
      {groupRow.subRows.map((row) => (
        <tr key={row.id}>
          {row.getVisibleCells().map((cell) => (
            <td key={cell.id}>
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </td>
          ))}
        </tr>
      ))}
    </tbody>
  );
};

export const ActivitiesTable = () => {
  const selectedDate = new Date(useSelectedDate());

  const {
    data: activityOccurrencesAndGroups,
    isPending,
    isError,
    error,
  } = useActivityOccurrencesAndGroups(
    selectedDate.toDateString(),
    selectedDate.toDateString(),
  );

  const data = useMemo(
    () =>
      getDataFromActivityOccurrencesAndGroups(
        activityOccurrencesAndGroups || [],
      ),
    [activityOccurrencesAndGroups],
  );
  const [grouping, setGrouping] = useState<GroupingState>(["category"]);
  const [inGroupSorting, setInGroupSorting] = useState<SortingState>([
    { id: "timespan", desc: false },
  ]);
  const sorting = useMemo(
    () => [
      ...grouping.map((group) => ({ id: group, desc: false })),
      ...inGroupSorting.map((sort) => ({
        id: sort.id,
        desc: sort.desc,
      })),
      { id: "timespan", desc: false },
    ],
    [grouping, inGroupSorting],
  );
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getGroupedRowModel: customGetGroupedRowModel(),
    getSortedRowModel: getSortedRowModel(),
    groupedColumnMode: false, // Don't move grouped columns to the left
    state: {
      grouping,
      sorting,
    },
  });

  const [parent] = useAutoAnimate();

  if (isError) {
    Sentry.captureException(error);
    return (
      <ErrorMessage
        message={`Gick inte att hämta in aktiviteterna. ${deducedError(error)}`}
        padding={24}
      />
    );
  }

  return (
    <>
      <article className={styles.tableToolbar}>
        <RadioButtons orientation="horizontal" legend="Gruppering">
          {GROUPABLE_COLUMNS.map((column) => (
            <RadioButton
              label={{ text: COLUMN_DISPLAY_NAMES[column] }}
              name="group-by"
              key={column}
              value={column}
              checked={grouping[0] === column}
              visualStyle="framed"
              onChange={(e) => setGrouping([e.target.value])}
            />
          ))}
        </RadioButtons>
        <RadioButtons orientation="horizontal" legend="Ordning">
          {SORTABLE_COLUMNS.map((column) => (
            <RadioButton
              label={{ text: COLUMN_DISPLAY_NAMES[column] }}
              name="order-by"
              key={column}
              value={column}
              checked={inGroupSorting[0]?.id === column}
              visualStyle="framed"
              onChange={(e) =>
                setInGroupSorting([{ id: e.target.value, desc: false }])
              }
            />
          ))}
        </RadioButtons>
      </article>
      {isPending ? (
        <Loading message="Hämtar aktiviteter" padding={24} />
      ) : (
        <div className={styles.tableContainer}>
          <table
            className={styles.activitiesTable}
            key={selectedDate.toDateString()}
            ref={parent}
          >
            <thead className={styles.thead}>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <th className={styles.tableHeaderCell} key={header.id}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            {/* Grouping logic/markup inspired by https://github.com/TanStack/table/discussions/3320#discussioncomment-1453996 */}
            {table.getRowModel().rows.map((groupedRow) => {
              return <Group key={groupedRow.id} groupRow={groupedRow} />;
            })}
          </table>
        </div>
      )}
    </>
  );
};
