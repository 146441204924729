import RadioButton from "@/components/RadioButton/RadioButton";
import RadioButtons from "@/components/RadioButton/RadioButtons";
import { useFormContext } from "react-hook-form";
import { IRecurringOption, recurringDictionary } from "./recurrenceUtils";

export type IRecurrencePickerFields = {
  recurrence: IRecurringOption;
};

export const RecurrencePicker = ({
  onlyRecurringOptions = false,
}: {
  onlyRecurringOptions?: boolean;
}) => {
  const {
    formState: { errors },
    register,
  } = useFormContext<IRecurrencePickerFields>();

  return (
    <RadioButtons
      legend={"Återkommer"}
      errorMessage={errors.recurrence?.message}
      orientation="horizontal"
    >
      {!onlyRecurringOptions ? (
        <RadioButton
          label={{ text: recurringDictionary.never }}
          {...register(`recurrence`)}
          value={recurringDictionary.never}
          visualStyle="framed"
        />
      ) : (
        <></>
      )}
      {/* Only one RadioButton has to hold additional logic, applies to whole registration. */}
      <RadioButton
        label={{ text: recurringDictionary.everyDay }}
        {...register(`recurrence`, {
          required: {
            value: true,
            message: "Aktiviteten behöver ett val för återkommande tillfällen.",
          },
        })}
        value={recurringDictionary.everyDay}
        visualStyle="framed"
      />
      <RadioButton
        label={{ text: recurringDictionary.every2ndDay }}
        {...register(`recurrence`)}
        value={recurringDictionary.every2ndDay}
        visualStyle="framed"
      />
      <RadioButton
        label={{ text: recurringDictionary.every3rdDay }}
        {...register(`recurrence`)}
        value={recurringDictionary.every3rdDay}
        visualStyle="framed"
      />
      <RadioButton
        label={{ text: recurringDictionary.every5thDay }}
        {...register(`recurrence`)}
        value={recurringDictionary.every5thDay}
        visualStyle="framed"
      />
      <RadioButton
        label={{ text: recurringDictionary.custom }}
        {...register(`recurrence`)}
        value={recurringDictionary.custom}
        visualStyle="framed"
      />
    </RadioButtons>
  );
};
