import { PATIENT_API_URL } from "@/Utils/EnvUtils";
import { Iframe } from "@components/Iframe/Iframe";
import { z } from "zod";
import { useNavigate, useParams } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { patientKeys } from "@/api/Patients";

export const AdmitPatient = () => {
  const { patientId } = z
    .object({ patientId: z.string().uuid() })
    .parse(useParams());
  const navigate = useNavigate();

  const queryClient = useQueryClient();
  const postMessageHandler = {
    messageType: "patientAdmitted",
    onMessageReceived: () => {
      queryClient.invalidateQueries({
        queryKey: patientKeys.detail(patientId),
      });
      navigate(`../activities/templates`);
    },
  };

  return (
    <Iframe
      src={PATIENT_API_URL + `/htmx/center/patients/${patientId}/admit`}
      loadingMessage="Laddar in patient"
      loadingPadding={24}
      title="Inskrivning av patient"
      postMessageHandler={postMessageHandler}
    />
  );
};
