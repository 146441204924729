import { RefObject, useEffect } from "react";
import { IChatMessage } from "./useMatrixEvents";

/**
 * @description Scrolls the message panel to the bottom when new messages are added
 * @returns Function to scroll the message panel to the bottom manually
 */
export const useChatScroll = ({
  messagePanelRef,
  inputTextAreaScrollHeight,
  messages,
}: {
  messagePanelRef: RefObject<HTMLDivElement>;
  inputTextAreaScrollHeight: number;
  messages: IChatMessage[];
}) => {
  const scrollBehavior = window.matchMedia("(prefers-reduced-motion: reduce)")
    .matches
    ? "auto"
    : "smooth";
  const scrollMessagePanelToBottom = () => {
    messagePanelRef.current?.scrollTo({
      top: messagePanelRef.current.scrollHeight,
      behavior: scrollBehavior,
    });
  };

  const lastMessageIdentity = messages[messages.length - 1]?.id;

  // Scroll to bottom when new messages are added
  useEffect(() => {
    const scrollBehavior = window.matchMedia("(prefers-reduced-motion: reduce)")
      .matches
      ? "auto"
      : "smooth";
    messagePanelRef.current?.scrollTo({
      top: messagePanelRef.current.scrollHeight,
      behavior: scrollBehavior,
    });
  }, [lastMessageIdentity, messagePanelRef, inputTextAreaScrollHeight]);

  return { scrollMessagePanelToBottom };
};
