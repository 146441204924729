import clsx from "clsx";
import styles from "./CircledInitials.module.scss";

/**
 * Accepts a name (string) and returns the initials of the string.
 *
 * @param name
 * @returns The initials of a name. E.g. "John Doe" -> "JD", "John-Marcus Doe" -> "JMD", "John Marcus Doe" -> "JMD", "John" -> "J"
 */
export const getInitials = (name: string): string => {
  const initials = name
    .split(/[\s-]+/)
    .map((word) => word[0])
    .join("")
    .toUpperCase();
  return initials;
};

interface ICircledInitials {
  name: string;
  size?: "small" | "medium" | "large";
}
function CircledInitials({ name, size = "small" }: ICircledInitials) {
  return (
    <div className={clsx(styles.circledInitials, styles[size])}>
      {getInitials(name)}
    </div>
  );
}

export default CircledInitials;
