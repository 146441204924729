import { IWeekday } from "@/api/Activities";

export const weekdayDictionaryMap = new Map<IWeekday, string>([
  ["Monday", "Måndag"],
  ["Tuesday", "Tisdag"],
  ["Wednesday", "Onsdag"],
  ["Thursday", "Torsdag"],
  ["Friday", "Fredag"],
  ["Saturday", "Lördag"],
  ["Sunday", "Söndag"],
]);
