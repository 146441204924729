import { RoutesContextProvider } from "./RoutesContext";
import { MapProvider } from "react-map-gl";
import { DaySwitcher } from "@/components/DaySwitcher/DaySwitcher";
import { RoutesMap } from "./RoutesMap";
import { RoutesTabs } from "./RoutesTabs";
import styles from "./Routes.module.scss";
import { useLocation, useSearchParams } from "react-router-dom";
import { useEffect } from "react";

export const Routes = () => {
  const { state }: { state?: { day?: string } } = useLocation();
  const [, setSearchParams] = useSearchParams();

  // If the state object has a day property, set the search params to that day
  // Passed from CommandCenterContainer.tsx
  useEffect(() => {
    if (state?.day) setSearchParams({ day: state?.day });
  }, [setSearchParams, state]);

  return (
    <RoutesContextProvider>
      <MapProvider>
        <div className={styles.container}>
          <div className={styles.routesContainer}>
            <div className={styles.daySwitcher}>
              <DaySwitcher />
            </div>
            <div className={styles.routes}>
              <RoutesTabs />
            </div>
          </div>
          <div className={styles.map}>
            <RoutesMap />
          </div>
        </div>
      </MapProvider>
    </RoutesContextProvider>
  );
};
