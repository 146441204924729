import { useFormContext } from "react-hook-form";
import Checkbox from "@/components/Checkbox/Checkbox";
import Checkboxes from "@/components/Checkbox/Checkboxes";
import {
  IMedicalCompetence,
  medicalCompetenceDictionary,
} from "@models/shifts";

export type ICompetencePickerFields = {
  requiredCompetences: IMedicalCompetence[];
};

const CompetencePicker = ({ isRequired = false }: { isRequired?: boolean }) => {
  const {
    formState: { errors },
    register,
  } = useFormContext<ICompetencePickerFields>();
  return (
    <Checkboxes
      legend={isRequired ? "Kompetensbehov" : "Kompetensbehov (valfri)"}
      errorMessage={errors.requiredCompetences?.message}
      orientation="horizontal"
    >
      {/* Only one Checkbox has to hold additional logic, applies to whole registration. */}
      <Checkbox
        label={{ text: medicalCompetenceDictionary.NurseAssistant.sv.short }}
        {...register(`requiredCompetences`, {
          required: {
            value: isRequired,
            message: "Minst en kompetens behöver anges.",
          },
        })}
        value="NurseAssistant"
        visualStyle="framed"
      />
      <Checkbox
        label={{ text: medicalCompetenceDictionary.NursePractitioner.sv.short }}
        {...register(`requiredCompetences`)}
        value="NursePractitioner"
        visualStyle="framed"
      />
      <Checkbox
        label={{ text: medicalCompetenceDictionary.MedicalDoctor.sv.short }}
        {...register(`requiredCompetences`)}
        value="MedicalDoctor"
        visualStyle="framed"
      />
      <Checkbox
        label={{ text: medicalCompetenceDictionary.Support.sv.long }}
        {...register(`requiredCompetences`)}
        value="Support"
        visualStyle="framed"
      />
    </Checkboxes>
  );
};

export { CompetencePicker };
