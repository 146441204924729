import ErrorMessage from "@components/ErrorMessage/ErrorMessage";
import { Loading } from "@components/Loading/Loading";
import { useAuth } from "react-oidc-context";
import { Navigate } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { deducedError } from "@/Utils/ErrorUtils";

export const Login = () => {
  const { activeNavigator, isLoading, isAuthenticated, error, user } =
    useAuth();

  if (isAuthenticated) {
    return <Navigate to={user?.state ? user.state : "/"} replace />;
  }

  if (activeNavigator || isLoading) {
    return <Loading message="Loggar in" padding={24} />;
  }

  if (error) {
    Sentry.captureException(error);
    return (
      <ErrorMessage
        message={`Kunde inte logga in. ${deducedError(error)}`}
        padding={24}
      />
    );
  }

  return null;
};
