import {
  useNavigate,
  useLocation,
  To,
  NavigateOptions,
} from "react-router-dom";

export const useNavigateWithPreservedQueryParams = () => {
  const navigate = useNavigate();
  const queryParams = useLocation().search;

  return {
    navigateWithPreservedQueryParams: (to: To, options?: NavigateOptions) => {
      const concatenatedTo = `${to}${queryParams}`;
      navigate(concatenatedTo, options);
    },
  };
};
