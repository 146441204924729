import { Link } from "@components/Link/Link";
import { IListPatient } from "@/api/Patients";
import { useCenterBaseUrl } from "@/Utils/useCenterBaseUrl";

export const NameCell = ({ patient }: { patient: IListPatient }) => {
  const centerBaseUrl = useCenterBaseUrl();
  return (
    <Link to={`${centerBaseUrl}/patients/${patient.id}`} weight="regular">
      {patient.name}
    </Link>
  );
};

export default NameCell;
