import styles from "./Routes.module.scss";
import { Loading } from "@components/Loading/Loading";
import ErrorMessage from "@components/ErrorMessage/ErrorMessage";
import Route from "./Route";
import NoResults from "@/components/NoResults/NoResults";
import { fetchEmployeesRoutes, routeKeys } from "@/api/Routes";
import { useQuery } from "@tanstack/react-query";
import { startOfToday } from "date-fns";
import { deducedError } from "@/Utils/ErrorUtils";
import * as Sentry from "@sentry/react";
import { format } from "@models/date-and-time";

function Routes() {
  const today = format(startOfToday(), "yyyy-MM-dd");
  const {
    data: employeesRoutes,
    isPending,
    isError,
    error,
  } = useQuery({
    queryKey: routeKeys.list({
      from: today,
      to: null,
      assignee: "me",
    }),
    queryFn: () => {
      return fetchEmployeesRoutes(today);
    },
  });

  if (isPending) {
    return <Loading message="Laddar dina rutter" />;
  }

  if (isError) {
    Sentry.captureException(error);
    return (
      <ErrorMessage
        message={`Kunde inte hämta dina rutter. ${deducedError(error)}`}
      />
    );
  }

  const noRoutes = employeesRoutes.length === 0;

  return (
    <div className={styles.routes}>
      {noRoutes ? (
        <NoResults message="Du har inga planerade besök idag." />
      ) : (
        <ul className={styles.routesList}>
          {employeesRoutes.map((route) => {
            return (
              <li key={route.id} className={styles.routesByDate}>
                <Route initialRoute={route} />
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
}

export default Routes;
