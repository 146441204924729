import { MatrixClient, MatrixEvent, RoomMemberEvent } from "matrix-js-sdk";
import { useEffect, useState } from "react";

export const useOtherUserIsTyping = ({
  matrixClient,
  otherUserMatrixId,
}: {
  matrixClient: MatrixClient | null;
  otherUserMatrixId: string;
}) => {
  const [typingUsers, setTypingUsers] = useState<string[]>([]);

  useEffect(() => {
    if (!matrixClient) return;

    const updateTypingState = (event: MatrixEvent) => {
      const currentlyTypingUserIds = event.event.content!.user_ids as string[];
      setTypingUsers(currentlyTypingUserIds);
    };

    matrixClient.on(RoomMemberEvent.Typing, updateTypingState);
    return () => {
      matrixClient.off(RoomMemberEvent.Typing, updateTypingState);
    };
  }, [matrixClient]);

  const otherUserIsTyping = typingUsers.includes(otherUserMatrixId);
  return { otherUserIsTyping };
};
