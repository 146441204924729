import { Dialog } from "@components/Dialog/Dialog";
import { AddActivityForm } from "@/forms/AddActivityForm/AddActivityForm";
import { useNavigateWithPreservedQueryParams } from "@/Utils/useNavigateWithPreservedQueryParams";
import { useCenterBaseUrl } from "@/Utils/useCenterBaseUrl";

export const AddActivityDialog = () => {
  const { navigateWithPreservedQueryParams } =
    useNavigateWithPreservedQueryParams();
  const centerBaseUrl = useCenterBaseUrl();

  return (
    <Dialog
      isOpen={true}
      onClose={() => {
        navigateWithPreservedQueryParams(`${centerBaseUrl}/activities`);
      }}
      title="Skapa ny aktivitet"
    >
      <AddActivityForm />
    </Dialog>
  );
};
