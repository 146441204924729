import { TextWithCheckOrCross } from "@/components/TextWithCheckOrCross/TextWithCheckOrCross";
import styles from "./$visitId.module.scss";
import { IActivityOccurrence } from "@models/activities";

const NonInteractiveActivityOccurrence = ({
  status,
  title,
  displayStatus,
}: Pick<IActivityOccurrence, "status" | "title"> & {
  displayStatus: boolean;
}) => {
  return (
    <>
      {displayStatus ? (
        <TextWithCheckOrCross condition={status === "finished"} text={title} />
      ) : (
        <span className={styles[status]}>{title}</span>
      )}
    </>
  );
};

export { NonInteractiveActivityOccurrence };
