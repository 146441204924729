import {
  patientKeys,
  removeRelative,
  useParentRoutesPatient,
} from "@/api/Patients";
import { PlainButton } from "@components/Button/Button";
import { AddRelative } from "@/forms/AddRelative";
import { EditAddress } from "@/forms/EditAddress";
import { EditInformation } from "@/forms/EditInformation";
import { EditPhoneNumber } from "@/forms/EditPhoneNumber";
import {
  deducedError,
  displayErrorMessageAlert,
  isErrorWithKnownErrorCode,
  knownErrorCodeSchema,
} from "@/Utils/ErrorUtils";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import styles from "./EditInformationWrappers.module.scss";
import { Heading } from "@components/Heading/Heading";
import { EditSafetyAlarmText } from "@/forms/EditSafetyAlarm";

export const EditAddressWrapper = () => {
  const patient = useParentRoutesPatient();
  const navigate = useNavigate();

  return (
    <section className={styles.editFormContainer}>
      <Heading level="h2">Ändra adressen</Heading>
      <EditAddress
        patientId={patient.id}
        currentAddress={patient.address}
        onSuccess={() => navigate(-1)}
      />
    </section>
  );
};

export const EditPhoneNumberWrapper = () => {
  const patient = useParentRoutesPatient();
  const navigate = useNavigate();

  return (
    <section className={styles.editFormContainer}>
      <Heading level="h2">Ändra telefonnumret</Heading>
      <EditPhoneNumber
        patientId={patient.id}
        currentPhoneNumber={patient.phoneNumber}
        onSuccess={() => navigate(-1)}
      />
    </section>
  );
};

export const EditRelativesWrapper = () => {
  const patient = useParentRoutesPatient();
  const navigate = useNavigate();

  const queryClient = useQueryClient();
  const { mutate: removeRelativeMutation, isPending: isRemovingRelative } =
    useMutation({
      mutationFn: ({
        relativeId,
        patientId,
      }: {
        relativeId: string;
        patientId: string;
      }) => removeRelative(patientId, relativeId),
      onError: (error, { patientId }) => {
        if (
          isErrorWithKnownErrorCode(error) &&
          error.response.data.code ===
            knownErrorCodeSchema.Values.RelativeMissing
        ) {
          return queryClient.invalidateQueries({
            queryKey: patientKeys.detail(patientId),
          });
        } else {
          displayErrorMessageAlert(
            `Gick inte att ta bort anhörig. ${deducedError(error)}`,
          );
        }
      },
      onSuccess: (_, { patientId }) => {
        return queryClient.invalidateQueries({
          queryKey: patientKeys.detail(patientId),
        });
      },
    });

  return (
    <div className={styles.relatives}>
      {patient.relatives.length > 0 ? (
        <section className={styles.editFormContainer}>
          <Heading level="h2">Ändra anhöriga</Heading>
          <ul className={styles.list}>
            {patient?.relatives.map((relative) => {
              return (
                <li key={relative.id}>
                  <p>
                    {relative.name} ({relative.relation}){" "}
                    {relative.isLivingTogether
                      ? `bor med ${patient.name} och`
                      : undefined}{" "}
                    nås på {relative.phoneNumber}
                  </p>
                  <PlainButton
                    onClick={() =>
                      removeRelativeMutation({
                        patientId: patient.id,
                        relativeId: relative.id,
                      })
                    }
                    disabled={isRemovingRelative}
                  >
                    Ta bort
                  </PlainButton>
                </li>
              );
            })}
          </ul>
        </section>
      ) : undefined}
      <section className={styles.editFormContainer}>
        <Heading level="h2">Lägg till anhörig</Heading>
        <AddRelative patientId={patient.id} onSuccess={() => navigate(-1)} />
      </section>
    </div>
  );
};

export const EditOtherInformationWrapper = () => {
  const patient = useParentRoutesPatient();
  const navigate = useNavigate();

  return (
    <section className={styles.editFormContainer}>
      <Heading level="h2">Ändra allmän information</Heading>
      <EditInformation
        patientId={patient.id}
        currentInformation={patient.information}
        onSuccess={() => navigate(-1)}
      />
    </section>
  );
};

export const EditSafetyAlarmWrapper = () => {
  const patient = useParentRoutesPatient();
  const navigate = useNavigate();

  return (
    <section className={styles.editFormContainer}>
      <Heading level="h2">Ändra trygghetslarm</Heading>
      <EditSafetyAlarmText
        patientId={patient.id}
        currentSafetyAlarmText={patient.safetyAlarmText}
        onSuccess={() => navigate(-1)}
      />
    </section>
  );
};
