import { ITitleSuggestion } from "@/forms/AddActivityForm/titleSuggestions";
import type { IAddActivityFormData } from "@/forms/AddActivityForm/AddActivityForm";
import { recurringDictionary } from "@/forms/AddActivityForm/Scheduling/recurrenceUtils";
import { timeOfDayDictionary } from "@models/activities";

export type IBaseTemplate = Pick<
  IAddActivityFormData,
  | "category"
  | "description"
  | "doubleStaffing"
  | "duration"
  | "recurrence"
  | "recurrencesPerDay"
  | "requiredCompetences"
  | "timeCategory"
  | "timeSensitivity"
  | "timeslots"
  | "title"
  | "hidden"
  | "weekdays"
  | "measurements"
> & {
  templateOptions: {
    showInList: boolean;
  };
  templateId: string;
  templateRevision: number;
};

type IAnyTimeOfDayTemplate = Omit<
  IBaseTemplate,
  "timeslots" | "timeSensitivity" | "recurrencesPerDay"
>;

// Must be a subset of IAddActivityFormData
export type ITemplate = IBaseTemplate | IAnyTimeOfDayTemplate;

type ITemplateSuggestions = Extract<
  ITitleSuggestion,
  | "Blodprov"
  | "Byte av pump"
  | "Byte larmklocka"
  | "Byte materiallåda"
  | "Byte PVK - perfier infart"
  | "Dosettförberedelse"
  | "Dra infarter"
  | "Egen mätning blodsocker"
  | "Egen mätning kväll"
  | "Egen mätning morgon"
  | "Egen mätning vikt"
  | "Hämtning larmklocka"
  | "Hämtning materiallåda"
  | "Hämtning tekniklåda"
  | "Kontroll infart"
  | "Koppla pump"
  | "Koppla bort pump"
  | "Leverans larmklocka"
  | "Leverans läkemedel"
  | "Leverans mat"
  | "Leverans materiallåda"
  | "Läkemedel subkutan injektion"
  | "Läkemedel intravenöst"
  | "Läkemedelsgenomgång"
  | "Nyckelkvittens"
  | "Omläggning central infart"
  | "Patientenkät"
  | "Pumpkontroll digital"
  | "Riskbedömning"
  | "Rond"
  | "Vätske- och urinmätning"
  | "Återlämning nyckel"
  | "Överlämning behandlingsmeddelande"
  | "Överlämning läkemedelslista"
>;

export type ITemplateKey =
  | ITemplateSuggestions
  | "Ta medicin 1 tillfälle"
  | "Ta medicin 2 tillfällen"
  | "Ta medicin 3 tillfällen"
  | "Ta medicin 4 tillfällen"
  | "Ta medicin 5 tillfällen"
  | "Ta medicin 6 tillfällen";

export const templateSuggestions: ITemplateSuggestions[] = [
  "Blodprov",
  "Byte av pump",
  "Byte larmklocka",
  "Byte materiallåda",
  "Byte PVK - perfier infart",
  "Dosettförberedelse",
  "Dra infarter",
  "Egen mätning blodsocker",
  "Egen mätning kväll",
  "Egen mätning morgon",
  "Egen mätning vikt",
  "Hämtning larmklocka",
  "Hämtning materiallåda",
  "Hämtning tekniklåda",
  "Kontroll infart",
  "Koppla pump",
  "Koppla bort pump",
  "Leverans larmklocka",
  "Leverans läkemedel",
  "Leverans mat",
  "Leverans materiallåda",
  "Läkemedel subkutan injektion",
  "Läkemedel intravenöst",
  "Läkemedelsgenomgång",
  "Nyckelkvittens",
  "Omläggning central infart",
  "Patientenkät",
  "Pumpkontroll digital",
  "Riskbedömning",
  "Rond",
  "Vätske- och urinmätning",
  "Återlämning nyckel",
  "Överlämning behandlingsmeddelande",
  "Överlämning läkemedelslista",
];

export const templates: Record<ITemplateKey, ITemplate> = {
  Blodprov: {
    title: "Blodprov",
    category: "HomeVisit",
    description: "",
    recurrence: recurringDictionary.never,
    timeCategory: timeOfDayDictionary.Specific.long,
    recurrencesPerDay: "1",
    timeslots: [{ time: "08:00" }],
    timeSensitivity: "2",
    requiredCompetences: ["NurseAssistant"],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: true,
    },
    templateId: "1447d006-d953-428c-92f6-666c40c1a58f",
    templateRevision: 1,
  },
  "Byte av pump": {
    title: "Byte av pump",
    category: "HomeVisit",
    description:
      'Kom ihåg att ändra tiden till 24 timmar efter att pumpen kopplats och ta sedan bort "dold för patienten"',
    recurrence: recurringDictionary.everyDay,
    timeCategory: timeOfDayDictionary.Specific.long,
    recurrencesPerDay: "1",
    timeslots: [{ time: "00:00" }],
    timeSensitivity: "1",
    requiredCompetences: ["NursePractitioner"],
    doubleStaffing: false,
    duration: 15,
    hidden: true,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: true,
    },
    templateId: "f6685fed-5dff-4d53-aa00-912b5a1928b9",
    templateRevision: 2,
  },
  "Byte larmklocka": {
    title: "Byte larmklocka",
    category: "HomeVisit",
    description: "Fyll i klockans larmnummer under Patientinformation",
    recurrence: recurringDictionary.every3rdDay,
    timeCategory: timeOfDayDictionary.Any.long,
    requiredCompetences: ["Support"],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: false,
    },
    templateId: "37b3f115-cc7f-416a-bd66-5d7582870196",
    templateRevision: 1,
  },
  "Byte materiallåda": {
    title: "Byte materiallåda",
    category: "HomeVisit",
    description: "",
    recurrence: recurringDictionary.every5thDay,
    timeCategory: timeOfDayDictionary.Any.long,
    requiredCompetences: ["Support"],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: false,
    },
    templateId: "7ed1e9e3-5305-4c7b-a620-6df2223fe060",
    templateRevision: 1,
  },
  "Byte PVK - perfier infart": {
    title: "Byte PVK - perfier infart",
    category: "HomeVisit",
    description: "",
    recurrence: recurringDictionary.every3rdDay,
    timeCategory: timeOfDayDictionary.Any.long,
    requiredCompetences: ["NurseAssistant"],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: true,
    },
    templateId: "b16dcdce-0b23-4188-9564-e711caa53815",
    templateRevision: 1,
  },
  Dosettförberedelse: {
    title: "Dosettförberedelse",
    category: "AdminTask",
    description: "",
    recurrence: recurringDictionary.never,
    timeCategory: timeOfDayDictionary.Any.long,
    requiredCompetences: ["NursePractitioner"],
    doubleStaffing: false,
    duration: 30,
    hidden: true,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: true,
    },
    templateId: "3f976242-4263-4191-b253-bf4907bf8e4a",
    templateRevision: 1,
  },
  "Dra infarter": {
    title: "Dra infarter",
    category: "HomeVisit",
    description: "",
    recurrence: recurringDictionary.never,
    timeCategory: timeOfDayDictionary.Any.long,
    requiredCompetences: ["NurseAssistant"],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: false,
    },
    templateId: "b793e53f-2df0-4c14-84eb-e4537844710b",
    templateRevision: 1,
  },
  "Egen mätning blodsocker": {
    title: "Egen mätning blodsocker",
    category: "PatientMeasurementTask",
    description: "",
    recurrence: recurringDictionary.everyDay,
    timeCategory: timeOfDayDictionary.Specific.long,
    recurrencesPerDay: "7",
    timeslots: [
      { time: "07:30" },
      { time: "10:00" },
      { time: "11:30" },
      { time: "14:00" },
      { time: "18:00" },
      { time: "20:00" },
      { time: "23:00" },
    ],
    timeSensitivity: "0.5",
    requiredCompetences: [],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: ["bloodGlucose"],
    weekdays: [],
    templateOptions: {
      showInList: true,
    },
    templateId: "c41bf40e-7467-426e-b331-5820d02259f5",
    templateRevision: 1,
  },
  "Egen mätning kväll": {
    title: "Egen mätning kväll",
    category: "PatientMeasurementTask",
    description: "",
    recurrence: recurringDictionary.everyDay,
    timeCategory: timeOfDayDictionary.Specific.long,
    recurrencesPerDay: "1",
    timeslots: [{ time: "17:30" }],
    timeSensitivity: "2",
    requiredCompetences: [],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: ["bloodPressure", "pulse", "saturation", "temperature"],
    weekdays: [],
    templateOptions: {
      showInList: false,
    },
    templateId: "bc00f686-6145-446d-9997-0ac97b50a61c",
    templateRevision: 1,
  },
  "Egen mätning morgon": {
    title: "Egen mätning morgon",
    category: "PatientMeasurementTask",
    description: "",
    recurrence: recurringDictionary.everyDay,
    timeCategory: timeOfDayDictionary.Specific.long,
    recurrencesPerDay: "1",
    timeslots: [{ time: "06:30" }],
    timeSensitivity: "2",
    requiredCompetences: [],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: ["bloodPressure", "pulse", "saturation", "temperature"],
    weekdays: [],
    templateOptions: {
      showInList: false,
    },
    templateId: "e93f17d3-6f2b-4d61-9ff0-6aaa7fd61c42",
    templateRevision: 1,
  },
  "Egen mätning vikt": {
    title: "Egen mätning vikt",
    category: "PatientMeasurementTask",
    description: "",
    recurrence: recurringDictionary.everyDay,
    timeCategory: timeOfDayDictionary.Specific.long,
    recurrencesPerDay: "1",
    timeslots: [{ time: "08:00" }],
    timeSensitivity: "0.5",
    requiredCompetences: [],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: ["weight"],
    weekdays: [],
    templateOptions: {
      showInList: true,
    },
    templateId: "b8399642-d386-4533-9cf7-8ea282d704df",
    templateRevision: 1,
  },
  "Hämtning larmklocka": {
    title: "Hämtning larmklocka",
    category: "HomeVisit",
    description: "",
    recurrence: recurringDictionary.never,
    timeCategory: timeOfDayDictionary.Any.long,
    requiredCompetences: ["Support"],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: false,
    },
    templateId: "5aff2acd-4c59-4be3-96a6-6316463afcf8",
    templateRevision: 1,
  },
  "Hämtning materiallåda": {
    title: "Hämtning materiallåda",
    category: "HomeVisit",
    description: "",
    recurrence: recurringDictionary.never,
    timeCategory: timeOfDayDictionary.Any.long,
    requiredCompetences: ["Support"],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: false,
    },
    templateId: "2a6f491f-6b38-4b44-849d-c26600a5050a",
    templateRevision: 1,
  },
  "Hämtning tekniklåda": {
    title: "Hämtning tekniklåda",
    category: "HomeVisit",
    description: "",
    recurrence: recurringDictionary.never,
    timeCategory: timeOfDayDictionary.Any.long,
    requiredCompetences: ["Support"],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: false,
    },
    templateId: "1984b33a-605b-42fc-bb91-b65b90819736",
    templateRevision: 1,
  },
  "Kontroll infart": {
    title: "Kontroll infart",
    category: "HomeVisit",
    description: "",
    recurrence: recurringDictionary.never,
    timeCategory: timeOfDayDictionary.Any.long,
    requiredCompetences: ["NurseAssistant"],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: false,
    },
    templateId: "601c001c-8d87-435d-8200-9d3ebba2930b",
    templateRevision: 1,
  },
  "Koppla pump": {
    title: "Koppla pump",
    category: "HomeVisit",
    description: "",
    recurrence: recurringDictionary.never,
    timeCategory: timeOfDayDictionary.Specific.long,
    recurrencesPerDay: "1",
    timeslots: [{ time: "--:--" }], // The user must choose a time
    timeSensitivity: "2",
    requiredCompetences: ["NursePractitioner"],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: false,
    },
    templateId: "7cede509-6b71-4bd9-8ea9-cb8744278ea5",
    templateRevision: 1,
  },
  "Koppla bort pump": {
    title: "Koppla bort pump",
    category: "HomeVisit",
    description: "",
    recurrence: recurringDictionary.never,
    timeCategory: timeOfDayDictionary.Specific.long,
    recurrencesPerDay: "1",
    timeslots: [{ time: "--:--" }],
    timeSensitivity: "2",
    requiredCompetences: ["NurseAssistant"],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: true,
    },
    templateId: "c3d50d41-ecfd-4b8c-9bfd-28eb82ed18e7",
    templateRevision: 1,
  },
  "Leverans larmklocka": {
    title: "Leverans larmklocka",
    category: "HomeVisit",
    description: "",
    recurrence: recurringDictionary.never,
    timeCategory: timeOfDayDictionary.Any.long,
    requiredCompetences: ["Support"],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: false,
    },
    templateId: "0784cc93-18cb-4f3b-a2cf-c3a6b6d0baca",
    templateRevision: 1,
  },
  "Leverans läkemedel": {
    title: "Leverans läkemedel",
    category: "HomeVisit",
    description: "",
    recurrence: recurringDictionary.custom,
    timeCategory: timeOfDayDictionary.Any.long,
    requiredCompetences: ["Support"],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: true,
    },
    templateId: "aead9f16-0e2f-4235-bf29-e2329d8893d8",
    templateRevision: 1,
  },
  "Leverans mat": {
    title: "Leverans mat",
    category: "HomeVisit",
    description: "Fråga patient om önskemål och eventuella allergier",
    recurrence: recurringDictionary.every2ndDay,
    timeCategory: timeOfDayDictionary.Any.long,
    requiredCompetences: ["Support"],
    doubleStaffing: false,
    duration: 30,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: true,
    },
    templateId: "4999baee-7173-4158-869d-4c7efbd7aa0b",
    templateRevision: 1,
  },
  "Leverans materiallåda": {
    title: "Leverans materiallåda",
    category: "HomeVisit",
    description: "",
    recurrence: recurringDictionary.never,
    timeCategory: timeOfDayDictionary.Any.long,
    requiredCompetences: ["Support"],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: false,
    },
    templateId: "58388aca-3f3b-41ea-8af3-dee1addde183",
    templateRevision: 1,
  },
  "Läkemedel intravenöst": {
    title: "Läkemedel intravenöst",
    category: "HomeVisit",
    description: "",
    recurrence: recurringDictionary.everyDay,
    timeCategory: timeOfDayDictionary.Specific.long,
    recurrencesPerDay: "3",
    timeslots: [{ time: "00:00" }, { time: "08:00" }, { time: "16:00" }],
    timeSensitivity: "1",
    requiredCompetences: ["NursePractitioner"],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: true,
    },
    templateId: "7fa267d2-fba3-490e-a529-88e0c523d55c",
    templateRevision: 1,
  },
  "Läkemedel subkutan injektion": {
    title: "Läkemedel subkutan injektion",
    category: "HomeVisit",
    description: "",
    recurrence: recurringDictionary.everyDay,
    timeCategory: timeOfDayDictionary.Specific.long,
    recurrencesPerDay: "1",
    timeslots: [{ time: "--:--" }], // The user must choose a time
    timeSensitivity: "2",
    requiredCompetences: ["NurseAssistant"],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: true,
    },
    templateId: "4fb46db4-c9c7-4217-a0a3-8c77560986ba",
    templateRevision: 1,
  },
  Läkemedelsgenomgång: {
    title: "Läkemedelsgenomgång",
    category: "VideoCall",
    description: "",
    recurrence: recurringDictionary.never,
    timeCategory: timeOfDayDictionary.Specific.long,
    recurrencesPerDay: "1",
    timeslots: [{ time: "09:00" }],
    timeSensitivity: "2",
    requiredCompetences: ["MedicalDoctor"],
    doubleStaffing: false,
    duration: 30,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: false,
    },
    templateId: "27254a85-296e-4320-9bb4-00fe8e1b06f7",
    templateRevision: 1,
  },
  Nyckelkvittens: {
    title: "Nyckelkvittens",
    category: "HomeVisit",
    description: "",
    recurrence: recurringDictionary.never,
    timeCategory: timeOfDayDictionary.Any.long,
    requiredCompetences: ["Support"],
    doubleStaffing: false,
    duration: 15,
    hidden: true,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: false,
    },
    templateId: "cec340d5-f295-477a-a168-0de94918b3d3",
    templateRevision: 1,
  },
  "Omläggning central infart": {
    title: "Omläggning central infart",
    category: "HomeVisit",
    description: "",
    recurrence: recurringDictionary.custom,
    timeCategory: timeOfDayDictionary.Any.long,
    requiredCompetences: ["NursePractitioner"],
    doubleStaffing: false,
    duration: 30,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: true,
    },
    templateId: "5db951ec-08a1-409d-8e4f-d53bc43adede",
    templateRevision: 1,
  },
  Patientenkät: {
    title: "Patientenkät",
    category: "HomeVisit",
    description: "",
    recurrence: recurringDictionary.never,
    timeCategory: timeOfDayDictionary.Any.long,
    requiredCompetences: ["Support"],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: false,
    },
    templateId: "77936b25-936f-4963-95da-e316085facb6",
    templateRevision: 1,
  },
  "Pumpkontroll digital": {
    title: "Pumpkontroll digital",
    category: "VideoCall",
    description: "",
    recurrence: recurringDictionary.everyDay,
    timeCategory: timeOfDayDictionary.Specific.long,
    recurrencesPerDay: "1",
    timeslots: [{ time: "18:00" }],
    timeSensitivity: "1",
    requiredCompetences: ["NursePractitioner"],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: true,
    },
    templateId: "3bf79eeb-8e62-4ee3-b751-b5ee20da2eec",
    templateRevision: 1,
  },
  Riskbedömning: {
    title: "Riskbedömning",
    category: "HomeVisit",
    description: "För in i Cosmic",
    recurrence: recurringDictionary.never,
    timeCategory: timeOfDayDictionary.Any.long,
    requiredCompetences: ["NurseAssistant"],
    doubleStaffing: false,
    duration: 15,
    hidden: true,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: false,
    },
    templateId: "7eb2dcd9-f6cc-4926-95cd-28a46211bf14",
    templateRevision: 1,
  },
  Rond: {
    title: "Rond",
    category: "VideoCall",
    description: "",
    recurrence: recurringDictionary.everyDay,
    timeCategory: timeOfDayDictionary.Specific.long,
    recurrencesPerDay: "1",
    timeslots: [{ time: "09:00" }],
    timeSensitivity: "2",
    requiredCompetences: ["NursePractitioner", "MedicalDoctor"],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: false,
    },
    templateId: "542a2317-ca1c-4e29-81eb-cb6266aa4632",
    templateRevision: 1,
  },
  "Ta medicin 1 tillfälle": {
    title: "Ta medicin",
    category: "PatientTask",
    description: "",
    recurrence: recurringDictionary.everyDay,
    timeCategory: timeOfDayDictionary.Specific.long,
    recurrencesPerDay: "1",
    timeslots: [{ time: "08:00" }],
    timeSensitivity: "2",
    requiredCompetences: [],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: true,
    },
    templateId: "b20b0c3a-0376-43c7-a2e6-222fad3fb528",
    templateRevision: 1,
  },
  "Ta medicin 2 tillfällen": {
    title: "Ta medicin",
    category: "PatientTask",
    description: "",
    recurrence: recurringDictionary.everyDay,
    timeCategory: timeOfDayDictionary.Specific.long,
    recurrencesPerDay: "2",
    timeslots: [{ time: "08:00" }, { time: "20:00" }],
    timeSensitivity: "2",
    requiredCompetences: [],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: true,
    },
    templateId: "b6ed1e73-0f67-411e-bfbe-f416bbbda18b",
    templateRevision: 1,
  },
  "Ta medicin 3 tillfällen": {
    title: "Ta medicin",
    category: "PatientTask",
    description: "",
    recurrence: recurringDictionary.everyDay,
    timeCategory: timeOfDayDictionary.Specific.long,
    recurrencesPerDay: "3",
    timeslots: [{ time: "08:00" }, { time: "14:00" }, { time: "20:00" }],
    timeSensitivity: "2",
    requiredCompetences: [],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: true,
    },
    templateId: "b2b8565b-655f-4a08-9b24-405902529162",
    templateRevision: 1,
  },
  "Ta medicin 4 tillfällen": {
    title: "Ta medicin",
    category: "PatientTask",
    description: "",
    recurrence: recurringDictionary.everyDay,
    timeCategory: timeOfDayDictionary.Specific.long,
    recurrencesPerDay: "4",
    timeslots: [
      { time: "08:00" },
      { time: "14:00" },
      { time: "20:00" },
      { time: "22:00" },
    ],
    timeSensitivity: "2",
    requiredCompetences: [],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: true,
    },
    templateId: "6ce1070f-cdd9-4d28-9506-c26f7dba6ca7",
    templateRevision: 1,
  },
  "Ta medicin 5 tillfällen": {
    title: "Ta medicin",
    category: "PatientTask",
    description: "",
    recurrence: recurringDictionary.everyDay,
    timeCategory: timeOfDayDictionary.Specific.long,
    recurrencesPerDay: "5",
    timeslots: [
      { time: "08:00" },
      { time: "13:00" },
      { time: "18:00" },
      { time: "20:00" },
      { time: "22:00" },
    ],
    timeSensitivity: "2",
    requiredCompetences: [],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: true,
    },
    templateId: "3eec829e-29ff-4156-9da4-227ce80bf804",
    templateRevision: 1,
  },
  "Ta medicin 6 tillfällen": {
    title: "Ta medicin",
    category: "PatientTask",
    description: "",
    recurrence: recurringDictionary.everyDay,
    timeCategory: timeOfDayDictionary.Specific.long,
    recurrencesPerDay: "6",
    timeslots: [
      { time: "06:00" },
      { time: "10:00" },
      { time: "14:00" },
      { time: "18:00" },
      { time: "20:00" },
      { time: "22:00" },
    ],
    timeSensitivity: "2",
    requiredCompetences: [],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: true,
    },
    templateId: "12647e10-7e15-4c90-82da-ce86d96da994",
    templateRevision: 1,
  },
  "Vätske- och urinmätning": {
    title: "Vätske- och urinmätning",
    category: "PatientTask",
    description: "",
    recurrence: recurringDictionary.everyDay,
    timeCategory: timeOfDayDictionary.Specific.long,
    recurrencesPerDay: "1",
    timeslots: [{ time: "06:30" }],
    timeSensitivity: "2",
    requiredCompetences: [],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: true,
    },
    templateId: "f6e41839-b658-4f8c-b5f6-275122dae807",
    templateRevision: 1,
  },
  "Återlämning nyckel": {
    title: "Återlämning nyckel",
    category: "HomeVisit",
    description: "",
    recurrence: recurringDictionary.never,
    timeCategory: timeOfDayDictionary.Any.long,
    requiredCompetences: ["Support"],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: false,
    },
    templateId: "67a1446f-e14c-42b9-b379-6a2d7d5f9985",
    templateRevision: 1,
  },
  "Överlämning behandlingsmeddelande": {
    title: "Överlämning behandlingsmeddelande",
    category: "HomeVisit",
    description: "",
    recurrence: recurringDictionary.never,
    timeCategory: timeOfDayDictionary.Any.long,
    requiredCompetences: ["Support"],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: false,
    },
    templateId: "64b29fd4-d2fe-4fa1-a4d7-a8ca36ad913a",
    templateRevision: 1,
  },
  "Överlämning läkemedelslista": {
    title: "Överlämning läkemedelslista",
    category: "HomeVisit",
    description: "",
    recurrence: recurringDictionary.never,
    timeCategory: timeOfDayDictionary.Any.long,
    requiredCompetences: ["Support"],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: false,
    },
    templateId: "a168d742-71c3-4ba0-90b6-7287425a63af",
    templateRevision: 1,
  },
};
