import { Root, Content, Arrow, Trigger } from "@radix-ui/react-popover";
import styles from "./Popover.module.scss";
import { IBaseButton, PopoverTriggerButton } from "@components/Button/Button";
import { XOR } from "ts-xor";

type IPopover = {
  trigger: ICustomTrigger;
  children: string | JSX.Element;
  controlled?: { open: boolean; onOpenChange: (open: boolean) => void };
};

type ICustomTrigger = Pick<IBaseButton, "weight" | "size" | "disabled"> &
  XOR<
    { icon: JSX.Element; text: string },
    XOR<
      { icon: JSX.Element; ariaLabel: string },
      { custom: JSX.Element; ariaLabel?: string }
    >
  >;

const CustomTrigger = ({
  text,
  icon,
  custom,
  ariaLabel,
  weight = "light",
  size,
  disabled = false,
}: ICustomTrigger) => {
  if (custom !== undefined) {
    return (
      <Trigger className={styles.customTrigger} aria-label={ariaLabel}>
        {custom}
      </Trigger>
    );
  }

  if (text !== undefined) {
    return (
      <PopoverTriggerButton
        contentType="text-and-icon"
        weight={weight}
        size={size}
        disabled={disabled}
      >
        {icon}
        {text}
      </PopoverTriggerButton>
    );
  }

  /* fallback <=> "icon" */
  return (
    <div className={styles.fallbackTrigger}>
      <PopoverTriggerButton
        contentType="text-and-icon"
        weight={weight}
        size={size}
        aria-label={ariaLabel}
      >
        {icon}
      </PopoverTriggerButton>
    </div>
  );
};

export const Popover = ({ trigger, children, controlled }: IPopover) => {
  return (
    <Root open={controlled?.open} onOpenChange={controlled?.onOpenChange}>
      <CustomTrigger {...trigger} />
      <Content className={styles.content}>
        {children}
        <Arrow className={styles.arrow} />
      </Content>
    </Root>
  );
};
