import Form from "@/components/Form/Form";
import { useForm } from "react-hook-form";
import { FilledButton } from "@components/Button/Button";
import styles from "./ChangeGroup.module.scss";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deducedError } from "@/Utils/ErrorUtils";
import { Loading } from "@components/Loading/Loading";
import {
  activityKeys,
  changeGroup,
  eligibleGroupsKeys,
  IActivityRequirement,
  useEligibleGroups,
} from "@/api/Activities";
import ErrorMessage from "@components/ErrorMessage/ErrorMessage";
import RadioButton from "@/components/RadioButton/RadioButton";
import {
  IGroupOfHomeVisitActivityOccurrenceWithPatientId,
  IHomeVisitActivityOccurrence,
  timeOfDayDictionary,
} from "@models/activities";
import RadioButtons from "@/components/RadioButton/RadioButtons";
import { RequirementChip } from "@/components/Chips/RequirementChip";
import { Text } from "@components/Text/Text";
import { getUnfulfilledRequirements } from "@/pages/commandcenter/Activities/WorkBlocks/helpers";
import { AssignedShiftChip } from "@/components/Chips/AssignedShiftChip";
import { IScheduledShift } from "@models/shifts";
import { formattedTimeSpan } from "@/components/Time/timeUtils";

const CompetencesAndAssignees = ({
  unfulfilledRequirements,
  assignees,
}: {
  unfulfilledRequirements: IActivityRequirement[];
  assignees: IScheduledShift[];
}) => {
  return (
    <div className={styles.competencesAndAssignees}>
      {unfulfilledRequirements.length > 0 ? (
        <ul className={styles.competences}>
          {unfulfilledRequirements.map((unfulfilledRequirement) => (
            <li key={unfulfilledRequirement}>
              <RequirementChip
                requirement={unfulfilledRequirement}
                state="unfulfilled"
                size="small"
              />
            </li>
          ))}
        </ul>
      ) : undefined}
      {assignees.length > 0 ? (
        <ul className={styles.assignees}>
          {assignees.map((shift) => (
            <li key={shift.id}>
              <AssignedShiftChip
                medicalCompetence={shift.competence}
                shift={shift}
                size="small"
              />
            </li>
          ))}
        </ul>
      ) : undefined}
    </div>
  );
};

export const NEW_GROUP_ID = "new-group";

const CustomRadioButton = (
  group: IGroupOfHomeVisitActivityOccurrenceWithPatientId,
) => {
  return (
    <>
      <div className={styles.groupDetails}>
        <Text element="span">
          {group.timeOfDay === "Any"
            ? timeOfDayDictionary.Any.short
            : formattedTimeSpan(group.start, group.end)}
        </Text>
        <CompetencesAndAssignees
          assignees={group.assignees}
          unfulfilledRequirements={getUnfulfilledRequirements(group)}
        />
      </div>
      <Text element="span" size="small">
        {group.occurrences.map(({ title }) => title).join(", ")}
      </Text>
    </>
  );
};

interface IFormData {
  groupId: string;
}

export const ChangeGroup = ({
  activityOccurrence,
  onSubmitSuccess,
}: {
  activityOccurrence: IHomeVisitActivityOccurrence;
  onSubmitSuccess?: () => void;
}) => {
  const {
    activityId,
    id: occurrenceId,
    title,
    start,
    end,
    timeOfDay,
    assignees,
  } = activityOccurrence;

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<IFormData>();

  const {
    data: eligibleGroups,
    isPending: isPendingGroups,
    isError: isErrorGroups,
  } = useEligibleGroups({ activityId, occurrenceId });

  const queryClient = useQueryClient();
  const { mutate, isPending } = useMutation({
    mutationFn: (groupId: string) =>
      changeGroup(activityId, occurrenceId, groupId),
    onError: (error) => {
      setError("groupId", {
        message: deducedError(error),
      });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: activityKeys.all });
      queryClient.invalidateQueries({ queryKey: eligibleGroupsKeys.all });
      onSubmitSuccess?.();
    },
  });

  const validateAndSubmit = handleSubmit((formData) => {
    const { groupId } = formData;
    mutate(groupId);
  });

  return (
    <>
      <div className={styles.changeGroup}>
        <div className={styles.activityOccurrenceToMove}>
          <Text element="span" weight="medium" size="large">
            {title}
            {", "}
            {timeOfDay === "Any"
              ? timeOfDayDictionary.Any.short
              : formattedTimeSpan(start, end)}
          </Text>
          <CompetencesAndAssignees
            assignees={assignees}
            unfulfilledRequirements={getUnfulfilledRequirements({
              assignees,
              occurrences: [activityOccurrence],
            })}
          />
        </div>
        <Form onSubmit={validateAndSubmit}>
          <RadioButtons
            errorMessage={errors.groupId?.message}
            orientation="vertical"
          >
            <RadioButton
              label={{
                text: "Nytt hembesök",
              }}
              visualStyle="framed"
              value={NEW_GROUP_ID}
              {...register(`groupId`, {
                required: {
                  value: true,
                  message: "Ett hembesök måste väljas",
                },
              })}
            />
            <>
              {isErrorGroups ? (
                <ErrorMessage message="Kunde inte hämta besök" />
              ) : isPendingGroups ? (
                <Loading message="Laddar passande besök" />
              ) : (
                eligibleGroups.map((group) => (
                  <RadioButton
                    key={group.id}
                    label={{
                      text: `${
                        group.timeOfDay === "Any"
                          ? timeOfDayDictionary.Any.short
                          : formattedTimeSpan(group.start, group.end)
                      } ${group.occurrences.map(({ title }) => title).join(", ")}`,
                      component: CustomRadioButton(group),
                    }}
                    visualStyle="framed"
                    value={group.id}
                    {...register(`groupId`)}
                  />
                ))
              )}
            </>
          </RadioButtons>
          <Form.SubmitButtonWrapper>
            <FilledButton type="submit" disabled={isPending}>
              Flytta
            </FilledButton>
          </Form.SubmitButtonWrapper>
        </Form>
      </div>
    </>
  );
};
