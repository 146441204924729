import { IActivityRequirement } from "@/api/Activities";
import Chip, { IChip } from "./Chip";
import { CompetenceChip } from "./CompetenceChip";
import { IActivityRequirementStatus } from "@models/activities";
import { getBackground, getBorder } from "./chipUtils";

export const RequirementChip = ({
  requirement,
  state,
  size,
}: {
  requirement: IActivityRequirement;
  state: IActivityRequirementStatus;
  content?: string;
  onRemove?: () => void;
} & Pick<IChip, "size">) => {
  if (requirement === "DoubleStaffing") {
    return (
      <Chip
        color="gray"
        background={getBackground(state)}
        border={getBorder(state)}
        size={size}
      >
        2x
      </Chip>
    );
  }

  return <CompetenceChip competence={requirement} state={state} size={size} />;
};
