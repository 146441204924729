import { useFormContext } from "react-hook-form";
import { IAddActivityFormData } from "@/forms/AddActivityForm/AddActivityForm";
import Checkboxes from "@/components/Checkbox/Checkboxes";
import Checkbox from "@/components/Checkbox/Checkbox";
import {
  measurementsDictionary,
  orderedMeasurementsList,
} from "@models/activities";

export const MeasurementsPicker = () => {
  const {
    formState: { errors },
    register,
  } = useFormContext<IAddActivityFormData>();
  return (
    <Checkboxes
      legend={"Värden"}
      errorMessage={errors.measurements?.message}
      orientation="horizontal"
    >
      {orderedMeasurementsList.map((measurement) => {
        return (
          <Checkbox
            key={measurement}
            label={{ text: measurementsDictionary[measurement].sv }}
            {...register(`measurements`, {
              required: {
                value: true,
                message: "Minst ett mätvärde behöver anges.",
              },
            })}
            value={measurement}
            visualStyle="framed"
          />
        );
      })}
    </Checkboxes>
  );
};
