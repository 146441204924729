import {
  IActivityCategory,
  IActivityOccurrenceOrGroupWithPatient,
  IActivityOccurrenceStatus,
  isGroup,
  ITimeOfDay,
} from "@models/activities";
import { useMemo } from "react";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import styles from "./ActivityTable.module.scss";
import { StatusTagWithDropdown } from "@/components/StatusTagWithDropdown/StatusTagWithDropdown";
import { useParams } from "react-router-dom";
import { CategoryIcon } from "@/components/CategoryIcon/CategoryIcon";
import { ActivityTitle } from "@/components/ActivityTitle/ActivityTitle";
import clsx from "clsx";
import { ActorsCell } from "@/pages/commandcenter/Activities/ActivitiesTable/ActorsCell";
import { StatusTag } from "@/components/StatusTag/StatusTag";
import { Link } from "@components/Link/Link";
import { TimeSpan } from "@/components/Time/TimeSpan";

interface IActivityTable {
  activityOccurrencesAndGroups: IActivityOccurrenceOrGroupWithPatient[];
}

type IActivityTableItem = {
  activityOccurrenceOrGroup: IActivityOccurrenceOrGroupWithPatient;
  category: IActivityCategory;
  title: IActivityOccurrenceOrGroupWithPatient;
  timespan: [Date, Date, ITimeOfDay];
  status: IActivityOccurrenceStatus[];
  actors: IActivityOccurrenceOrGroupWithPatient;
  occurrenceIsActive: boolean;
  activityIsActive: boolean;
  groupIsActive: boolean;
  activeOccurrencesInGroup: { activityId: string; occurrenceId: string }[];
  activeActivitiesInGroup: { activityId: string }[];
};

const columnHelper = createColumnHelper<IActivityTableItem>();

const columns = [
  columnHelper.accessor("category", {
    header: "Typ av besök",
    cell: ({ getValue }) => {
      return <CategoryIcon category={getValue()} size="small" />;
    },
  }),
  columnHelper.accessor("timespan", {
    header: "Tid",
    cell: ({ getValue }) => {
      const [start, end, type] = getValue();
      return <TimeSpan start={start} end={end} timeOfDay={type} />;
    },
  }),
  columnHelper.accessor("status", {
    header: "Status",
    cell: ({ getValue, row: { original } }) => {
      const { activityOccurrenceOrGroup } = original;
      if (!isGroup(activityOccurrenceOrGroup)) {
        const { category, activityId, id, status } = activityOccurrenceOrGroup;
        return (
          <StatusTagWithDropdown
            status={status}
            category={category}
            activityId={activityId}
            occurrenceId={id}
          />
        );
      }

      const statuses = getValue();
      const { occurrences } = activityOccurrenceOrGroup;
      return (
        <ul>
          {statuses.map((status, index) => {
            // Accessing occurrences here is temporary. When a group has its own status, it should be rendered here instead.
            // @ts-expect-error - Shouldn't direct access without checking, but here we know it's safe
            const { id, activityId } = occurrences[index];

            return (
              <li
                key={`${activityId}${id}`}
                className={styles.multipleStatusesListItem}
              >
                <StatusTag status={status} size="tiny" />
              </li>
            );
          })}
        </ul>
      );
    },
  }),
  columnHelper.accessor("title", {
    header: "Aktivitet",
    cell: ({ getValue, row }) => {
      const activityOccurrenceOrGroup = getValue();
      if (!isGroup(activityOccurrenceOrGroup)) {
        return (
          <ActivityTitle
            activityOccurrence={activityOccurrenceOrGroup}
            linkTo={`${activityOccurrenceOrGroup.activityId}/occurrences/${activityOccurrenceOrGroup.id}`}
            weight="medium"
            size="small"
          />
        );
      }

      return (
        <>
          <Link to={`occurrence-groups/${activityOccurrenceOrGroup.id}`}>
            Hembesök
          </Link>
          <ul>
            {activityOccurrenceOrGroup.occurrences.map((activityOccurrence) => {
              const { id, activityId } = activityOccurrence;

              return (
                <li
                  key={`${activityId}-${id}`}
                  className={clsx(
                    styles.activityTitleInGroup,
                    row.original.activeOccurrencesInGroup.find(
                      (item) =>
                        activityId === item.activityId &&
                        id === item.occurrenceId,
                    ) && styles.occurrenceIsActive,
                    row.original.activeActivitiesInGroup.find(
                      (item) => activityId === item.activityId,
                    ) && styles.activityIsActive,
                  )}
                >
                  <ActivityTitle
                    activityOccurrence={activityOccurrence}
                    linkTo={`${activityOccurrence.activityId}/occurrences/${activityOccurrence.id}`}
                    weight="regular"
                    size="small"
                  />
                </li>
              );
            })}
          </ul>
        </>
      );
    },
  }),
  columnHelper.accessor("actors", {
    header: "Utförare",
    cell: ({ getValue }) => {
      const activityOccurrenceOrGroup = getValue();
      return (
        <ActorsCell activityOccurrenceOrGroup={activityOccurrenceOrGroup} />
      );
    },
  }),
];

const ActivityTable = ({ activityOccurrencesAndGroups }: IActivityTable) => {
  const params = useParams();

  const data = useMemo(
    () =>
      activityOccurrencesAndGroups.map((activityOccurrenceOrGroup) => {
        if (isGroup(activityOccurrenceOrGroup)) {
          const group = activityOccurrenceOrGroup;
          return {
            activityOccurrenceOrGroup: group,
            category: "HomeVisit" as const,
            timespan: [
              group.start,
              group.end,
              group.occurrences.every(({ timeOfDay }) => timeOfDay === "Any")
                ? "Any"
                : "Specific",
            ] as [Date, Date, ITimeOfDay],
            status: group.occurrences.map(({ status }) => status),
            title: group,
            actors: group,
            groupIsActive: params.groupId === group.id,
            occurrenceIsActive: false,
            activityIsActive: false,
            activeOccurrencesInGroup: group.occurrences
              .filter(
                (occurrence) =>
                  params.activityId === occurrence.activityId &&
                  params.occurrenceId === occurrence.id,
              )
              .map((occurrence) => ({
                activityId: occurrence.activityId,
                occurrenceId: occurrence.id,
              })),
            activeActivitiesInGroup: group.occurrences
              .filter(
                (occurrence) => params.activityId === occurrence.activityId,
              )
              .map((occurrence) => ({
                activityId: occurrence.activityId,
              })),
          };
        } else {
          const activityOccurrence = activityOccurrenceOrGroup;
          return {
            activityOccurrenceOrGroup: activityOccurrence,
            category: activityOccurrence.category,
            timespan: [
              activityOccurrence.start,
              activityOccurrence.end,
              activityOccurrence.timeOfDay,
            ] as [Date, Date, ITimeOfDay],
            status: [activityOccurrence.status],
            title: activityOccurrence,
            patient: activityOccurrence.patient,
            actors: activityOccurrence,
            occurrenceIsActive:
              params.occurrenceId === activityOccurrenceOrGroup.id &&
              params.activityId === activityOccurrenceOrGroup.activityId,
            activityIsActive:
              params.activityId === activityOccurrenceOrGroup.activityId,
            groupIsActive: false,
            activeOccurrencesInGroup: [],
            activeActivitiesInGroup: [],
          };
        }
      }),
    [
      activityOccurrencesAndGroups,
      params.activityId,
      params.occurrenceId,
      params.groupId,
    ],
  );

  const table = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <table className={styles.table}>
      <thead className={styles.thead}>
        {table.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <th key={header.id}>
                {header.isPlaceholder
                  ? null
                  : flexRender(
                      header.column.columnDef.header,
                      header.getContext(),
                    )}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody className={styles.tbody}>
        {table.getRowModel().rows.map((row) => (
          <tr
            key={row.id}
            className={clsx(
              row.original.occurrenceIsActive && styles.occurrenceIsActive,
              row.original.activityIsActive && styles.activityIsActive,
              row.original.groupIsActive && styles.groupIsActive,
            )}
          >
            {row.getVisibleCells().map((cell) => (
              <td key={cell.id}>
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default ActivityTable;
