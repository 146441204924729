import {
  ClientEvent,
  createClient,
  EventType,
  IFilterDefinition,
  MatrixClient,
  MatrixEvent,
  MsgType,
  RoomMember,
  RoomMemberEvent,
} from "matrix-js-sdk";
import { useEffect, useRef, useState } from "react";
import { ReceiptType } from "matrix-js-sdk/lib/@types/read_receipts";
import * as Sentry from "@sentry/react";

export const ROOM_ALIAS_PREFIX_EMPLOYEE = "employee-";

function parseJwt(token: string): { sub: string } {
  const base64Url = token.split(".")[1];
  if (base64Url === undefined) {
    throw new Error("Invalid JWT token");
  }
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join(""),
  );
  return JSON.parse(jsonPayload);
}

const E2E_ENABLED = false;

const careFilter: IFilterDefinition = {
  room: {
    account_data: {
      not_senders: ["@patient-api-medoma-matrix-admin-user:test.medoma.com"],
    },
    ephemeral: {
      not_senders: ["@patient-api-medoma-matrix-admin-user:test.medoma.com"],
    },
    state: {
      not_types: [
        // only care about member event
        "m.room.join_rules",
        "m.room.history_visibility",
        "m.room.power_levels",
        "m.room.create",
        "m.room.canonical_alias",
        "m.room.guest_access",
      ],
      not_senders: ["@patient-api-medoma-matrix-admin-user:test.medoma.com"],
    },
    timeline: {
      types: ["m.room.message"], // probably just what we want to display? room.member etc. should probably only be needed in `state`?
      not_senders: ["@patient-api-medoma-matrix-admin-user:test.medoma.com"],
    },
  },
};

const goFilter: IFilterDefinition = {
  room: {
    account_data: {
      not_senders: ["@patient-api-medoma-matrix-admin-user:test.medoma.com"],
    },
    ephemeral: {
      not_senders: ["@patient-api-medoma-matrix-admin-user:test.medoma.com"],
    },
    state: {
      not_types: [
        // only care about member event
        "m.room.join_rules",
        "m.room.history_visibility",
        "m.room.power_levels",
        "m.room.create",
        "m.room.canonical_alias",
        "m.room.guest_access",
      ],
      not_senders: ["@patient-api-medoma-matrix-admin-user:test.medoma.com"],
    },
    timeline: {
      types: ["m.room.message"], // probably just what we want to display? room.member etc. should probably only be needed in `state`?
      not_senders: ["@patient-api-medoma-matrix-admin-user:test.medoma.com"],
    },
  },
};

const centerFilter: IFilterDefinition = {
  room: {
    account_data: {
      lazy_load_members: true,
      not_senders: ["@patient-api-medoma-matrix-admin-user:test.medoma.com"],
    },
    ephemeral: {
      lazy_load_members: true,
      not_senders: ["@patient-api-medoma-matrix-admin-user:test.medoma.com"],
    },
    include_leave: false, // already false by default. Only applies to voluntary leaves, not kicks.
    state: {
      // types: [],
      // rooms: [],
      not_types: [
        // only care about member event
        "m.room.join_rules",
        "m.room.history_visibility",
        "m.room.power_levels",
        "m.room.create",
        "m.room.canonical_alias",
        "m.room.guest_access",
      ],
      lazy_load_members: true,
      not_senders: ["@patient-api-medoma-matrix-admin-user:test.medoma.com"],
    },
    timeline: {
      lazy_load_members: true,
      types: ["m.room.message"], // probably just what we want to display? room.member etc. should probably only be needed in `state`?
      not_senders: ["@patient-api-medoma-matrix-admin-user:test.medoma.com"],
    },
  },
};

export const newMatrixClient = async function (
  application: "Center" | "Go" | "Care",
  { token, baseUrl }: { token: string; baseUrl: string },
) {
  const registrationClient = createClient({ baseUrl });

  const { sub } = parseJwt(token);
  const device_id = localStorage.getItem(`matrix_device_id_${sub}`);

  // https://spec.matrix.org/historical/client_server/r0.6.1#post-matrix-client-r0-login
  const userRegisterResult = await registrationClient.login(
    // https://matrix-org.github.io/synapse/latest/jwt.html
    "org.matrix.login.jwt",
    {
      device_id,
      initial_device_display_name: `Medoma ${application}`,
      token,
    },
  );

  localStorage.setItem(`matrix_device_id_${sub}`, userRegisterResult.device_id);

  const matrixClient = createClient({
    baseUrl,
    userId: userRegisterResult.user_id,
    accessToken: userRegisterResult.access_token,
    deviceId: userRegisterResult.device_id,

    // A store to be used for end-to-end crypto session data. If not specified, end-to-end crypto will be disabled. The createClient helper will create a default store if needed. Calls the factory supplied to setCryptoStoreFactory if unspecified; or if no factory has been specified, uses a default implementation (indexeddb in the browser, in-memory otherwise).
    // cryptoStore: new MemoryCryptoStore(),
  });

  extendMatrixClient(matrixClient);

  if (E2E_ENABLED) {
    await matrixClient.initCrypto();
    // TODO: Prefer non-deprecated method
    matrixClient.setGlobalErrorOnUnknownDevices(false);
  }

  // https://spec.matrix.org/latest/client-server-api/#filtering
  const filter = await matrixClient.createFilter(
    application === "Center"
      ? centerFilter
      : application === "Go"
        ? goFilter
        : careFilter,
  );

  await matrixClient.startClient({
    initialSyncLimit: 8,
    filter,
  });

  return matrixClient;
};

function extendMatrixClient(matrixClient: MatrixClient) {
  // Join new rooms automatically
  matrixClient.on(
    RoomMemberEvent.Membership,
    async (_event: MatrixEvent, member: RoomMember) => {
      if (
        member.membership === "invite" &&
        member.userId === matrixClient.getUserId()
      ) {
        setTimeout(() => {
          if (
            matrixClient.store.getRoom(member.roomId)?.getMyMembership() ===
            "invite"
          ) {
            matrixClient.joinRoom(member.roomId);
          }
        }, 1);
      }
    },
  );

  matrixClient.sendTextMessage = async function (message, roomId) {
    if (!roomId) {
      throw new Error("roomId is required");
    }
    return matrixClient.sendMessage(roomId, {
      body: message,
      msgtype: MsgType.Text,
    });
  };
}

export const sendTextMessage = (
  matrixClient: MatrixClient,
  roomId: string,
  message: string,
  clearInput: () => void,
  senderGivenName?: string,
  imageURL?: string | null,
  senderTitle?: string,
) => {
  clearInput();
  matrixClient
    .sendEvent(roomId, EventType.RoomMessage, {
      msgtype: MsgType.Text,
      body: message.trim(),
      // @ts-expect-error - `sender` is not part of matrix-js-sdk's types
      sender: {
        name: senderGivenName,
        imageURL: imageURL,
        title: senderTitle,
      },
    })
    .catch((err) => {
      console.error(err);
    });
};

export const sendReadReceipt = (
  matrixClient: MatrixClient,
  event: MatrixEvent | null,
) => {
  matrixClient.sendReadReceipt(event, ReceiptType.Read, true).catch((err) => {
    console.error(err);
  });
};

export const useMatrixClient = (
  application: "Center" | "Go" | "Care",
  getJwt: () => Promise<{ baseUrl: string; token: string }>,
  chatEnabled: boolean,
) => {
  // Opt out from re-creating the Matrix client.
  const hasStartedCreating = useRef(false);

  const [matrixClient, setMatrixClient] = useState<MatrixClient | null>(null);

  // This effect is executed as follows:
  // 1. MatrixClient is null, getJwt is DEFINED, Application is X, hasStartedCreating starts as false and becomes true during execution.
  // ❗ In the unlucky case that the component unmounts between 1. and 2., we will have a MatrixClient that is never stopped. It is not a huge issue.
  // 2. MatrixClient is DEFINED, getJwt is still DEFINED, Application is still X, hasStartedCreating is still true.
  useEffect(() => {
    const initiateMatrixClient = async () => {
      // If we already have a non-stopped MatrixClient, don't create a new one.
      if (hasStartedCreating.current || matrixClient?.clientRunning) {
        return;
      }

      // Stop future creation attempts if we already have an unstopped client in the making.
      hasStartedCreating.current = true;

      try {
        const jwt = await getJwt();
        const client = await newMatrixClient(application, jwt);
        client.once(ClientEvent.Sync, () => {
          setMatrixClient(client);
        });
      } catch (e) {
        if (application === "Center" || application === "Go") {
          const errorMessage =
            "Kunde inte starta chatten. Ladda om sidan för att försöka igen.";

          Sentry.captureException(e);
          Sentry.captureException(
            new Error(`An error alert was shown: ${errorMessage}`),
          );

          alert(errorMessage);
        }
        // For Care, we rely on passive UI to communicate issues.
      }
    };

    if (chatEnabled) {
      initiateMatrixClient();
    }

    // Only when matrixClient is defined and a dependency changes/component unmounts, this cleanup will run.
    // matrixClient is only updated _once_ in the effect above, after the other dependencies' expected changes, so changes to matrixClient are not a problem.
    // getJwt _never_ changes, it is a static function provided by the parent component.
    // application never changes, and if it does, it makes sense to stop the old client and start a new one.
    // That leaves unmounting, which is the main scenario where we want to stop the MatrixClient.
    return () => {
      // Most cleanups happen before the matrixClient is set, then this will do nothing.
      if (!matrixClient) return;

      // Nothing to clean up.
      if (!chatEnabled) return;

      // Allow re-creating the MatrixClient.
      hasStartedCreating.current = false;
      matrixClient.stopClient();
    };
  }, [matrixClient, getJwt, application, chatEnabled]);

  return matrixClient;
};
