import { useFeatureFlag } from "@/api/FeatureFlags";
import { Navigate } from "react-router-dom";
import { Loading } from "@components/Loading/Loading";

const FeatureFlag = ({
  children,
  feature,
}: {
  children: JSX.Element;
  feature: string;
}) => {
  const { data: flagIsEnabled, isPending } = useFeatureFlag(feature);

  if (isPending) return <Loading message="Laddar" />;

  if (!flagIsEnabled) {
    return <Navigate to="/" replace />;
  }

  return children;
};

export default FeatureFlag;
