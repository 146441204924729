import Pushy from "pushy-sdk-web";
import { PUSHY_APP_ID } from "./EnvUtils";
import { useState } from "react";
import { notificationApi } from "../api/ApiClient";
import { displayErrorMessageAlert } from "./ErrorUtils";

export const usePushyRegistration = () => {
  const [isRegistered, setIsRegistered] = useState(Pushy.isRegistered());

  const registerPushy = (userId: string | null | undefined) => {
    if (!userId) {
      displayErrorMessageAlert(
        "Kunde inte hitta ditt användar-id som behövs för att registrera enheten. Ladda om appen och försök igen.",
      );
      return;
    }

    // Register visitor's browser for push notifications
    Pushy.register({ appId: PUSHY_APP_ID })
      .then(function (deviceToken) {
        console.log("Pushy device token: " + deviceToken);

        // Register token with backend
        notificationApi.put(`/device`, {
          employeeId: userId,
          registrationToken: deviceToken,
        });

        setIsRegistered(true);
      })
      .catch(function (err) {
        // Notify user of failure
        displayErrorMessageAlert(
          "Något gick fel när enheten skulle registreras: " + err.message,
        );
      });
  };

  return { isRegistered, registerPushy };
};
