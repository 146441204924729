import {
  deducedError,
  displayErrorMessageAlert,
  isErrorWithKnownErrorCode,
  knownErrorCodeSchema,
} from "@/Utils/ErrorUtils";
import {
  finishActivityOccurrence,
  resetActivityOccurrence,
} from "@/api/Activities";
import StandaloneCheckbox from "@/components/Checkbox/StandaloneCheckbox";
import { IHomeVisitActivityOccurrenceWithoutPatientAndAssignees } from "@models/activities";
import { useMutation } from "@tanstack/react-query";
import * as Sentry from "@sentry/react";

const ActivityOccurrenceCheckbox = ({
  occurrence,
  refetchVisitWithOccurrences,
}: {
  occurrence: IHomeVisitActivityOccurrenceWithoutPatientAndAssignees;
  refetchVisitWithOccurrences: () => Promise<void>;
}) => {
  const {
    mutate: finishActivityOccurrenceMutation,
    isPending: isFinishingActivityOccurrence,
  } = useMutation({
    mutationFn: ({
      activityId,
      occurrenceId,
    }: {
      activityId: string;
      occurrenceId: string;
    }) => finishActivityOccurrence(activityId, occurrenceId),
    onSuccess: () => {
      return refetchVisitWithOccurrences();
    },
    // MED-2163 - prefer other ways of resolving errors than displaying alerts
    onError: (error) => {
      if (
        isErrorWithKnownErrorCode(error) &&
        error.response.data.code ===
          knownErrorCodeSchema.Values.OccurrenceFinished
      ) {
        Sentry.captureException(
          "Failed to finish activity occurrence. Occurrence was already finished. Refetching.",
        );
        return refetchVisitWithOccurrences();
      } else {
        displayErrorMessageAlert(
          `Gick inte att färdigmarkera aktiviteten. ${deducedError(error)}`,
        );
      }
    },
  });

  const {
    mutate: resetActivityOccurrenceMutation,
    isPending: isResettingActivityOccurrence,
  } = useMutation({
    mutationFn: ({
      activityId,
      occurrenceId,
    }: {
      activityId: string;
      occurrenceId: string;
    }) => resetActivityOccurrence(activityId, occurrenceId),
    onSuccess: () => {
      return refetchVisitWithOccurrences();
    },
    // MED-2163 - prefer other ways of resolving errors than displaying alerts
    onError: (error) => {
      if (
        isErrorWithKnownErrorCode(error) &&
        error.response.data.code ===
          knownErrorCodeSchema.Values.OccurrenceNotFinished
      ) {
        Sentry.captureException(
          "Failed to reset activity occurrence. Occurrence was not finished. Refetching.",
        );
        return refetchVisitWithOccurrences();
      } else {
        displayErrorMessageAlert(
          `Gick inte att återställa aktiviteten. ${deducedError(error)}`,
        );
      }
    },
  });

  const activityOccurrenceActionInProgress =
    isFinishingActivityOccurrence || isResettingActivityOccurrence;

  const { activityId, id: occurrenceId } = occurrence;

  return (
    <StandaloneCheckbox
      label={occurrence.title}
      name={occurrenceId}
      checked={occurrence.status === "finished"}
      onChange={() =>
        occurrence.status === "finished"
          ? resetActivityOccurrenceMutation({ activityId, occurrenceId })
          : finishActivityOccurrenceMutation({ activityId, occurrenceId })
      }
      disabled={activityOccurrenceActionInProgress}
    />
  );
};

export { ActivityOccurrenceCheckbox };
