import { useCenterBaseUrl } from "@/Utils/useCenterBaseUrl";
import { useNavigateWithPreservedQueryParams } from "@/Utils/useNavigateWithPreservedQueryParams";
import { GroupDetails } from "@/pages/commandcenter/Patients/Patient/Activities/GroupDetails/GroupDetails";
import { Dialog } from "@components/Dialog/Dialog";

export const GroupDetailsDialog = () => {
  const { navigateWithPreservedQueryParams } =
    useNavigateWithPreservedQueryParams();
  const centerBaseUrl = useCenterBaseUrl();

  return (
    <Dialog
      isOpen={true}
      onClose={() => {
        navigateWithPreservedQueryParams(`${centerBaseUrl}/activities`);
      }}
      title="Besöksdetaljer"
      hideTitle
    >
      <GroupDetails showPatientName showCloseButton={false} />
    </Dialog>
  );
};
