import { PATIENT_API_URL } from "@/Utils/EnvUtils";
import {
  patientsWithUnhandledMeasurementsQueryOptions,
  useParentRoutesPatient,
} from "@/api/Patients";
import { Iframe } from "@components/Iframe/Iframe";
import { useQueryClient } from "@tanstack/react-query";

export const Measurements = () => {
  const queryClient = useQueryClient();
  const patient = useParentRoutesPatient();
  const postMessageHandler = {
    messageType: "measurementAcknowledged",
    onMessageReceived: () => {
      queryClient.invalidateQueries({
        queryKey: patientsWithUnhandledMeasurementsQueryOptions.queryKey,
      });
    },
  };
  return (
    <Iframe
      title="Mätvärden"
      src={`${PATIENT_API_URL}/htmx/center/patients/${patient.id}/measurements?react=true`}
      loadingMessage="Hämtar mätvärden"
      padding={24}
      postMessageHandler={postMessageHandler}
    />
  );
};
