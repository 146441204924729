import { visitStatusSchema, visitStatusDictionary } from "@models/visits";
import {
  activityOccurrenceStatusSchema,
  activityOccurrenceStatusDictionary,
} from "@models/activities";
import z from "zod";

export const statusSchema = z.union([
  activityOccurrenceStatusSchema,
  visitStatusSchema,
]);
export type IStatus = z.infer<typeof statusSchema>;

export const statusDictionary = {
  ...activityOccurrenceStatusDictionary,
  ...visitStatusDictionary,
};
