import { IActivityCategory } from "@models/activities";
import styles from "./ActivityGroupTag.module.scss";
import clsx from "clsx";
import HouseIcon from "@components/icons/HouseIcon";
import VideoCameraIcon from "@components/icons/VideoCameraIcon";
import AvatarIcon from "@components/icons/AvatarIcon";
import MedicalCircleIcon from "@components/icons/MedicalCircleIcon";
import HealthParametersIcon from "@components/icons/HealthParametersIcon";

type IActivityGroupTag = {
  category: IActivityCategory;
  patient?: string;
  count?: number;
};

export const ActivityGroupTag = ({
  category,
  patient,
  count,
}: IActivityGroupTag) => {
  const renderIcon = () => {
    switch (category) {
      case "HomeVisit":
        return <HouseIcon />;
      case "VideoCall":
        return <VideoCameraIcon />;
      case "PatientTask":
        return <AvatarIcon />;
      case "AdminTask":
        return <MedicalCircleIcon />;
      case "PatientMeasurementTask":
        return <HealthParametersIcon />;
      default:
        return null;
    }
  };

  return (
    <div className={clsx([styles.container, styles[`category-${category}`]])}>
      <div className={styles.icon}>{renderIcon()}</div>
      {count ? <div className={styles.count}>{count}</div> : null}
      {patient ? <div className={styles.patient}>{patient}</div> : null}
    </div>
  );
};
