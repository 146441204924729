import { z } from "zod";
import { addressWithCoordinates } from "./addresses";
import { localizePhoneNumber } from "./phoneNumbers";
import { dateSchema } from "./date-and-time";

const relativeSchema = z.object({
  id: z.string().uuid(),
  name: z.string(),
  phoneNumber: z.string().transform(localizePhoneNumber),
  relation: z.string(),
  isLivingTogether: z.boolean(),
});

export type IRelative = z.infer<typeof relativeSchema>;
export const patientStatusSchema = z.enum([
  "prospect",
  "admitted",
  "discharged",
  "declined",
]);
export const patientStatusDictionary: Record<
  IPatientStatus,
  { singular: { sv: string }; plural: { sv: string } }
> = {
  prospect: {
    singular: { sv: "Under bedömning" },
    plural: { sv: "Under bedömning" },
  },
  admitted: { singular: { sv: "Inskriven" }, plural: { sv: "Inskrivna" } },
  discharged: { singular: { sv: "Utskriven" }, plural: { sv: "Utskrivna" } },
  declined: { singular: { sv: "Fått avslag" }, plural: { sv: "Fått avslag" } },
};
export type IPatientStatus = z.infer<typeof patientStatusSchema>;

export const activeStatuses = [
  patientStatusSchema.Values.prospect,
  patientStatusSchema.Values.admitted,
];

export const canDischargePatient = ({ status }: Pick<IPatient, "status">) =>
  status === patientStatusSchema.Values.admitted;

export const ward = z.object({
  displayName: z.string(),
  id: z.number(),
});

export const wardsSchema = z.array(ward);

export type IWard = z.infer<typeof ward>;

export const prospectPatientSchema = z.object({
  address: addressWithCoordinates,
  healthcareJourneyNumber: z.string().length(8).nullish(),
  id: z.string().uuid(),
  information: z.string().nullish(),
  name: z.string(),
  personalIdentityNumber: z.string().length(12),
  phoneNumber: z.string().transform(localizePhoneNumber).nullish(),
  status: z.literal(patientStatusSchema.Values.prospect),
  relatives: z.array(relativeSchema),
  safetyAlarmText: z.string().nullish(),
  registeredAt: dateSchema,
  ward: ward,
});
export type IRegisteredPatient = z.infer<typeof prospectPatientSchema>;

const admittedPatientSchema = prospectPatientSchema.extend({
  admittedAt: dateSchema,
  status: z.literal(patientStatusSchema.Values.admitted),
});
export type IAdmittedPatient = z.infer<typeof admittedPatientSchema>;

const declinedPatientSchema = prospectPatientSchema.extend({
  declinedAt: dateSchema,
  status: z.literal(patientStatusSchema.Values.declined),
});
export type IDeclinedPatient = z.infer<typeof declinedPatientSchema>;

const dischargedPatientSchema = admittedPatientSchema.extend({
  dischargedAt: dateSchema,
  status: z.literal(patientStatusSchema.Values.discharged),
});
export type IDischargedPatient = z.infer<typeof dischargedPatientSchema>;

// Standardized omitters
const listPatient = {
  healthcareJourneyNumber: true,
  relatives: true,
  safetyAlarmText: true,
} as const;

export const patientSchema = z.union([
  prospectPatientSchema,
  admittedPatientSchema,
  declinedPatientSchema,
  dischargedPatientSchema,
]);
export type IPatient = z.infer<typeof patientSchema>;

export const listProspectPatientSchema =
  prospectPatientSchema.omit(listPatient);
export const listAdmittedPatientSchema =
  admittedPatientSchema.omit(listPatient);
export const listDischargedPatientSchema =
  dischargedPatientSchema.omit(listPatient);

export const listPatientSchema = z.union([
  prospectPatientSchema.omit(listPatient),
  admittedPatientSchema.omit(listPatient),
  declinedPatientSchema.omit(listPatient),
  dischargedPatientSchema.omit(listPatient),
]);
