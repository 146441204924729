import { z } from "zod";
import { scheduledShiftSchema } from "./shifts";
import {
  activityOccurrenceStatusDictionary,
  homeVisitActivityOccurrenceWithoutPatientAndAssigneesSchema,
} from "./activities";
import { listPatientSchema } from "./patients";
import { timestampSchema } from "./date-and-time";
import { isEqual } from "date-fns";

const sortOccurrencesByStartTime = (occurrences: Array<{ start: Date }>) => {
  return [...occurrences].sort((a, b) => {
    if (a.start < b.start) {
      return -1;
    }
    if (a.start > b.start) {
      return 1;
    }
    return 0;
  });
};

const sortOccurrencesByEndTime = (occurrences: Array<{ end: Date }>) => {
  return [...occurrences].sort((a, b) => {
    if (a.end < b.end) {
      return -1;
    }
    if (a.end > b.end) {
      return 1;
    }
    return 0;
  });
};

const occurrencesAreOverlapping = (
  occurrences: Array<
    Pick<z.infer<typeof visitSchema>["occurrences"][0], "start" | "end">
  >,
) => {
  const latestStart =
    sortOccurrencesByStartTime(occurrences)[occurrences.length - 1]?.start;
  const earliestEnd = sortOccurrencesByEndTime(occurrences)[0]?.end;

  if (!latestStart || !earliestEnd) {
    return false;
  }

  if (earliestEnd > latestStart) {
    return true;
  }

  return false;
};

export const calculateVisitTimes = (visit: {
  occurrences: Array<
    Pick<
      z.infer<typeof visitSchema>["occurrences"][0],
      "start" | "end" | "timeOfDay"
    >
  >;
}) => {
  const { occurrences } = visit;
  const visitOccurrencesExcludingJit = occurrences.filter((occurrence) => {
    const isJit = isEqual(occurrence.start, occurrence.end);
    return !isJit;
  });

  if (visitOccurrencesExcludingJit.length === 0) {
    return {
      start: undefined,
      end: undefined,
    };
  }

  if (!occurrencesAreOverlapping(visitOccurrencesExcludingJit)) {
    return {
      start: undefined,
      end: undefined,
    };
  }

  const isAnyTimeOfDay = visitOccurrencesExcludingJit.every(
    (occurrence) => occurrence.timeOfDay === "Any",
  );

  const start = sortOccurrencesByStartTime(visitOccurrencesExcludingJit).at(
    -1,
  )?.start;
  const end = sortOccurrencesByEndTime(visitOccurrencesExcludingJit)[0]?.end;

  if (!start || !end) {
    return {
      start: undefined,
      end: undefined,
    };
  }

  return {
    start,
    end,
    isAnyTimeOfDay,
  };
};

export const getVisitStatusTimestamp = (
  visit: Pick<
    IVisit,
    "status" | "startedTravellingToAt" | "startedAt" | "finishedAt"
  >,
) => {
  const { status, startedTravellingToAt, startedAt, finishedAt } = visit;

  if (status === "travellingTo" && startedTravellingToAt) {
    return startedTravellingToAt;
  }

  if (status === "ongoing" && startedAt) {
    return startedAt;
  }

  if (status === "finished" && finishedAt) {
    return finishedAt;
  }

  return undefined;
};

export const visitStatuses = [
  "planned",
  "travellingTo",
  "ongoing",
  "finished",
] as const;
export const visitStatusSchema = z.enum(visitStatuses);
export type IVisitStatus = z.infer<typeof visitStatusSchema>;

export const visitStatusDictionary = {
  planned: { sv: "Planerat" },
  travellingTo: { sv: "På väg" },
  ongoing: activityOccurrenceStatusDictionary.ongoing,
  finished: activityOccurrenceStatusDictionary.finished,
};

export const visitSchemaWithPatientId = z.object({
  id: z.string(),
  assignees: z.array(scheduledShiftSchema),
  patientId: z.string(),
  occurrences: z.array(
    homeVisitActivityOccurrenceWithoutPatientAndAssigneesSchema,
  ),
  status: visitStatusSchema,
  startedTravellingToAt: timestampSchema,
  startedAt: timestampSchema,
  finishedAt: timestampSchema,
});
export const visitSchema = visitSchemaWithPatientId
  .omit({ patientId: true })
  .extend({ patient: listPatientSchema });
export type IVisit = z.infer<typeof visitSchema>;
