import { z } from "zod";
import { dateSchema, format } from "./date-and-time";
import { isSameDay, isToday, isYesterday } from "date-fns";

export const medicalCompetenceSchema = z.enum([
  "MedicalDoctor",
  "NurseAssistant",
  "NursePractitioner",
  "Physiotherapist",
  "Support",
]);

export type IMedicalCompetence = z.infer<typeof medicalCompetenceSchema>;

export const medicalCompetenceDictionary: Record<
  IMedicalCompetence,
  {
    sv: { long: string; short: string };
  }
> = {
  MedicalDoctor: { sv: { long: "Läkare", short: "LÄK" } },
  NurseAssistant: { sv: { long: "Undersköterska", short: "USK" } },
  NursePractitioner: { sv: { long: "Sjuksköterska", short: "SSK" } },
  Physiotherapist: { sv: { long: "Fysioterapeut", short: "FYS" } },
  Support: {
    sv: { long: "Support", short: "SUP" },
  },
};

export const medicalTitle = (
  probablyMedicalCompetence: unknown,
  { format }: { format: "long" | "short" },
) => {
  try {
    const parsedMedicalCompetence = medicalCompetenceSchema.parse(
      probablyMedicalCompetence,
    );
    return medicalCompetenceDictionary[parsedMedicalCompetence].sv[format];
  } catch {
    return undefined;
  }
};

export const employee = z.object({
  id: z.number(),
  matrixUserId: z.string().uuid(),
  firstName: z.string(),
  lastName: z.string(),
  competence: medicalCompetenceSchema,
  imageURL: z.string().nullable(),
});
export const employees = z.array(employee);
export type IEmployee = z.infer<typeof employee>;
export type IEmployees = z.infer<typeof employees>;

export const employeeName = (
  { firstName, lastName }: Pick<IEmployee, "firstName" | "lastName">,
  { format }: { format: "long" | "short" } = { format: "short" },
) => {
  if (format === "short") {
    if (lastName.length === 0) {
      return `${firstName}`;
    }
    return `${firstName} ${lastName.charAt(0)}`;
  }
  return `${firstName} ${lastName}`;
};

const shiftTypeSchema = z.enum(["Ambulating", "Center", "Doctor"]);
export const scheduledShiftSchema = z.object({
  id: z.number(),
  employee: employee.nullish(),
  startDateTime: dateSchema,
  endDateTime: dateSchema,
  competence: medicalCompetenceSchema,
  type: shiftTypeSchema,
});
export type IScheduledShift = z.infer<typeof scheduledShiftSchema>;
export const scheduledShiftsSchema = z.array(scheduledShiftSchema);

export const calculateAvailableShifts = ({
  scheduledShifts,
  assignedShiftIds,
}: {
  scheduledShifts: IScheduledShift[];
  assignedShiftIds: number[];
}) =>
  scheduledShifts.filter(({ id }) => {
    if (assignedShiftIds.includes(id)) {
      return false;
    }
    return true;
  });

export const shiftName = ({
  employee,
  competence,
  options,
}: Pick<IScheduledShift, "employee" | "competence"> & {
  options: { length: "long" | "short" };
}) => {
  const { length } = options;
  if (!employee) {
    return medicalCompetenceDictionary[competence].sv[length];
  }

  if (!employee.lastName) {
    return `${employee.firstName}`;
  }

  const lastName =
    length === "long" ? employee.lastName : employee.lastName.charAt(0);

  return `${employee.firstName} ${lastName}`;
};

export const shiftTimeDisplayValues = ({
  selectedDate,
  startDateTime,
  endDateTime,
}: {
  selectedDate: Date;
  startDateTime: Date;
  endDateTime: Date;
}) => {
  // MED-1618: We know that we only have shifts that started on the selected day, or the day before.
  const startedDayBefore = !isSameDay(selectedDate, startDateTime);
  // MED-2431: Clear indication on nightshift that ends the day after.
  const hasEndTimeAfterToday = !isSameDay(startDateTime, endDateTime);
  const formattedDayBefore = (
    isYesterday(startDateTime) ? "igår" : format(startDateTime, "d MMM")
  ).toLocaleLowerCase();
  const formattedDayToday = (
    isToday(startDateTime) ? "idag" : format(startDateTime, "d MMM")
  ).toLocaleLowerCase();

  return {
    startedDayBefore,
    hasEndTimeAfterToday,
    formattedDayBefore,
    formattedDayToday,
  };
};
