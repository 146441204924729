import { useFormContext } from "react-hook-form";
import { WeekdaysPicker, IWeekdaysPickerFields } from "./WeekdaysPicker";
import { RecurrencePicker, IRecurrencePickerFields } from "./RecurrencePicker";
import { recurringDictionary } from "./recurrenceUtils";
import { ITimeSlotsPickerFields, TimeSlotsPicker } from "./TimeSlotsPicker";
import { DateInput, IDateInputFields } from "./DateInput";
import { useEffect } from "react";

export type ISchedulingFields = IRecurrencePickerFields &
  IWeekdaysPickerFields &
  IDateInputFields &
  ITimeSlotsPickerFields;

export const Scheduling = () => {
  const { unregister, watch } = useFormContext<ISchedulingFields>();

  const isRecurring = watch("recurrence") !== recurringDictionary.never;
  const isRecurringOnWeekdays =
    watch("recurrence") === recurringDictionary.custom;

  useEffect(() => {
    if (!isRecurringOnWeekdays) {
      unregister("weekdays");
    }
  }, [isRecurringOnWeekdays, unregister]);

  return (
    <>
      <RecurrencePicker />
      {isRecurringOnWeekdays ? <WeekdaysPicker /> : <></>}
      <DateInput hasEndDate={isRecurring} />
      <TimeSlotsPicker isRecurring={isRecurring} />
    </>
  );
};
