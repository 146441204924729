import clsx from "clsx";
import styles from "./MessageGroup.module.scss";
import CrossIcon from "../icons/CrossIcon";
import { isToday, isYesterday } from "date-fns";
import Portrait from "../Portrait/Portrait";
import { format } from "../../models/date-and-time";

// Duplicated from `chat` package until we figure out best sharing pattern.
type IMessageStatus =
  // The event is in the process of being sent.
  | "sending"
  // The event has been sent to the server, and echo is received.
  | "sent"
  // The event was not sent and will no longer be retried.
  | "not-sent";

export type IMessageGroup = {
  children: JSX.Element | JSX.Element[];
  placement: "right" | "left";
  senderName?: string;
  senderTitle?: string;
  timestamp: string;
  hideAvatar?: boolean;
  status: IMessageStatus;
  readIndicator?: Date | boolean | undefined;
  senderPhotoURL?: string | null;
  isLatestMessage?: boolean;
};

export const MessageGroup = ({
  children,
  placement,
  senderName,
  senderTitle,
  status,
  timestamp,
  hideAvatar = false,
  readIndicator,
  senderPhotoURL,
  isLatestMessage,
}: IMessageGroup) => {
  const showComplementaryInformation =
    status === "not-sent" || (!hideAvatar && senderName);

  return (
    <div
      className={clsx(
        styles.messageGroup,
        styles[`placement-${placement}`],
        styles[`status-${status}`],
        !showComplementaryInformation && styles.noComplementaryInformation,
      )}
    >
      <div className={styles.senderAndTimestamp}>
        {senderName !== undefined ? (
          <span className={styles.sender}>{senderName}, </span>
        ) : null}
        {senderTitle !== undefined ? <span>{senderTitle}, </span> : null}
        {status === "sent" ? (
          <time>{timestamp}</time>
        ) : status === "sending" ? (
          <i>Skickar...</i>
        ) : (
          <i>Ej levererat</i>
        )}
      </div>
      {typeof readIndicator === "object" ? (
        <div className={styles.readIndicator}>
          <span>{formatReadMessageTimestamp(readIndicator)}</span>
        </div>
      ) : readIndicator ? (
        <div className={styles.readIndicator}>
          <span>Läst</span>
        </div>
      ) : isLatestMessage && status === "sent" ? (
        <div className={styles.deliveredNotRead}>
          <span>Levererat, ännu ej läst</span>
        </div>
      ) : null}
      {showComplementaryInformation ? (
        <div className={styles.complementaryInformation}>
          {status === "not-sent" ? (
            <CrossIcon />
          ) : (
            // showComplementaryInformation implies that senderName is defined
            <Portrait name={senderName!} photoUrl={senderPhotoURL} />
          )}
        </div>
      ) : null}
      <div className={styles.messages}>{children}</div>
    </div>
  );
};

const formatReadMessageTimestamp = (timestamp: Date) => {
  if (isToday(timestamp)) {
    return "Läst " + format(timestamp, "H:mm");
  } else if (isYesterday(timestamp)) {
    return "Lästes igår";
  } else {
    return "Lästes " + format(timestamp, "d MMMM");
  }
};
