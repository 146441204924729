import { MatrixClient, MatrixEvent, Room } from "matrix-js-sdk";
import { displayedEvent } from "./useMatrixEvents";

const HISTORICAL_MESSAGE_COUNT_TO_RETRIEVE = 10;
const MAX_ITERATIONS = 50;

export const getHistory = async (matrixClient: MatrixClient, room: Room) => {
  let messageEventsAfterScrollback: MatrixEvent[] = [];
  let atStartOfRoom = false;
  // Copy to avoid mutating the room object
  const messageEventsBeforeScrollback = [
    ...room.getLiveTimeline().getEvents().filter(displayedEvent),
  ];

  for (
    let i = 0;
    messageEventsAfterScrollback.length <
      messageEventsBeforeScrollback.length +
        HISTORICAL_MESSAGE_COUNT_TO_RETRIEVE && i < MAX_ITERATIONS;
    i++
  ) {
    const roomAfterScrollback = await matrixClient.scrollback(
      room,
      HISTORICAL_MESSAGE_COUNT_TO_RETRIEVE,
    );
    if (
      roomAfterScrollback.oldState.paginationToken === null ||
      roomAfterScrollback.oldState.paginationToken.startsWith("t1-")
    ) {
      atStartOfRoom = true;
      break;
    }
    // Copy to avoid mutating the room object
    messageEventsAfterScrollback = [
      ...roomAfterScrollback
        .getLiveTimeline()
        .getEvents()
        .filter(displayedEvent),
    ];
  }

  const hasRemainingHistory = !atStartOfRoom;
  return hasRemainingHistory;
};
