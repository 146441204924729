import { OutlinedButton } from "@components/Button/Button";
import clsx from "clsx";
import styles from "./Timeline.module.scss";
import { Heading } from "@components/Heading/Heading";

export const Timeline = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  return <ol className={styles.timeline}>{children}</ol>;
};

export const TimelineItem = ({
  children,
  state,
  title,
  relatedAction,
}: {
  children: JSX.Element | JSX.Element[];
  state: "initial" | "ongoing" | "finished";
  title: string;
  relatedAction?: {
    label: string;
    onClick: () => void;
  };
}) => {
  return (
    <li className={clsx(styles.timelineItem, styles[state])}>
      <div className={styles.timelineItemTitleRow}>
        <Heading level="h3" weight="regular">
          {title}
        </Heading>
        {relatedAction !== undefined ? (
          <OutlinedButton
            size="small"
            weight="light"
            onClick={relatedAction.onClick}
          >
            {relatedAction.label}
          </OutlinedButton>
        ) : undefined}
      </div>
      {children}
    </li>
  );
};
