import {
  IRoute,
  removeRoute,
  routeKeys,
  updateActivityOccurrencesOrderInRoute,
} from "@/api/Routes";
import styles from "./RoutesListItem.module.scss";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deducedError, displayErrorMessageAlert } from "@/Utils/ErrorUtils";
import { Heading } from "@components/Heading/Heading";
import { Time } from "@/components/Time/Time";
import { PlainButton } from "@components/Button/Button";
import BinIcon from "@components/icons/BinIcon";
import { Visit } from "./Visit";
import clsx from "clsx";
import { RoutesContext } from "./RoutesContext";
import { determineTimespan } from "@/pages/commandcenter/Activities/WorkBlocks/helpers";
import * as Sentry from "@sentry/react";
import React, { useContext } from "react";
import { useFeatureFlag } from "@/api/FeatureFlags";
import { formattedTimeSpan } from "@/components/Time/timeUtils";
import { timeOfDayDictionary } from "@models/activities";

type IRouteListItem = {
  route: IRoute;
  legDurations: number[] | null;
  isEditable: boolean;
};

function moveItem<T>(array: T[], fromIndex: number, toIndex: number) {
  const tempArray = [...array];
  const from = tempArray.splice(fromIndex, 1)[0];
  if (!from) {
    Sentry.captureException(new Error("Could not find item to move in array"));
    return array;
  }
  tempArray.splice(toIndex, 0, from);
  return tempArray;
}

const Leg = ({ duration }: { duration: number | null | undefined }) => {
  if (duration === null) {
    return <span>Okänd restid</span>;
  }
  return <span>{`ca ${duration} minuters restid`}</span>;
};

export const RouteListItem = ({
  route,
  legDurations,
  isEditable,
}: IRouteListItem) => {
  const queryClient = useQueryClient();
  const { selectedRouteId, setSelectedRouteId } = useContext(RoutesContext);
  const { data: displayTravellingTimes } = useFeatureFlag(
    "DisplayTravellingTimes",
  );
  const { mutate: updateOccurrenceOrderMutation, isPending: isUpdatingOrder } =
    useMutation({
      mutationFn: ({
        visitId,
        direction,
      }: {
        visitId: string;
        direction: "up" | "down";
      }) => {
        let orderedVisitIds = route.visits.map(({ id }) => id);
        const occurrenceIndex = orderedVisitIds.findIndex(
          (id) => id === visitId,
        );
        if (direction === "up") {
          orderedVisitIds = moveItem(
            orderedVisitIds,
            occurrenceIndex,
            occurrenceIndex - 1,
          );
        } else {
          orderedVisitIds = moveItem(
            orderedVisitIds,
            occurrenceIndex,
            occurrenceIndex + 1,
          );
        }
        return updateActivityOccurrencesOrderInRoute(orderedVisitIds, route.id);
      },
      onError: (error) => {
        displayErrorMessageAlert(
          `Gick inte att flytta besöket. ${deducedError(error)}`,
        );
      },
      onSuccess: () => {
        return queryClient.invalidateQueries({ queryKey: routeKeys.all });
      },
    });

  const { mutate: removeBlockMutation, isPending: isRemovingBlock } =
    useMutation({
      mutationFn: () => removeRoute(route.id),
      onError: (error) => {
        displayErrorMessageAlert(
          `Gick inte att ta bort rutten. ${deducedError(error)}`,
        );
      },
      onSuccess: () => {
        return queryClient.invalidateQueries({ queryKey: routeKeys.all });
      },
    });

  const routeIsViewableInMap = route.visits.length > 0;
  const totalEstimatedTravelTimeInMinutes = legDurations
    ? legDurations.reduce((acc, legDuration) => acc + legDuration, 0)
    : null;
  return (
    <li className={styles.container}>
      <button
        disabled={!routeIsViewableInMap}
        className={clsx(
          styles.button,
          selectedRouteId === route.id && styles.selected,
        )}
        onClick={() => setSelectedRouteId(route.id)}
      >
        <div className={styles.headingRow}>
          <Heading level="h2">{route.name}</Heading>
          <div className={styles.visitsCounter}>
            <span>
              {route.visits.length} besök
              {totalEstimatedTravelTimeInMinutes === null ||
              !displayTravellingTimes
                ? ""
                : ` (restid ca ${totalEstimatedTravelTimeInMinutes} min)`}
            </span>
            {route.timespan ? (
              <>
                ,{" "}
                <Time>
                  {determineTimespan(route.timespan) === "Any"
                    ? timeOfDayDictionary.Any.short
                    : formattedTimeSpan(route.timespan.from, route.timespan.to)}
                </Time>
              </>
            ) : null}
          </div>
        </div>
      </button>
      {route.visits.length === 0 ? (
        <div className={styles.noVisits}>
          <p className={styles.noVisitsText}>
            Det finns inga besök i denna rutt.
          </p>
          <PlainButton
            size="small"
            weight="light"
            disabled={isRemovingBlock}
            onClick={() => removeBlockMutation()}
          >
            <BinIcon /> Radera rutt
          </PlainButton>
        </div>
      ) : (
        <ul
          aria-label={`${route.name}: Besökslista`}
          className={styles.visitsList}
        >
          {route.visits.map((visit, index) => (
            <React.Fragment key={visit.id}>
              {displayTravellingTimes ? (
                <li>
                  <Leg duration={legDurations?.[index]} />
                </li>
              ) : (
                <></>
              )}
              <Visit
                visit={visit}
                position={
                  index === 0 && index === route.visits.length - 1
                    ? "only"
                    : index === 0
                      ? "first"
                      : index === route.visits.length - 1
                        ? "last"
                        : "other"
                }
                isUpdatingOrder={isUpdatingOrder}
                moveUp={() =>
                  updateOccurrenceOrderMutation({
                    visitId: visit.id,
                    direction: "up",
                  })
                }
                moveDown={() =>
                  updateOccurrenceOrderMutation({
                    visitId: visit.id,
                    direction: "down",
                  })
                }
                routeId={route.id}
                isEditable={isEditable}
              />
            </React.Fragment>
          ))}
          {displayTravellingTimes ? (
            <li>
              <Leg duration={legDurations?.[route.visits.length]} />
            </li>
          ) : (
            <></>
          )}
        </ul>
      )}
    </li>
  );
};
