import { z } from "zod";

export const patientNoteStatusSchema = z.enum([
  "initiated",
  "succeeded",
  "failed",
  "accepted",
]);

export type IPatientNoteStatus = z.infer<typeof patientNoteStatusSchema>;

export type ICreatePatientNote = z.infer<typeof _createPatientNoteSchema>;
export type IConvertedPatientNoteWithPatientId = z.infer<
  typeof convertedPatientNoteWithPatientIdSchema
>;
export type IConvertedPatientNote = z.infer<typeof _convertedPatientNoteSchema>;

export const patientNoteTypeSchema = z.enum([
  "TransferNote",
  "Epicrisis",
  "GeneralNote",
]);

export type IPatientNoteType = z.infer<typeof patientNoteTypeSchema>;
export const patientNoteTypes = patientNoteTypeSchema.options;

export const typeDictionary: Record<IPatientNoteType, { sv: string }> = {
  TransferNote: { sv: "Överflyttningsanteckning" },
  Epicrisis: { sv: "Epikris" },
  GeneralNote: { sv: "Daganteckning" },
};

const transferNoteSchema = z.object({
  type: z.literal(patientNoteTypeSchema.Values.TransferNote),
  note: z.object({
    diagnosis: z.string().nullish(),
    background: z.string().nullish(),
    current: z.string().nullish(),
  }),
});

const epicrisisSchema = z.object({
  type: z.literal(patientNoteTypeSchema.Values.Epicrisis),
  note: z.object({
    diagnosis: z.string().nullish(),
    courseOfCare: z.string().nullish(),
    reasonForAdmittance: z.string().nullish(),
    medicalHistory: z.string().nullish(),
  }),
});

const generalNoteSchema = z.object({
  type: z.literal(patientNoteTypeSchema.Values.GeneralNote),
  note: z.object({
    // TODO: Change this in backend and then rename to annotation
    current: z.string().nullish(),
  }),
});

const patientNoteTypeAndNoteSchema = z.union([
  epicrisisSchema,
  transferNoteSchema,
  generalNoteSchema,
]);

const _createPatientNoteSchema = z.object({
  patientId: z.string().uuid(),
  anonymisedNote: patientNoteTypeAndNoteSchema,
  status: patientNoteStatusSchema.nullish(),
});

export const patientNoteStates = {
  EDITED: "edited",
  MUTATED: "mutated",
  SUBMITTED: "submitted",
  CANCEL_EDITING: "cancel_editing",
};

export const convertedPatientNoteWithPatientIdSchema = z.object({
  id: z.string().uuid(),
  patientId: z.string().uuid(),
  status: patientNoteStatusSchema,
  acceptedBy: z.string().nullish(),
  convertedText: z.string(),
  anonymisedNote: patientNoteTypeAndNoteSchema,
  conversionTimeStamp: z.string().nullish(),
});

const _convertedPatientNoteSchema =
  convertedPatientNoteWithPatientIdSchema.omit({
    patientId: true,
  });

type TransferNoteNoteKeys = keyof z.infer<typeof transferNoteSchema>["note"];
type EpicrisisNoteNoteKeys = keyof z.infer<typeof epicrisisSchema>["note"];
type GeneralNoteNoteKeys = keyof z.infer<typeof generalNoteSchema>["note"];

export type IPatientNoteNoteKeys =
  | TransferNoteNoteKeys
  | EpicrisisNoteNoteKeys
  | GeneralNoteNoteKeys;

export const noteByTypeDictionary: Record<
  IPatientNoteType,
  Partial<Record<IPatientNoteNoteKeys, { sv: string }>>
> = {
  TransferNote: {
    diagnosis: { sv: "Diagnos/Åtgärd" },
    background: { sv: "Bakgrund" },
    current: { sv: "Aktuellt" },
  },
  Epicrisis: {
    diagnosis: { sv: "Diagnos/Åtgärd" },
    courseOfCare: { sv: "Vårdförlopp" },
    reasonForAdmittance: { sv: "Inskrivningsorsak" },
    medicalHistory: { sv: "Anamnes" },
  },
  GeneralNote: {
    current: { sv: "Anteckning" },
  },
};

export const acceptedPatientNoteSchema = z.object({
  id: z.string().uuid(),
  status: z.literal("accepted"),
  acceptedBy: z.string().nullish(),
  convertedText: z.string(),
  anonymisedNote: patientNoteTypeAndNoteSchema,
  conversionTimeStamp: z.string().nullish(),
});
