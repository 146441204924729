import { activityKeys, updateMeasurements } from "@/api/Activities";
import { FilledButton } from "@components/Button/Button";
import ErrorMessage from "@components/ErrorMessage/ErrorMessage";
import Form from "@/components/Form/Form";
import { Loading } from "@components/Loading/Loading";
import { deducedError } from "@/Utils/ErrorUtils";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { FormProvider, useForm } from "react-hook-form";
import { IMeasurementsType } from "@models/activities";
import { MeasurementsPicker } from "@/forms/AddActivityForm/MeasurementsPicker";

export const EditMeasurements = ({
  currentMeasurements,
  onSuccess,
  activityId,
}: {
  currentMeasurements: IMeasurementsType[];
  onSuccess: () => void;
  activityId: string;
}) => {
  const methods = useForm({
    defaultValues: {
      measurements: currentMeasurements,
    },
  });

  const {
    formState: { errors, isDirty },
    getValues,
    handleSubmit,
    reset,
    setError,
  } = methods;

  const queryClient = useQueryClient();
  const { mutate, isPending, isSuccess } = useMutation({
    mutationFn: (measurements: IMeasurementsType[]) =>
      updateMeasurements(activityId, measurements),
    onError: (error) => {
      setError("root.server", {
        message: deducedError(error),
      });
      // Reset `isDirty` to support only showing server error when the form is not changed.
      reset(getValues(), {
        keepErrors: true,
        keepIsSubmitted: true,
        keepTouched: true,
        keepIsValid: true,
        keepSubmitCount: true,
      });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: activityKeys.all });
      onSuccess();
    },
  });

  return (
    <FormProvider {...methods}>
      <Form
        onSubmit={handleSubmit((formData) => {
          mutate(formData.measurements);
        })}
      >
        {isPending || isSuccess ? (
          <Loading message="Ändrar mätvärden" />
        ) : (
          <>
            {errors.root?.server?.message && !isDirty ? (
              <ErrorMessage message={errors.root.server.message} />
            ) : null}
            <Form.Row>
              <MeasurementsPicker />
            </Form.Row>
            {isDirty ? (
              <FilledButton type="submit">Spara ändringar</FilledButton>
            ) : (
              <></>
            )}
          </>
        )}
      </Form>
    </FormProvider>
  );
};
