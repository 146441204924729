import { patientKeys, updateHealthcareJourneyNumber } from "@/api/Patients";
import ErrorMessage from "@components/ErrorMessage/ErrorMessage";
import { FilledButton } from "@components/Button/Button";
import Form from "@/components/Form/Form";
import InputField from "@/components/InputField/InputField";
import { Loading } from "@components/Loading/Loading";
import { deducedError } from "@/Utils/ErrorUtils";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useForm } from "react-hook-form";

export const EditHealthcareJourneyNumber = ({
  currentHealthcareJourneyNumber,
  onSuccess,
  patientId,
}: {
  currentHealthcareJourneyNumber: string | null | undefined;
  onSuccess: () => void;
  patientId: string;
}) => {
  const {
    formState: { errors, isDirty },
    getValues,
    handleSubmit,
    register,
    reset,
    setError,
  } = useForm({
    defaultValues: {
      healthcareJourneyNumber: currentHealthcareJourneyNumber,
    },
  });

  const queryClient = useQueryClient();
  const { mutate, isPending, isSuccess } = useMutation({
    mutationFn: (healthcareJourneyNumber: string | null | undefined) =>
      updateHealthcareJourneyNumber(patientId, healthcareJourneyNumber),
    onError: (error) => {
      setError("root.server", {
        message: deducedError(error),
      });
      // Reset `isDirty` to support only showing server error when the form is not changed.
      reset(getValues(), {
        keepErrors: true,
        keepIsSubmitted: true,
        keepTouched: true,
        keepIsValid: true,
        keepSubmitCount: true,
      });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: patientKeys.detail(patientId),
      });
      onSuccess();
    },
  });

  return (
    <Form
      onSubmit={handleSubmit((formData) => {
        mutate(formData.healthcareJourneyNumber);
      })}
    >
      {isPending || isSuccess ? (
        <Loading message="Ändrar sjukresenummer" />
      ) : (
        <>
          {errors.root?.server?.message && !isDirty ? (
            <ErrorMessage message={errors.root.server.message} />
          ) : undefined}
          <Form.Row>
            <InputField
              label="Sjukresenummer (valfri)"
              errorMessage={errors.healthcareJourneyNumber?.message}
              {...register("healthcareJourneyNumber", {
                pattern: {
                  value: /^\d{8}$/,
                  message: "Ange sjukresenummer med 8 siffror.",
                },
              })}
            />
          </Form.Row>
          <FilledButton type="submit">Spara ändringar</FilledButton>
        </>
      )}
    </Form>
  );
};
