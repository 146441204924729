import { competenceColor } from "@/Utils/ColorUtils";
import Chip, { IChip } from "./Chip";
import {
  IMedicalCompetence,
  medicalCompetenceDictionary,
} from "@models/shifts";
import { IActivityRequirementStatus } from "@models/activities";
import CrossIcon from "@components/icons/CrossIcon";
import { getBackground, getBorder } from "./chipUtils";

export const CompetenceChip = ({
  competence,
  state,
  content,
  onRemove,
  size,
  disabled = false,
}: {
  competence: IMedicalCompetence;
  state: IActivityRequirementStatus;
  content?: string;
  onRemove?: () => void;
  disabled?: boolean;
} & Pick<IChip, "size">) => {
  const label =
    content ??
    (competence === "Support"
      ? medicalCompetenceDictionary[competence].sv.long
      : medicalCompetenceDictionary[competence].sv.short);

  return (
    <Chip
      color={competenceColor[competence].colorName}
      onClick={
        state === "assigned" && onRemove !== undefined ? onRemove : undefined
      }
      iconEnd={
        state === "assigned" && onRemove !== undefined ? (
          <CrossIcon />
        ) : undefined
      }
      aria-label={`Ta bort ${medicalCompetenceDictionary[competence].sv.long}`}
      background={getBackground(state)}
      border={getBorder(state)}
      size={size}
      disabled={disabled}
    >
      {label}
    </Chip>
  );
};
