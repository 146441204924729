import { z } from "zod";
import { patientApi } from "./ApiClient";
import { IEmployee } from "@models/shifts";

const jwtSchema = z.object({ baseUrl: z.string(), token: z.string() });

export const getCenterJwt = async () => {
  const url = `center/chat/token`;

  const response = await patientApi.post(url);
  return jwtSchema.parse(response.data);
};

export const getEmployeeJwt = async (employee: IEmployee) => {
  if (!employee) {
    return Promise.reject(new Error("Invalid employee"));
  }

  const url = `/employees/${employee.id}/chat/token`;
  const response = await patientApi.post(url);
  return jwtSchema.parse(response.data);
};
