import { BlockChip } from "@/components/Chips/BlockChip";
import { LoadingOverlay } from "@components/Loading/Loading";
import {
  assignActivityOccurrenceToRoute,
  routeKeys,
  useRoutes,
  addRoute,
  getRouteFromActivityOccurrence,
  unassignActivityOccurrenceFromRoute,
  INewRouteWithName,
  getRouteFromGroup,
  routeStatusSchema,
  assignGroupToRoute,
  unassignGroupFromRoute,
} from "@/api/Routes";
import { deducedError, displayErrorMessageAlert } from "@/Utils/ErrorUtils";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { generateRandomUUID } from "@/Utils/UniqueId";
import { useForm } from "react-hook-form";
import ErrorMessage from "@components/ErrorMessage/ErrorMessage";
import Chip from "@/components/Chips/Chip";
import PlusIcon from "@components/icons/PlusIcon";
import { Popover } from "@/components/Popover/Popover";
import { activityOccurrenceAndGroupKeys } from "@/api/Activities";
import { useSelectedDate } from "@/Utils/useSelectedDate";
import { useSelectedDateHasPassed } from "@/Utils/useSelectedDateHasPassed";
import styles from "./BlockCell.module.scss";
import InputField from "@/components/InputField/InputField";
import { PlainButton } from "@components/Button/Button";
import NoResults from "@/components/NoResults/NoResults";
import * as Sentry from "@sentry/react";

const SelectBlockButton = () => (
  <Chip
    size="small"
    color="primary"
    shape="sharp"
    border="dashed"
    background="none"
    iconStart={<PlusIcon />}
  >
    Rutt
  </Chip>
);

type IBlock =
  | {
      activityId: string;
      activityOccurrenceId: string;
    }
  | {
      groupId: string;
    };

export const BlockCell = (props: IBlock) => {
  const selectedDate = useSelectedDate();

  const { data: routes, isPending, isError, error } = useRoutes(selectedDate);

  const queryClient = useQueryClient();

  const pastDateSelected = useSelectedDateHasPassed();

  const {
    formState: { errors },
    handleSubmit,
    reset: resetForm,
    register,
  } = useForm({
    defaultValues: {
      blockName: "",
    },
  });

  const handleCreateNewBlock = handleSubmit((formData) => {
    const newRoute: INewRouteWithName = {
      id: generateRandomUUID(),
      name: formData.blockName,
      date: selectedDate,
    };
    createNewBlock({ newRoute });
  });

  const {
    mutate: placeOccurrenceInBlock,
    isPending: isPlacingOccurrenceInBlock,
  } = useMutation({
    mutationFn: ({
      activityId,
      occurrenceId,
      routeId,
    }: {
      activityId: string;
      occurrenceId: string;
      routeId: string;
    }) => assignActivityOccurrenceToRoute(activityId, occurrenceId, routeId),
    onError: (err) => {
      displayErrorMessageAlert(
        `Gick inte att lägga till aktivitetstillfället i rutten. ${deducedError(
          err,
        )}`,
      );
    },
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries({ queryKey: routeKeys.all }),
        queryClient.invalidateQueries({
          queryKey: activityOccurrenceAndGroupKeys.all(),
        }),
      ]);
      resetForm();
    },
  });

  const { mutate: placeGroupInBlock, isPending: isPlacingGroupInBlock } =
    useMutation({
      mutationFn: ({
        groupId,
        routeId,
      }: {
        groupId: string;
        routeId: string;
      }) => assignGroupToRoute(groupId, routeId),
      onError: (err) => {
        displayErrorMessageAlert(
          `Gick inte att lägga till besöket i rutten. ${deducedError(err)}`,
        );
      },
      onSuccess: async () => {
        await Promise.all([
          queryClient.invalidateQueries({ queryKey: routeKeys.all }),
          queryClient.invalidateQueries({
            queryKey: activityOccurrenceAndGroupKeys.all(),
          }),
        ]);
        resetForm();
      },
    });

  // Create new block and on success, place occurrence/group in it
  const {
    mutate: createNewBlock,
    isPending: isCreatingNewBlock,
    isError: creatingNewBlockFailed,
    error: createNewBlockError,
  } = useMutation({
    mutationFn: ({ newRoute }: { newRoute: INewRouteWithName }) =>
      addRoute(newRoute),
    onError: (error) => {
      displayErrorMessageAlert(
        `Gick inte att skapa rutt. ${deducedError(error)}`,
      );
    },
    onSuccess: (_, { newRoute: { id } }) =>
      "groupId" in props
        ? placeGroupInBlock({ groupId: props.groupId, routeId: id })
        : placeOccurrenceInBlock({
            activityId: props.activityId,
            occurrenceId: props.activityOccurrenceId,
            routeId: id,
          }),
  });

  const {
    mutate: removeOccurrenceFromBlock,
    isPending: isRemovingOccurrenceFromBlock,
  } = useMutation({
    mutationFn: ({
      activityId,
      occurrenceId,
      routeId,
    }: {
      activityId: string;
      occurrenceId: string;
      routeId: string;
    }) =>
      unassignActivityOccurrenceFromRoute(activityId, occurrenceId, routeId),
    onError: (err) => {
      displayErrorMessageAlert(
        `Gick inte att ta bort aktivitetstillfället från rutten. ${deducedError(
          err,
        )}`,
      );
    },
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries({ queryKey: routeKeys.all }),
        queryClient.invalidateQueries({
          queryKey: activityOccurrenceAndGroupKeys.all(),
        }),
      ]);
    },
  });

  const { mutate: removeGroupFromBlock, isPending: isRemovingGroupFromBlock } =
    useMutation({
      mutationFn: ({
        groupId,
        routeId,
      }: {
        groupId: string;
        routeId: string;
      }) => unassignGroupFromRoute(groupId, routeId),
      onError: (err) => {
        displayErrorMessageAlert(
          `Gick inte att ta bort besöket från rutten. ${deducedError(err)}`,
        );
      },
      onSuccess: async () => {
        await Promise.all([
          queryClient.invalidateQueries({ queryKey: routeKeys.all }),
          queryClient.invalidateQueries({
            queryKey: activityOccurrenceAndGroupKeys.all(),
          }),
        ]);
      },
    });

  if (isPending) {
    return <BlockChip label="Laddar..." />;
  }

  if (isError) {
    Sentry.captureException(error);
    return (
      <ErrorMessage
        message={`Kunde inte hämta rutterna. ${deducedError(error)}`}
      />
    );
  }

  const assignedBlock =
    "groupId" in props
      ? getRouteFromGroup(routes, props.groupId)
      : getRouteFromActivityOccurrence(
          routes,
          props.activityId,
          props.activityOccurrenceId,
        );

  const isAssignedToBlock = assignedBlock !== undefined;

  const isEditable =
    !pastDateSelected &&
    (assignedBlock === undefined || assignedBlock.status === "draft");

  if (!isEditable && !isAssignedToBlock) {
    return null;
  }

  if (!isEditable && isAssignedToBlock) {
    return <BlockChip label={assignedBlock.name} />;
  }

  if (isAssignedToBlock) {
    return (
      <BlockChip
        label={assignedBlock.name}
        onRemove={() =>
          "groupId" in props
            ? removeGroupFromBlock({
                groupId: props.groupId,
                routeId: assignedBlock.id,
              })
            : removeOccurrenceFromBlock({
                activityId: props.activityId,
                occurrenceId: props.activityOccurrenceId,
                routeId: assignedBlock.id,
              })
        }
        disabled={
          "groupId" in props
            ? isRemovingGroupFromBlock
            : isRemovingOccurrenceFromBlock
        }
      />
    );
  }

  // If block is not assigned, show dropdown menu with available blocks and possibility to create new block
  const blocksNotFinished = routes.filter(
    (block) => block.status !== routeStatusSchema.Values.finished,
  );

  const ListOfBlocks = () => (
    <ul className={styles.blockList}>
      {blocksNotFinished.length > 0 ? (
        blocksNotFinished.map((block) => (
          <li key={block.id}>
            <button
              className={styles.blockListButton}
              disabled={
                "groupId" in props
                  ? isPlacingGroupInBlock
                  : isPlacingOccurrenceInBlock
              }
              onClick={() =>
                "groupId" in props
                  ? placeGroupInBlock({
                      groupId: props.groupId,
                      routeId: block.id,
                    })
                  : placeOccurrenceInBlock({
                      activityId: props.activityId,
                      occurrenceId: props.activityOccurrenceId,
                      routeId: block.id,
                    })
              }
            >
              <BlockChip label={block.name} />
            </button>
          </li>
        ))
      ) : (
        <NoResults message="Inga rutter tillgängliga" />
      )}
    </ul>
  );

  const NewBlockError = () => {
    Sentry.captureException(createNewBlockError);
    return (
      <ErrorMessage
        message={`Gick inte att skapa rutt. ${deducedError(createNewBlockError)}`}
      />
    );
  };

  const CreateNewBlock = () => (
    <div className={styles.createNewBlock}>
      <form
        onSubmit={handleCreateNewBlock}
        className={styles.createNewBlockForm}
      >
        <InputField
          label="Lägg till i ny rutt"
          formatHint="Namn på rutt"
          errorMessage={errors.blockName?.message}
          {...register("blockName", {
            required: {
              value: true,
              message: "Ange ett namn på rutten",
            },
            maxLength: {
              value: 20,
              message: "Namnet får vara max 20 tecken långt",
            },
          })}
        />
        <div className={styles.submitButton}>
          {creatingNewBlockFailed ? <NewBlockError /> : null}
          <PlainButton
            type="submit"
            disabled={
              isCreatingNewBlock || "groupId" in props
                ? isPlacingGroupInBlock
                : isPlacingOccurrenceInBlock
            }
          >
            Lägg till
          </PlainButton>
        </div>
      </form>
    </div>
  );

  return (
    <Popover
      trigger={{
        custom: <SelectBlockButton />,
      }}
    >
      <LoadingOverlay
        show={
          isCreatingNewBlock || "groupId" in props
            ? isPlacingGroupInBlock
            : isPlacingOccurrenceInBlock
        }
        message="Lägger till i rutt"
      >
        <>
          <ListOfBlocks />
          {!pastDateSelected ? <CreateNewBlock /> : <></>}
        </>
      </LoadingOverlay>
    </Popover>
  );
};
