import {
  removeRoute,
  routeKeys,
  updateActivityOccurrencesOrderInRoute,
} from "@/api/Routes";
import { IVisit } from "@models/visits";
import { Time } from "@/components/Time/Time";
import { formattedTimeSpan } from "@/components/Time/timeUtils";
import { Heading } from "@components/Heading/Heading";
import { Visit } from "./Visit";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deducedError, displayErrorMessageAlert } from "@/Utils/ErrorUtils";
import styles from "./WorkBlock.module.scss";
import { PlainButton } from "@components/Button/Button";
import BinIcon from "@components/icons/BinIcon";
import { determineTimespan } from "@/pages/commandcenter/Activities/WorkBlocks/helpers";
import * as Sentry from "@sentry/react";
import { timeOfDayDictionary } from "@models/activities";

type IWorkBlock = {
  block: {
    id: string;
    name: string;
    timespan?:
      | {
          from: Date;
          to: Date;
        }
      | null
      | undefined;
    visits: IVisit[];
  };
  isEditable?: boolean;
};

function moveItem<T>(array: T[], fromIndex: number, toIndex: number) {
  const tempArray = [...array];
  const from = tempArray.splice(fromIndex, 1)[0];
  if (!from) {
    Sentry.captureException(new Error("Could not find item to move in array"));
    return array;
  }
  tempArray.splice(toIndex, 0, from);
  return tempArray;
}

export const WorkBlock = ({
  block: { id, name, timespan, visits },
  isEditable = false,
}: IWorkBlock) => {
  const queryClient = useQueryClient();
  const { mutate: updateOccurrenceOrderMutation, isPending: isUpdatingOrder } =
    useMutation({
      mutationFn: ({
        visitId,
        direction,
      }: {
        visitId: string;
        direction: "up" | "down";
      }) => {
        let orderedVisitIds = visits.map(({ id }) => id);
        const occurrenceIndex = orderedVisitIds.findIndex(
          (id) => id === visitId,
        );
        if (direction === "up") {
          orderedVisitIds = moveItem(
            orderedVisitIds,
            occurrenceIndex,
            occurrenceIndex - 1,
          );
        } else {
          orderedVisitIds = moveItem(
            orderedVisitIds,
            occurrenceIndex,
            occurrenceIndex + 1,
          );
        }
        return updateActivityOccurrencesOrderInRoute(orderedVisitIds, id);
      },
      onError: (error) => {
        displayErrorMessageAlert(
          `Gick inte att flytta besöket. ${deducedError(error)}`,
        );
      },
      onSuccess: () => {
        return queryClient.invalidateQueries({ queryKey: routeKeys.all });
      },
    });

  const { mutate: removeBlockMutation, isPending: isRemovingBlock } =
    useMutation({
      mutationFn: () => removeRoute(id),
      onError: (error) => {
        displayErrorMessageAlert(
          `Gick inte att ta bort rutten. ${deducedError(error)}`,
        );
      },
      onSuccess: () => {
        return queryClient.invalidateQueries({ queryKey: routeKeys.all });
      },
    });

  return (
    <div className={styles.workBlock}>
      <div className={styles.headingRow}>
        <Heading level="h3">{name}</Heading>
        <div>
          <span className={styles.visitsCounter}>{visits.length} besök, </span>
          {timespan ? (
            <Time>
              {determineTimespan(timespan) === "Any"
                ? timeOfDayDictionary.Any.short
                : formattedTimeSpan(timespan.from, timespan.to)}
            </Time>
          ) : null}
        </div>
      </div>
      {visits.length === 0 ? (
        <>
          <p className={styles.noVisitsText}>
            Det finns inga besök i denna rutt.
          </p>
          <PlainButton
            size="small"
            weight="light"
            disabled={isRemovingBlock}
            onClick={() => removeBlockMutation()}
          >
            <BinIcon /> Radera rutt
          </PlainButton>
        </>
      ) : (
        <ul aria-label={`${name}: Besökslista`} className={styles.visitsList}>
          {visits.map((visit, index) => (
            <Visit
              key={visit.id}
              visit={visit}
              position={
                index === 0 && index === visits.length - 1
                  ? "only"
                  : index === 0
                    ? "first"
                    : index === visits.length - 1
                      ? "last"
                      : "other"
              }
              isUpdatingOrder={isUpdatingOrder}
              moveUp={() =>
                updateOccurrenceOrderMutation({
                  visitId: visit.id,
                  direction: "up",
                })
              }
              moveDown={() =>
                updateOccurrenceOrderMutation({
                  visitId: visit.id,
                  direction: "down",
                })
              }
              routeId={id}
              isEditable={isEditable}
            />
          ))}
        </ul>
      )}
    </div>
  );
};
