import { deducedError } from "@/Utils/ErrorUtils";
import { IActivityRequirement } from "@/api/Activities";
import { useScheduledShifts } from "@/api/Shifts";
import DropdownMenu from "@/components/DropdownMenu/DropdownMenu";
import NoResults from "@/components/NoResults/NoResults";
import ErrorMessage from "@components/ErrorMessage/ErrorMessage";
import { Loading } from "@components/Loading/Loading";
import AddPersonIcon from "@components/icons/AddPersonIcon";
import {
  IMedicalCompetence,
  IScheduledShift,
  calculateAvailableShifts,
  medicalCompetenceDictionary,
  shiftName,
  shiftTimeDisplayValues,
} from "@models/shifts";
import { useSelectedDate } from "@/Utils/useSelectedDate";
import styles from "./ShiftDropdownMenu.module.scss";
import * as Sentry from "@sentry/react";

const Shift = ({
  employee,
  competence,
  startDateTime,
  endDateTime,
}: Pick<
  IScheduledShift,
  "employee" | "competence" | "startDateTime" | "endDateTime"
>) => {
  const selectedDate = new Date(useSelectedDate());
  const {
    startedDayBefore,
    hasEndTimeAfterToday,
    formattedDayBefore,
    formattedDayToday,
  } = shiftTimeDisplayValues({
    selectedDate,
    startDateTime,
    endDateTime,
  });

  return (
    <span className={styles.shift}>
      <span>{`${shiftName({
        employee,
        competence,
        options: { length: "long" },
      })}`}</span>
      <span className={styles.shiftTime}>
        {startedDayBefore ? (
          <span className={styles.startedDayBefore}>
            Start {formattedDayBefore}{" "}
          </span>
        ) : hasEndTimeAfterToday ? (
          <span className={styles.startedDayBefore}>
            Start {formattedDayToday}{" "}
          </span>
        ) : (
          <></>
        )}
        {`${startDateTime.getHours()}-${endDateTime.getHours()}`}
      </span>
    </span>
  );
};

export const ShiftDropdownMenu = ({
  assignedShiftIds,
  unfulfilledRequirements,
  onAllocate,
  isAllocating = false,
}: {
  assignedShiftIds: number[];
  unfulfilledRequirements: IActivityRequirement[];
  onAllocate(shiftIdToAdd: number): void;
  isAllocating?: boolean;
}) => {
  const selectedDate = useSelectedDate();

  const {
    data: scheduledShifts,
    isPending,
    isError,
    error,
  } = useScheduledShifts(selectedDate, selectedDate);

  const availableShifts = calculateAvailableShifts({
    scheduledShifts: scheduledShifts ?? [],
    assignedShiftIds,
  });

  const unfulfilledMedicalRequirements = unfulfilledRequirements.filter(
    (unfulfilledRequirement) => unfulfilledRequirement !== "DoubleStaffing",
  );

  const otherShifts = availableShifts.filter(
    ({ competence }) => !unfulfilledRequirements.includes(competence),
  );

  const ErrorDropdownItem = () => {
    Sentry.captureException(error);
    return (
      <DropdownMenu.Item
        content={
          <ErrorMessage
            message={`Gick inte att hämta skiften. ${deducedError(error)}`}
          />
        }
      />
    );
  };

  return (
    <DropdownMenu
      trigger={{
        icon: <AddPersonIcon />,
        ariaLabel: "Lägg till personal",
        disabled: isAllocating,
      }}
    >
      {isPending ? (
        <DropdownMenu.Item content={<Loading message="Laddar arbetspass" />} />
      ) : isError ? (
        <ErrorDropdownItem />
      ) : availableShifts.length === 0 ? (
        <DropdownMenu.Item
          content={<NoResults message={`Ingen tillgänglig personal`} />}
        />
      ) : (
        <>
          {unfulfilledMedicalRequirements.map((unfulfilledRequirement) => {
            const shiftsMatchingUnfulfilledMedicalRequirement =
              availableShifts.filter(
                ({ competence }) => competence === unfulfilledRequirement,
              );
            return (
              <DropdownMenu.Group key={unfulfilledRequirement}>
                <DropdownMenu.Label>
                  {`${
                    // `filter` ensures `as` is safe
                    medicalCompetenceDictionary[
                      unfulfilledRequirement as IMedicalCompetence
                    ].sv.long
                  } (${shiftsMatchingUnfulfilledMedicalRequirement.length})`}
                </DropdownMenu.Label>
                <>
                  {shiftsMatchingUnfulfilledMedicalRequirement.map(
                    ({
                      id,
                      employee,
                      startDateTime,
                      endDateTime,
                      competence,
                    }) => {
                      return (
                        <DropdownMenu.Item
                          action={() => {
                            onAllocate(id);
                          }}
                          content={
                            <Shift
                              competence={competence}
                              endDateTime={endDateTime}
                              startDateTime={startDateTime}
                              employee={employee}
                            />
                          }
                          key={id}
                        />
                      );
                    },
                  )}
                </>
              </DropdownMenu.Group>
            );
          })}

          {otherShifts.length >= 1 ? (
            <DropdownMenu.Group>
              <DropdownMenu.Label>Övriga</DropdownMenu.Label>
              <>
                {otherShifts.map(
                  ({
                    id,
                    employee,
                    startDateTime,
                    endDateTime,
                    competence,
                  }) => (
                    <DropdownMenu.Item
                      action={() => {
                        onAllocate(id);
                      }}
                      content={
                        <Shift
                          competence={competence}
                          endDateTime={endDateTime}
                          startDateTime={startDateTime}
                          employee={employee}
                        />
                      }
                      key={id}
                    />
                  ),
                )}
              </>
            </DropdownMenu.Group>
          ) : (
            <></>
          )}
        </>
      )}
    </DropdownMenu>
  );
};
