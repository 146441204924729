import { MatrixClient } from "matrix-js-sdk";
import { useQuery } from "@tanstack/react-query";
import { useCallback } from "react";
import * as Sentry from "@sentry/react";

export const useMatrixRoom = (
  matrixClient: MatrixClient | null,
  roomAlias: string | null,
) => {
  // Default to null so that `room` is not `undefined` for `Room` typing reasons
  const { data: roomId = null } = useQuery({
    queryKey: ["roomId", !!matrixClient, roomAlias],
    queryFn: () => {
      // React query does not appreciate `undefined`, so return `null` instead
      if (!matrixClient || !roomAlias) return null;
      return matrixClient
        .getRoomIdForAlias(roomAlias)
        .then((room) => room.room_id);
    },
    // Avoid spamming the server with requests for essentially stale data
    staleTime: Infinity,
  });

  // useCallback required since this function is a dependency in some places.
  const getRoom = useCallback(() => {
    if (!matrixClient || !roomAlias || !roomId) {
      return null;
    }
    return matrixClient.getRoom(roomId);
  }, [matrixClient, roomAlias, roomId]);

  return { roomId, getRoom };
};

export const useMatrixRoomAliases = (
  matrixClient: MatrixClient | null,
  roomIds: string[],
) => {
  const { data: roomAliases = [] } = useQuery({
    queryKey: ["roomAliases", !!matrixClient, ...roomIds],
    queryFn: async () => {
      if (!matrixClient) return [];
      const aliases = await Promise.all(
        roomIds.map(async (roomId) => {
          const { aliases } = await matrixClient.getLocalAliases(roomId);
          // We expect there is always exactly one alias per room
          if (aliases.length !== 1) {
            Sentry.captureException(
              new Error(`Expected exactly one alias for room ${roomId}`),
            );
            return null;
          }
          return aliases[0];
        }),
      );
      return aliases.filter((alias) => typeof alias === "string");
    },
    // Avoid spamming the server with requests for essentially stale data
    staleTime: Infinity,
  });

  return roomAliases;
};
