import z from "zod";

const recurringOptions = [
  "Aldrig",
  "Varje dag",
  "Varannan dag",
  "Var tredje dag",
  "Var femte dag",
  "Anpassad",
] as const;
export const recurringOptionSchema = z.enum(recurringOptions);
export type IRecurringOption = z.infer<typeof recurringOptionSchema>;

export const recurringDictionary: Record<
  | "never"
  | "everyDay"
  | "every2ndDay"
  | "every3rdDay"
  | "every5thDay"
  | "custom",
  IRecurringOption
> = {
  never: "Aldrig",
  everyDay: "Varje dag",
  every2ndDay: "Varannan dag",
  every3rdDay: "Var tredje dag",
  every5thDay: "Var femte dag",
  custom: "Anpassad",
};

export const recurringMapFromNumber = new Map<number, IRecurringOption>([
  [0, recurringDictionary.never],
  [1, recurringDictionary.everyDay],
  [2, recurringDictionary.every2ndDay],
  [3, recurringDictionary.every3rdDay],
  [5, recurringDictionary.every5thDay],
]);

export const recurringMapToNumber = new Map<IRecurringOption, number>([
  [recurringDictionary.never, 0],
  [recurringDictionary.everyDay, 1],
  [recurringDictionary.every2ndDay, 2],
  [recurringDictionary.every3rdDay, 3],
  [recurringDictionary.every5thDay, 5],
]);
