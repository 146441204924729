import {
  ANY_TIME_OF_DAY,
  activityOccurrenceAndGroupKeys,
  closeActivity,
  removeActivityOccurrence,
  useActivity,
  useActivityOccurrence,
} from "@/api/Activities";
import { PlainButton } from "@components/Button/Button";
import { IconButton } from "@components/Button/Button";
import { CompetenceChip } from "@/components/Chips/CompetenceChip";
import ErrorMessage from "@components/ErrorMessage/ErrorMessage";
import { Loading } from "@components/Loading/Loading";
import CrossIcon from "@components/icons/CrossIcon";
import CalendarEditIcon from "@components/icons/CalendarEditIcon";
import CalendarDeleteIcon from "@components/icons/CalendarDeleteIcon";
import RecurrenceIcon from "@components/icons/RecurrenceIcon";
import { deducedError, displayErrorMessageAlert } from "@/Utils/ErrorUtils";
import { Fragment, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { z } from "zod";
import styles from "./ActivityOccurrence.module.scss";
import { EditDescription } from "./EditDescription";
import { EditDoubleStaffing } from "./EditDoubleStaffing";
import { EditRequiredCompetences } from "./EditRequiredCompetences";
import { EditTitle } from "./EditTitle";
import { Heading } from "@components/Heading/Heading";
import { addTime } from "@/Utils/TimeUtils";
import { EditHidden } from "@/pages/commandcenter/Patients/Patient/Activities/ActivityOccurrence/EditHidden";
import { EditSchedule } from "./EditSchedule";
import { recurringMapFromNumber } from "@/forms/AddActivityForm/Scheduling/recurrenceUtils";
import { weekdayDictionaryMap } from "@/forms/AddActivityForm/Scheduling/weekdaysUtils";
import Chip from "@/components/Chips/Chip";
import { Text } from "@components/Text/Text";
import { dateName } from "@/Utils/DateUtils";
import { EditTime } from "./EditTime";
import { usePatient } from "@/api/Patients";
import { EditMeasurements } from "@/pages/commandcenter/Patients/Patient/Activities/ActivityOccurrence/EditMeasurements";
import MeasurementChip from "@/components/Chips/MeasurementChip";
import * as Sentry from "@sentry/react";
import { formattedTimeSpan } from "@/components/Time/timeUtils";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigateWithPreservedQueryParams } from "@/Utils/useNavigateWithPreservedQueryParams";
import { ActivityEndDateForm } from "@/forms/ActivityEndDateForm";
import { Dialog } from "@components/Dialog/Dialog";
import { StatusTagWithDropdown } from "@/components/StatusTagWithDropdown/StatusTagWithDropdown";
import ClockIcon from "@components/icons/ClockIcon";
import BinIcon from "@components/icons/BinIcon";
import {
  activityOccurrenceStatusSchema,
  timeOfDayDictionary,
} from "@models/activities";

const EditButton = ({
  label = "Ändra",
  iconStart,
  isEditing,
  toggleIsEditing,
}: {
  label?: string;
  iconStart?: JSX.Element;
  isEditing: boolean;
  toggleIsEditing: () => void;
}) => {
  return (
    <PlainButton size="small" weight="light" onClick={toggleIsEditing}>
      {isEditing ? (
        <>
          <CrossIcon />
          Avbryt
        </>
      ) : (
        <>
          {iconStart}
          {label}
        </>
      )}
    </PlainButton>
  );
};

const EditContainer = ({ children }: { children: JSX.Element }) => {
  return <div className={styles.editContainer}>{children}</div>;
};

const TimeSpan = ({ start, timespan }: { start: string; timespan: string }) => {
  const end = addTime(start, timespan);
  return <time>{`${start}-${end}`}</time>;
};

export const PatientName = ({ patientId }: { patientId: string }) => {
  const { data: patient, isPending, isError, error } = usePatient(patientId);

  if (isPending) {
    return <Loading message="Hämtar patientnamn" />;
  }

  if (isError) {
    Sentry.captureException(error);
    return (
      <ErrorMessage
        message={`Kunde inte hämta patientens namn. ${deducedError(error)}`}
      />
    );
  }
  return (
    <div className={styles.patientName}>
      <Heading level="h2" size="h1">
        {patient.name}
      </Heading>
    </div>
  );
};

const ActivityOccurrence = ({
  showPatientName = false,
  showCloseButton = true,
}: {
  showPatientName?: boolean;
  showCloseButton?: boolean;
}) => {
  const [activityEndDateDialogIsOpen, setActivityEndDateDialogIsOpen] =
    useState(false);

  const { activityId, occurrenceId } = z
    .object({ activityId: z.string(), occurrenceId: z.string() })
    .parse(useParams());

  const queryClient = useQueryClient();

  const navigate = useNavigate();
  const { navigateWithPreservedQueryParams } =
    useNavigateWithPreservedQueryParams();

  const { mutate: closeActivityMutation, isPending: isClosingActivity } =
    useMutation({
      mutationFn: ({ activityId }: { activityId: string }) =>
        closeActivity(activityId),
      onError: (error) => {
        displayErrorMessageAlert(
          `Gick inte att stänga aktiviteten. ${deducedError(error)}`,
        );
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: activityOccurrenceAndGroupKeys.lists(),
        });
        navigateWithPreservedQueryParams(`..`);
      },
    });

  const { data: activity, isPending, isError, error } = useActivity(activityId);
  const {
    data: occurrence,
    isPending: occurrenceIsPending,
    isError: occurrenceIsError,
    error: occurrenceError,
  } = useActivityOccurrence(activityId, occurrenceId);

  const [currentlyEditing, setCurrentlyEditing] = useState<
    | "time"
    | "title"
    | "description"
    | "schedule"
    | "requiredCompetences"
    | "doubleStaffing"
    | "hidden"
    | "measurements"
    | null
  >(null);

  if (isPending || occurrenceIsPending) {
    return <Loading message="Hämtar aktivitet" padding={24} />;
  }

  if (isError) {
    Sentry.captureException(error);
    return <ErrorMessage message={deducedError(error)} padding={24} />;
  }

  if (occurrenceIsError) {
    Sentry.captureException(occurrenceError);
    return (
      <ErrorMessage message={deducedError(occurrenceError)} padding={24} />
    );
  }

  const Schedule = () => {
    const schedule = activity.schedule;
    const hasIntervalRecurrence = "interval" in schedule;
    const hasWeekdaysRecurrence = "days" in schedule;

    const Frequency = () => {
      if (hasIntervalRecurrence) {
        return (
          <p>
            {recurringMapFromNumber.get(schedule.interval) ??
              `${schedule.interval} dagars intervall`}
          </p>
        );
      } else if (hasWeekdaysRecurrence) {
        return (
          <p>
            {schedule.days
              .map((day) => weekdayDictionaryMap.get(day))
              .join(", ")}
          </p>
        );
      } else {
        return <></>;
      }
    };

    const Times = () => {
      if ("times" in schedule) {
        if (schedule.times === ANY_TIME_OF_DAY) {
          return <p>{timeOfDayDictionary.Any.long}</p>;
        } else {
          return (
            <p>
              {schedule.times.map((time, i) => {
                const divider = i === schedule.times.length - 1 ? "" : ", ";
                return (
                  <Fragment key={time}>
                    <TimeSpan start={time} timespan={schedule.span} />
                    {divider}
                  </Fragment>
                );
              })}
            </p>
          );
        }
      } else {
        <></>;
      }
    };

    return (
      <section className={styles.informationSection}>
        <div className={styles.editableSection}>
          <Heading level="h3">Återkommer</Heading>
          <EditButton
            isEditing={currentlyEditing === "schedule"}
            toggleIsEditing={() =>
              setCurrentlyEditing(
                currentlyEditing === "schedule" ? null : "schedule",
              )
            }
          />
        </div>
        {currentlyEditing === "schedule" ? (
          <EditContainer>
            <EditSchedule
              activityId={activity.id}
              schedule={schedule}
              onSuccess={() => {
                navigate("..");
              }}
            />
          </EditContainer>
        ) : (
          <div>
            <Frequency />
            <Times />
          </div>
        )}
      </section>
    );
  };

  const ActivityOccurrenceHeader = () => {
    const {
      mutate: removeActivityOccurrenceMutation,
      isPending: isClosingActivityOccurrence,
    } = useMutation({
      mutationFn: ({
        activityId,
        occurrenceId,
      }: {
        activityId: string;
        occurrenceId: string;
      }) => removeActivityOccurrence(activityId, occurrenceId),
      onError: (error) => {
        displayErrorMessageAlert(
          `Gick inte att ta bort aktivitetstillfället. ${deducedError(error)}`,
        );
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: activityOccurrenceAndGroupKeys.lists(),
        });
        navigateWithPreservedQueryParams(`..`);
      },
    });

    return (
      <div className={styles.topRow}>
        <div className={styles.highlightedInfo}>
          {showPatientName &&
          // TypeScript is too stupid to extract this into variable
          activity.patientId !== null &&
          activity.patientId !== undefined &&
          activity.patientId !== "" ? (
            <PatientName patientId={activity.patientId} />
          ) : null}
          <Heading level="h2">{activity.title}</Heading>
          <Text element="span" size="large">
            {dateName(occurrence.start)},{" "}
            {occurrence.timeOfDay === "Any"
              ? timeOfDayDictionary.Any.long
              : formattedTimeSpan(occurrence.start, occurrence.end)}
          </Text>
          <div className={styles.statusAndActionButtons}>
            <StatusTagWithDropdown
              status={occurrence.status}
              activityId={occurrence.activityId}
              occurrenceId={occurrence.id}
              category={occurrence.category}
              variant="icon-and-text"
            />
            {occurrence.status !==
              activityOccurrenceStatusSchema.Values.finished &&
            occurrence.status !==
              activityOccurrenceStatusSchema.Values.ongoing ? (
              <EditButton
                label="Ändra tiden"
                iconStart={<ClockIcon />}
                isEditing={currentlyEditing === "time"}
                toggleIsEditing={() =>
                  setCurrentlyEditing(
                    currentlyEditing === "time" ? null : "time",
                  )
                }
              />
            ) : null}
            {activity.recurring ? (
              <>
                <PlainButton
                  size="small"
                  weight="light"
                  onClick={() =>
                    removeActivityOccurrenceMutation({
                      activityId,
                      occurrenceId,
                    })
                  }
                  disabled={isClosingActivityOccurrence}
                >
                  <BinIcon />
                  Ta bort
                </PlainButton>
              </>
            ) : (
              <>
                <PlainButton
                  size="small"
                  weight="light"
                  onClick={() => closeActivityMutation({ activityId })}
                  disabled={isClosingActivity}
                >
                  <BinIcon />
                  Ta bort
                </PlainButton>
              </>
            )}
          </div>
          {currentlyEditing === "time" ? (
            <EditContainer>
              <EditTime
                currentDateTime={occurrence.start}
                currentTimeOfDay={occurrence.timeOfDay}
                onSuccess={() => {
                  setCurrentlyEditing(null);
                }}
                activityId={activityId}
                occurrenceId={occurrenceId}
              />
            </EditContainer>
          ) : null}
        </div>
        {showCloseButton ? (
          <IconButton
            aria-label="Stäng formuläret"
            onClick={() => navigate("..")}
          >
            <CrossIcon />
          </IconButton>
        ) : null}
      </div>
    );
  };

  return (
    <>
      <article>
        <ActivityOccurrenceHeader />

        <div className={styles.activityInfo}>
          {activity.recurring ? (
            <div className={styles.activityInfoHeader}>
              <Chip iconStart={<RecurrenceIcon />}>Återkommande aktivitet</Chip>
              <PlainButton
                size="small"
                weight="light"
                onClick={() => setActivityEndDateDialogIsOpen(true)}
              >
                <CalendarEditIcon />
                Ändra slutdatum
              </PlainButton>
              <PlainButton
                size="small"
                weight="light"
                onClick={() => closeActivityMutation({ activityId })}
                disabled={isClosingActivity}
              >
                <CalendarDeleteIcon />
                Avsluta nu
              </PlainButton>
            </div>
          ) : undefined}

          <section className={styles.informationSection}>
            <div className={styles.editableSection}>
              <Heading level="h3">Namn</Heading>
              <EditButton
                isEditing={currentlyEditing === "title"}
                toggleIsEditing={() =>
                  setCurrentlyEditing(
                    currentlyEditing === "title" ? null : "title",
                  )
                }
              />
            </div>
            {currentlyEditing === "title" ? (
              <EditContainer>
                <EditTitle
                  currentTitle={activity.title}
                  onSuccess={() => setCurrentlyEditing(null)}
                  activityId={activity.id}
                />
              </EditContainer>
            ) : (
              <p>{activity.title}</p>
            )}
          </section>
          <section className={styles.informationSection}>
            <div className={styles.editableSection}>
              <Heading level="h3">Beskrivning</Heading>
              <EditButton
                isEditing={currentlyEditing === "description"}
                toggleIsEditing={() =>
                  setCurrentlyEditing(
                    currentlyEditing === "description" ? null : "description",
                  )
                }
              />
            </div>
            {currentlyEditing === "description" ? (
              <EditContainer>
                <EditDescription
                  currentDescription={activity.description}
                  onSuccess={() => setCurrentlyEditing(null)}
                  activityId={activity.id}
                />
              </EditContainer>
            ) : (
              <p>{activity.description}</p>
            )}
          </section>
          {activity.category === "PatientMeasurementTask" ? (
            <section className={styles.informationSection}>
              <div className={styles.editableSection}>
                <Heading level="h3">Mätvärden</Heading>
                <EditButton
                  isEditing={currentlyEditing === "measurements"}
                  toggleIsEditing={() =>
                    setCurrentlyEditing(
                      currentlyEditing === "measurements"
                        ? null
                        : "measurements",
                    )
                  }
                />
              </div>
              {currentlyEditing === "measurements" ? (
                <EditContainer>
                  <EditMeasurements
                    currentMeasurements={activity.measurements}
                    onSuccess={() => setCurrentlyEditing(null)}
                    activityId={activity.id}
                  />
                </EditContainer>
              ) : (
                <ul className={styles.chips}>
                  {activity.measurements.map((measurement) => (
                    <li key={measurement}>
                      <MeasurementChip measurement={measurement} />
                    </li>
                  ))}
                </ul>
              )}
            </section>
          ) : null}
          {activity.recurring ? <Schedule /> : null}
          {activity.category !== "AdminTask" ? (
            <section className={styles.informationSection}>
              <div className={styles.editableSection}>
                <Heading level="h3">Dold för patienten</Heading>
                <EditButton
                  isEditing={currentlyEditing === "hidden"}
                  toggleIsEditing={() =>
                    setCurrentlyEditing(
                      currentlyEditing === "hidden" ? null : "hidden",
                    )
                  }
                />
              </div>
              {currentlyEditing === "hidden" ? (
                <EditContainer>
                  <EditHidden
                    currentHidden={activity.hidden}
                    onSuccess={() => setCurrentlyEditing(null)}
                    activityId={activity.id}
                  />
                </EditContainer>
              ) : (
                <p>{activity.hidden ? "Ja" : "Nej"}</p>
              )}
            </section>
          ) : null}
          {activity.category === "VideoCall" ||
          activity.category === "HomeVisit" ||
          activity.category === "AdminTask" ? (
            <section className={styles.informationSection}>
              <div className={styles.editableSection}>
                <Heading level="h3">Kompetenskrav</Heading>
                <EditButton
                  isEditing={currentlyEditing === "requiredCompetences"}
                  toggleIsEditing={() =>
                    setCurrentlyEditing(
                      currentlyEditing === "requiredCompetences"
                        ? null
                        : "requiredCompetences",
                    )
                  }
                />
              </div>
              {currentlyEditing === "requiredCompetences" ? (
                <EditContainer>
                  <EditRequiredCompetences
                    currentRequiredCompetences={activity.requiredCompetences}
                    onSuccess={() => setCurrentlyEditing(null)}
                    activityId={activity.id}
                  />
                </EditContainer>
              ) : (
                <ul className={styles.chips}>
                  {activity.requiredCompetences.map((competence) => (
                    <li key={competence}>
                      <CompetenceChip competence={competence} state="neutral" />
                    </li>
                  ))}
                </ul>
              )}
            </section>
          ) : null}
          {activity.category === "HomeVisit" ? (
            <section className={styles.informationSection}>
              <div className={styles.editableSection}>
                <Heading level="h3">Dubbelbemanning</Heading>
                <EditButton
                  isEditing={currentlyEditing === "doubleStaffing"}
                  toggleIsEditing={() =>
                    setCurrentlyEditing(
                      currentlyEditing === "doubleStaffing"
                        ? null
                        : "doubleStaffing",
                    )
                  }
                />
              </div>
              {currentlyEditing === "doubleStaffing" ? (
                <EditContainer>
                  <EditDoubleStaffing
                    currentDoubleStaffing={activity.doubleStaffing}
                    onSuccess={() => setCurrentlyEditing(null)}
                    activityId={activity.id}
                  />
                </EditContainer>
              ) : (
                <p>{activity.doubleStaffing ? "Ja" : "Nej"}</p>
              )}
            </section>
          ) : null}
        </div>
      </article>
      <Dialog
        isOpen={activityEndDateDialogIsOpen}
        onClose={() => setActivityEndDateDialogIsOpen(false)}
        title="Ändra slutdatum för aktivitetsserien"
      >
        {activityEndDateDialogIsOpen ? (
          <ActivityEndDateForm
            activityId={activityId}
            onSubmitSuccess={() => setActivityEndDateDialogIsOpen(false)}
          />
        ) : (
          <></>
        )}
      </Dialog>
    </>
  );
};

export default ActivityOccurrence;
