import { usePatient } from "@/api/Patients";
import { useVideoInfo } from "@/api/Video";
import ErrorMessage from "@components/ErrorMessage/ErrorMessage";
import { Loading } from "@components/Loading/Loading";
import { JitsiVideo } from "@/components/Video/JitsiVideo";
import { CompodiumVideo } from "@/components/Video/CompodiumVideo";
import { deducedError } from "@/Utils/ErrorUtils";
import { useParams, useSearchParams } from "react-router-dom";
import { z } from "zod";
import * as Sentry from "@sentry/react";
import { Suspense, lazy } from "react";

// Heavy dependency, lazy load it
const AzureVideo = lazy(() => import("@/components/Video/AzureVideo"));

const CenteredOnScreen = ({ children }: { children: React.ReactNode }) => (
  <div
    style={{
      display: "grid",
      placeItems: "center",
      height: "100vh",
      width: "100%",
    }}
  >
    {children}
  </div>
);

export const VideoMeeting = () => {
  const { patientId } = z.object({ patientId: z.string() }).parse(useParams());
  const [searchParams] = useSearchParams();
  const requestedVideoService = searchParams.get("video-service");
  const videoService =
    requestedVideoService === "compodium"
      ? "Compodium"
      : requestedVideoService === "acs"
        ? "ACS"
        : "Jitsi";

  const {
    data: patient,
    isError: isPatientError,
    isPending: isPatientLoading,
    error: patientError,
  } = usePatient(patientId);

  const {
    data: videoInfo,
    isError: isVideoInfoError,
    isPending: isVideoInfoLoading,
    error: videoInfoError,
  } = useVideoInfo(patientId, videoService);

  if (isPatientLoading || isVideoInfoLoading) {
    return (
      <CenteredOnScreen>
        <Loading message="Startar videosamtal" />
      </CenteredOnScreen>
    );
  }

  if (isPatientError) {
    Sentry.captureException(patientError);
    return (
      <CenteredOnScreen>
        <ErrorMessage
          message={`Gick inte att hitta rätt patient. ${deducedError(patientError)}`}
        />
      </CenteredOnScreen>
    );
  }

  if (isVideoInfoError) {
    Sentry.captureException(videoInfoError);
    return (
      <CenteredOnScreen>
        <ErrorMessage
          message={`Gick inte att få tillgång till videomötet. ${deducedError(videoInfoError)}`}
        />
      </CenteredOnScreen>
    );
  }

  if (!videoInfo) {
    return (
      <CenteredOnScreen>
        <ErrorMessage
          message={`Det blev ett fel vid inhämtning av videosamtalsdetaljer.`}
        />
      </CenteredOnScreen>
    );
  }

  if (videoInfo.videoService === "Jitsi") {
    return (
      <JitsiVideo
        roomName={patientId}
        // Guide: https://jitsi.github.io/handbook/docs/dev-guide/dev-guide-configuration/
        // All config options: https://github.com/jitsi/jitsi-meet/blob/master/config.js
        // All config options available to be overwritten from client: https://github.com/jitsi/jitsi-meet/blob/master/react/features/base/config/configWhitelist.ts
        configOverwrite={{
          startWithAudioMuted: false,
          startWithVideoMuted: false,
          toolbarButtons: [
            "hangup",
            "microphone",
            "camera",
            "settings",
            "tileview",
          ],
          welcomePage: {
            disabled: false,
          },
          prejoinConfig: {
            enabled: true,
          },
          filmstrip: {
            disableResizable: true,
          },
          subject: "Videosamtal med " + patient.name,
          disableRemoteMute: true, // Don't allow muting patient
        }}
        jwt={videoInfo.token}
        videoServer={videoInfo.domain}
      />
    );
  }

  if (videoInfo.videoService === "Compodium") {
    return (
      <CompodiumVideo meetingUrl={videoInfo.meetingUrl} patientId={patientId} />
    );
  }

  return (
    <Suspense
      fallback={
        <CenteredOnScreen>
          <Loading message="Startar videotjänsten" />
        </CenteredOnScreen>
      }
    >
      <AzureVideo
        patientId={patientId}
        userId={videoInfo.userId}
        token={videoInfo.token}
        displayName={"Medoma"}
      />
    </Suspense>
  );
};
