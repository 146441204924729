import { isToday, isYesterday } from "date-fns";
import {
  EventStatus,
  EventType,
  MatrixClient,
  MatrixEvent,
  MsgType,
  RoomEvent,
  TimelineWindow,
} from "matrix-js-sdk";
import { z } from "zod";
import { useEffect, useState } from "react";
import * as Sentry from "@sentry/react";
import { format } from "../models/date-and-time";

// sending: The event is in the process of being sent.
// sent: The event has been sent to the server, and echo is received.
// not-sent: The event was not sent and will no longer be retried.
const messageStatusSchema = z.enum(["sending", "sent", "not-sent"]);
export type IMessageStatus = z.infer<typeof messageStatusSchema>;

const chatMessageSchema = z.object({
  id: z.string(),
  sender: z.object({
    name: z.string().nullish(),
    imageURL: z.string().nullish(),
    isMe: z.boolean(),
    title: z.string().nullish(),
  }),
  text: z.string(),
  status: messageStatusSchema,
  epochTimestamp: z.number(),
});
export type IChatMessage = z.infer<typeof chatMessageSchema>;

// Defaults to 20, which does not scale well for large rooms.
// May be better implemented with pagination.
const MAX_MATRIX_EVENTS = 1000;
const sync = async (
  matrixClient: MatrixClient,
  roomId: string,
  onSyncComplete: (events: MatrixEvent[]) => void,
) => {
  const room = matrixClient.getRoom(roomId);
  if (!room) {
    return;
  }

  const timelineSet = room.getUnfilteredTimelineSet();
  // http://matrix-org.github.io/matrix-js-sdk/stable/classes/TimelineWindow.html
  const timelineWindow = new TimelineWindow(
    matrixClient,
    timelineSet,
    // http://matrix-org.github.io/matrix-js-sdk/stable/interfaces/_internal_.IOpts-3.html
    {
      windowLimit: MAX_MATRIX_EVENTS,
    },
  );
  // http://matrix-org.github.io/matrix-js-sdk/stable/classes/TimelineWindow.html#load
  timelineWindow.load(undefined, MAX_MATRIX_EVENTS);
  const events = timelineWindow.getEvents();
  onSyncComplete(events);
};

// Alert if message is not sent.
const syncWithErrorCheck = async (
  matrixClient: MatrixClient,
  roomId: string,
  onSyncComplete: (events: MatrixEvent[]) => void,
) => {
  const onSyncCompleteWithErrorCheck = (events: MatrixEvent[]) => {
    const lastEventNotSent = events.at(-1)?.status === EventStatus.NOT_SENT;
    if (lastEventNotSent) {
      const errorMessage =
        "Meddelandet kunde inte skickas. Försök igen, och använd andra kontaktvägar om det ändå inte fungerar.";
      Sentry.captureException(
        new Error(`An error alert was shown: ${errorMessage}`),
      );
      alert(errorMessage);
    }
    onSyncComplete(events);
  };
  sync(matrixClient, roomId, onSyncCompleteWithErrorCheck);
};

export const useMatrixTimelineEvents = (
  matrixClient: MatrixClient | null,
  roomId: string | null,
) => {
  const [events, setEvents] = useState<MatrixEvent[]>([]);

  useEffect(() => {
    if (!matrixClient || !roomId) {
      return;
    }

    const _sync = () => {
      sync(matrixClient, roomId, setEvents);
    };
    const _syncWithErrorCheck = () => {
      syncWithErrorCheck(matrixClient, roomId, setEvents);
    };

    const room = matrixClient.getRoom(roomId);
    if (!room) {
      // We are probably here because we are trying to access a room that we have already left (e.g. discharged patient from Center).
      matrixClient
        .peekInRoom(roomId)
        // All good, just sync the room
        .then(() => _sync())
        .catch(() => Sentry.captureException(new Error("Room not found.")));
      // Don't bother with event handlers since this room is stale.
      return;
    } else {
      // Run one initial sync on mount, to get initial events.
      _sync();
    }

    // Timeline has updated (i.e. new message). Perform a full sync.
    room.on(RoomEvent.Timeline, _sync);

    // Sent message has changed status. Perform a full sync.
    room.on(RoomEvent.LocalEchoUpdated, _syncWithErrorCheck);

    return () => {
      room.off(RoomEvent.Timeline, _sync);
      room.off(RoomEvent.LocalEchoUpdated, _syncWithErrorCheck);
    };
  }, [matrixClient, roomId]);

  return events;
};

const deriveEventStatus = (
  matrixEventStatus: EventStatus | null,
): IMessageStatus => {
  switch (matrixEventStatus) {
    case EventStatus.SENDING:
    case EventStatus.QUEUED:
    case EventStatus.SENT:
    case EventStatus.ENCRYPTING:
      return "sending";
    case null:
      return "sent";
    case EventStatus.NOT_SENT:
    case EventStatus.CANCELLED:
      return "not-sent";
  }
};

export const DISPLAYED_EVENT_TYPES = [EventType.RoomMessage] as const;
export const DISPLAYED_MESSAGE_TYPES = [MsgType.Text] as const;
export const displayedEvent = (event: MatrixEvent) => {
  return (
    DISPLAYED_EVENT_TYPES.some((eventType) => eventType === event.getType()) &&
    DISPLAYED_MESSAGE_TYPES.some(
      (messageType) => messageType === event.getContent().msgtype,
    )
  );
};

// Convert Matrix events to our message format.
export const makeMessages = (events: MatrixEvent[], userId: string) => {
  return events.filter(displayedEvent).map((event) => {
    return chatMessageSchema.parse({
      id: event.getId() || event.getTxnId()!,
      sender: {
        name: event.getContent().sender?.name,
        imageURL: event.getContent().sender?.imageURL,
        title: event.getContent().sender?.title,
        isMe: event.getSender() === userId,
      },
      text: event.getContent().body,
      status: deriveEventStatus(event.status),
      epochTimestamp: event.getTs(),
    });
  });
};

export const formatTimestamp = (timestamp: number) => {
  if (isToday(timestamp)) {
    return "idag " + format(timestamp, "H:mm");
  } else if (isYesterday(timestamp)) {
    return "igår " + format(timestamp, "H:mm");
  } else {
    return format(timestamp, "d MMMM H:mm");
  }
};
