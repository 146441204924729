import { Heading } from "@components/Heading/Heading";
import { IconButton } from "@components/Button/Button";
import { useNavigate } from "react-router-dom";
import styles from "./AddActivity.module.scss";
import CrossIcon from "@components/icons/CrossIcon";
import { Outlet } from "react-router-dom";

export const AddActivity = () => {
  const navigate = useNavigate();

  return (
    <section className={styles.addActivity}>
      <div className={styles.heading}>
        <Heading level="h2">Skapa ny aktivitet</Heading>
        <IconButton
          aria-label="Stäng formuläret"
          onClick={() => navigate(`..`)}
        >
          <CrossIcon />
        </IconButton>
      </div>
      <div className={styles.tabContent}>
        <Outlet />
      </div>
    </section>
  );
};
