import { deduplicatePrimitiveArray } from "@/Utils/arrayUtils";
import {
  IAdminTaskOccurrence,
  IHomeVisitActivityOccurrence,
  IVideoActivityOccurrence,
} from "@models/activities";
import { IScheduledShift } from "@models/shifts";

export const getUnfulfilledRequirements = ({
  assignees,
  occurrences,
}: {
  assignees: ReadonlyArray<Pick<IScheduledShift, "competence">>;
  occurrences: ReadonlyArray<
    | Pick<
        IHomeVisitActivityOccurrence,
        "category" | "requiredCompetences" | "doubleStaffing"
      >
    | Pick<IVideoActivityOccurrence, "category" | "requiredCompetences">
    | Pick<IAdminTaskOccurrence, "category" | "requiredCompetences">
  >;
}) => {
  const occurrencesRequirements = [
    ...deduplicatePrimitiveArray(
      occurrences.flatMap(({ requiredCompetences }) => requiredCompetences),
    ),
    ...(occurrences.some(
      (occurrence) =>
        occurrence.category === "HomeVisit" && occurrence.doubleStaffing,
    )
      ? ["DoubleStaffing" as const]
      : []),
  ];

  const assignedShiftsCompetences = assignees.map(
    ({ competence }) => competence,
  );

  return occurrencesRequirements.filter((requirement) => {
    if (requirement === "NurseAssistant") {
      return !(
        assignedShiftsCompetences.includes("NurseAssistant") ||
        assignedShiftsCompetences.includes("NursePractitioner")
      );
    }
    if (requirement === "Support") {
      return !(
        assignedShiftsCompetences.includes("Support") ||
        assignedShiftsCompetences.includes("NurseAssistant") ||
        assignedShiftsCompetences.includes("NursePractitioner") ||
        assignedShiftsCompetences.includes("MedicalDoctor") ||
        assignedShiftsCompetences.includes("Physiotherapist")
      );
    }
    if (requirement === "DoubleStaffing") {
      return assignees.length <= 1;
    }
    return !assignedShiftsCompetences.includes(requirement);
  });
};

export const determineTimespan = (timespan: { from: Date; to: Date }) =>
  timespan?.from.getHours().toString() === "0" &&
  timespan?.to.getHours().toString() === "23" &&
  timespan?.from.getMinutes().toString() === "0" &&
  timespan?.to.getMinutes().toString() === "59"
    ? "Any"
    : timespan;
