export const formattedTime = (time: Date) => {
  return Intl.DateTimeFormat("sv-SE", {
    hour: "numeric",
    minute: "numeric",
  }).format(time);
};

export const formattedTimeSpan = (from: Date, to: Date) => {
  return `${formattedTime(from)}-${formattedTime(to)}`;
};
