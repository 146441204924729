import { useEffect, useCallback } from "react";
import { useMatrixClient } from "@chat/matrixClient";
import { ClientEvent, MatrixClient } from "matrix-js-sdk";
import { getCenterJwt } from "@/api/Chat";
import { usePatients } from "@/api/Patients";
import { useChatNotifications } from "@chat/useChatNotifications";
import { useUnreadNotifications } from "@chat/useUnreadNotifications";
import { activeStatuses } from "@models/patients";
import ChatContext from "@chat/ChatContext";
import { useEmployees } from "@/api/Employee";
import { useLocation, useNavigate } from "react-router-dom";
import { useCenterBaseUrl } from "@/Utils/useCenterBaseUrl";
import { employeeName } from "@models/shifts";
import { useFeatureFlag } from "@/api/FeatureFlags";

const browserSupportsNotificationAPI = "Notification" in window;

const useDebugChat = (matrixClient: MatrixClient | null) => {
  useEffect(() => {
    if (matrixClient === null) {
      return;
    }

    if (import.meta.env.VITE_DEBUG_CHAT) {
      matrixClient.on(ClientEvent.Event, console.log);

      return () => {
        matrixClient.off(ClientEvent.Event, console.log);
      };
    }
  }, [matrixClient]);
};

export const ChatProvider = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  const { data: employeeChatNotificationsEnabled } = useFeatureFlag(
    "EmployeeDesktopChatNotifications",
  );
  const location = useLocation();
  const centerBaseUrl = useCenterBaseUrl();
  const isCommandCenter = centerBaseUrl === "/commandcenter";

  const matrixClient = useMatrixClient("Center", getCenterJwt, isCommandCenter);
  const { data: patients } = usePatients({
    statuses: activeStatuses,
  });
  const { data: employees } = useEmployees();
  const navigate = useNavigate();

  const unreadNotifications = useUnreadNotifications(matrixClient);

  useDebugChat(matrixClient);

  const getSenderDetails = useCallback(
    (senderId: string | undefined) => {
      if (!senderId) return null;

      const patientDetails = patients?.find(
        (patient) => patient.id === senderId,
      );
      if (patientDetails) {
        return { type: "patient", id: senderId };
      }
      const employeeDetails = employees?.find(
        (employee) => employee.matrixUserId === senderId,
      );
      if (employeeDetails) {
        return { type: "employee", id: employeeDetails.id.toString() };
      }
      return null;
    },
    [employees, patients],
  );

  const shouldAlert = useCallback(
    (senderId: string | undefined) => {
      if (!senderId) return false;

      const senderDetails = getSenderDetails(senderId);
      if (senderDetails === null) return false;

      const { type, id } = senderDetails;

      if (type === "patient") {
        const browserIsInFocus = document.hasFocus();
        const hasPatientsChatPanelOpen = location.pathname.endsWith(
          `${id}/communication`,
        );
        return !browserIsInFocus || !hasPatientsChatPanelOpen;
      }

      if (type === "employee") {
        const applicationIsVisible = document.visibilityState === "visible";
        // Uncomment for more frequent notifications
        // const hasEmployeesChatPanelOpen = location.pathname.endsWith(
        //   `chat/${id}`,
        // );
        return (
          employeeChatNotificationsEnabled === true &&
          // (
          !applicationIsVisible
          // || !hasEmployeesChatPanelOpen)
        );
      }

      return false;
    },
    [location.pathname, getSenderDetails, employeeChatNotificationsEnabled],
  );

  const createDesktopNotification = useCallback(
    ({
      type,
      id,
      name,
    }: {
      type: "employee" | "patient";
      id: string | number;
      name: string;
    }) => {
      const notif = new Notification(
        `Nytt meddelande från ${name} (${type === "employee" ? "kollega" : "patient"})`,
        {
          body: "Tryck för att öppna chatten",
          data: { id },
        },
      );
      notif.onclick = function () {
        // this.data as { sender: IPatient };
        navigate(
          `${centerBaseUrl}/${type === "employee" ? `chat/${id}` : `patients/${id}/communication`}`,
        );
        window.focus();
      };
    },
    [centerBaseUrl, navigate],
  );

  const onAlert = useCallback(
    (senderId: string | undefined) => {
      if (!browserSupportsNotificationAPI) return;
      if (Notification.permission !== "granted") return;

      const patientSender = patients?.find(
        (patient) => patient.id === senderId,
      );
      if (patientSender) {
        createDesktopNotification({
          type: "patient",
          id: patientSender.id,
          name: patientSender.name,
        });
      }
      const employeeSender = employees?.find(
        (employee) => employee.matrixUserId === senderId,
      );
      if (employeeSender) {
        createDesktopNotification({
          type: "employee",
          id: employeeSender.id,
          name: employeeName(employeeSender),
        });
      }
    },
    [employees, patients, createDesktopNotification],
  );

  useChatNotifications(matrixClient, shouldAlert, onAlert);

  return (
    <ChatContext.Provider
      value={{
        matrixClient,
        unreadNotifications,
        chatEnabled: isCommandCenter,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};
