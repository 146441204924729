import { addRelative, patientKeys } from "@/api/Patients";
import Form from "@/components/Form/Form";
import InputField from "@/components/InputField/InputField";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deducedError } from "@/Utils/ErrorUtils";
import { Loading } from "@components/Loading/Loading";
import ErrorMessage from "@components/ErrorMessage/ErrorMessage";
import Checkbox from "@/components/Checkbox/Checkbox";
import { generateRandomUUID } from "@/Utils/UniqueId";
import { FilledButton } from "@components/Button/Button";
import { IRelative } from "@models/patients";
import { standardizePhoneNumber } from "@models/phoneNumbers";

export const AddRelative = ({
  onSuccess,
  patientId,
}: {
  onSuccess: () => void;
  patientId: string;
}) => {
  const {
    formState: { errors, isDirty },
    getValues,
    handleSubmit,
    register,
    reset,
    setError,
  } = useForm<IRelative>();

  const queryClient = useQueryClient();
  const { mutate, isPending, isSuccess } = useMutation({
    mutationFn: (relative: IRelative) => addRelative(patientId, relative),
    onError: (error) => {
      setError("root.server", {
        message: deducedError(error),
      });
      // Reset `isDirty` to support only showing server error when the form is not changed.
      reset(getValues(), {
        keepErrors: true,
        keepIsSubmitted: true,
        keepTouched: true,
        keepIsValid: true,
        keepSubmitCount: true,
      });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: patientKeys.detail(patientId),
      });
      onSuccess();
    },
  });

  const validateAndSubmit = handleSubmit((formData) => {
    const relative: IRelative = {
      ...formData,
      id: generateRandomUUID(),
      phoneNumber: standardizePhoneNumber(formData.phoneNumber),
    };
    mutate(relative);
  });

  return (
    <Form onSubmit={validateAndSubmit}>
      {isPending || isSuccess ? (
        <Loading message="Lägger till anhörig" />
      ) : (
        <>
          {errors.root?.server?.message && !isDirty ? (
            <ErrorMessage message={errors.root.server.message} />
          ) : null}
          <Form.Row>
            <InputField
              label="Namn"
              errorMessage={errors.name?.message}
              {...register("name", {
                required: {
                  value: true,
                  message: "Anhörig behöver namn.",
                },
              })}
            />
            <InputField
              label="Telefonnummer"
              errorMessage={errors.phoneNumber?.message}
              {...register("phoneNumber", {
                required: {
                  value: true,
                  message: "Anhörig behöver telefonnummer.",
                },
                pattern: {
                  value: /^([0-9]|\s)+$/,
                  message: "Telefonnumret får bara innehålla siffror.",
                },
              })}
            />
            <InputField
              label="Relation"
              errorMessage={errors.relation?.message}
              {...register("relation", {
                required: {
                  value: true,
                  message: "Anhörig behöver relation.",
                },
              })}
            />
            <Checkbox
              label={{ text: "Sammanboende" }}
              errorMessage={errors.isLivingTogether?.message}
              {...register("isLivingTogether")}
            />
          </Form.Row>
          <FilledButton type="submit">Spara anhörig</FilledButton>
        </>
      )}
    </Form>
  );
};
