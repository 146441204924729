import { useMutation, useQueryClient } from "@tanstack/react-query";
import { activityOccurrenceAndGroupKeys, updateHidden } from "@/api/Activities";
import {
  IActivityCategory,
  IActivityOccurrenceStatus,
  IHomeVisitActivityOccurrenceWithoutPatientAndAssignees,
  timeOfDayDictionary,
} from "@models/activities";
import {
  moveToNewVisitInRoute,
  routeKeys,
  unassignActivityOccurrenceFromRoute,
} from "@/api/Routes";
import DropdownMenu from "@/components/DropdownMenu/DropdownMenu";
import { Time } from "@/components/Time/Time";
import { formattedTimeSpan } from "@/components/Time/timeUtils";
import ThreeDotsIcon from "@components/icons/ThreeDots";
import styles from "./ActivityOccurrence.module.scss";
import { deducedError, displayErrorMessageAlert } from "@/Utils/ErrorUtils";
import { ActivityTitle } from "@/components/ActivityTitle/ActivityTitle";
import { useFeatureFlag } from "@/api/FeatureFlags";

const ActionDropdownMenu = ({
  activityId,
  canMoveOccurrenceToNewVisit,
  routeId,
  occurrenceId,
  hidden,
  category,
  title,
}: {
  activityId: string;
  canMoveOccurrenceToNewVisit: boolean;
  routeId: string;
  occurrenceId: string;
  hidden: boolean;
  category: IActivityCategory;
  title: string;
}) => {
  const queryClient = useQueryClient();
  const { mutate: unassignFromRouteMutation, isPending: isUnassigning } =
    useMutation({
      mutationFn: ({
        activityId,
        occurrenceId,
      }: {
        activityId: string;
        occurrenceId: string;
      }) =>
        unassignActivityOccurrenceFromRoute(activityId, occurrenceId, routeId),
      onError: (error) => {
        displayErrorMessageAlert(
          `Gick inte att ta bort aktivitetstillfället från besöket. ${deducedError(
            error,
          )}`,
        );
      },
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: activityOccurrenceAndGroupKeys.list({}),
        });
        return queryClient.invalidateQueries({ queryKey: routeKeys.all });
      },
    });
  const { mutate: moveToNewVisitInRouteMutation, isPending: isMoving } =
    useMutation({
      mutationFn: ({
        activityId,
        occurrenceId,
      }: {
        activityId: string;
        occurrenceId: string;
      }) => moveToNewVisitInRoute(activityId, occurrenceId, routeId),
      onError: (error) => {
        displayErrorMessageAlert(
          `Gick inte att flytta aktiviteten till nytt besök. ${deducedError(
            error,
          )}`,
        );
      },
      onSuccess: () => {
        return queryClient.invalidateQueries({ queryKey: routeKeys.all });
      },
    });
  const { mutate: showActivityMutation, isPending: isShowingActivity } =
    useMutation({
      mutationFn: ({ activityId }: { activityId: string }) =>
        updateHidden(activityId, false),
      onError: (error) => {
        displayErrorMessageAlert(
          `Gick inte att visa aktivitet för patient ${deducedError(error)}`,
        );
      },
      onSuccess: () => {
        return queryClient.invalidateQueries({ queryKey: routeKeys.all });
      },
    });

  const actionInProgress = isUnassigning || isMoving || isShowingActivity;

  return (
    <DropdownMenu
      trigger={{
        icon: <ThreeDotsIcon />,
        ariaLabel: "Alternativ",
      }}
    >
      <DropdownMenu.Label>{title}</DropdownMenu.Label>
      <DropdownMenu.Item
        action={() =>
          moveToNewVisitInRouteMutation({ activityId, occurrenceId })
        }
        content="Lägg i nytt besök"
        disabled={!canMoveOccurrenceToNewVisit || actionInProgress}
      />
      <DropdownMenu.Item
        action={() => unassignFromRouteMutation({ activityId, occurrenceId })}
        content="Ta bort från rutten"
        disabled={actionInProgress}
      />
      {hidden && category !== "AdminTask" ? (
        <DropdownMenu.Item
          action={() => showActivityMutation({ activityId })}
          content="Visa för patient"
          disabled={actionInProgress}
        />
      ) : (
        <></>
      )}
    </DropdownMenu>
  );
};

export const ActivityOccurrence = ({
  activityOccurrence,
  onlyActivityOccurrenceInVisit,
  routeId,
  isEditable = false,
  status,
}: {
  activityOccurrence: IHomeVisitActivityOccurrenceWithoutPatientAndAssignees;
  onlyActivityOccurrenceInVisit: boolean;
  routeId: string;
  isEditable?: boolean;
  status: IActivityOccurrenceStatus;
}) => {
  const { data: isActivityGroupingEnabled } =
    useFeatureFlag("ActivityGrouping");
  return (
    <li className={styles.activityOccurrence}>
      <ActivityTitle
        activityOccurrence={activityOccurrence}
        showStatus={status === "finished" || status === "expired"}
      />
      <div className={styles.rightColumn}>
        <Time>
          {activityOccurrence.timeOfDay === "Any"
            ? timeOfDayDictionary.Any.short
            : formattedTimeSpan(
                activityOccurrence.start,
                activityOccurrence.end,
              )}
        </Time>
        {!isActivityGroupingEnabled && isEditable ? (
          <ActionDropdownMenu
            activityId={activityOccurrence.activityId}
            canMoveOccurrenceToNewVisit={!onlyActivityOccurrenceInVisit}
            routeId={routeId}
            occurrenceId={activityOccurrence.id}
            hidden={activityOccurrence.hidden}
            category={activityOccurrence.category}
            title={activityOccurrence.title}
          />
        ) : null}
      </div>
    </li>
  );
};
