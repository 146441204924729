import { FormEventHandler } from "react";
import styles from "./Form.module.scss";
import clsx from "clsx";

interface IForm {
  children: React.ReactNode;
  onSubmit: FormEventHandler<HTMLFormElement>;
}
const Form = ({ children, onSubmit }: IForm) => {
  return (
    <form onSubmit={onSubmit} className={styles.form}>
      {children}
    </form>
  );
};

interface IRow {
  children: JSX.Element | JSX.Element[];
  type?: "grid" | "flex";
}
const Row = ({ children, type = "grid" }: IRow) => {
  return <div className={clsx(styles.row, styles[type])}>{children}</div>;
};

interface ISubmitButtonWrapper {
  children: JSX.Element;
}
const SubmitButtonWrapper = ({ children }: ISubmitButtonWrapper) => {
  return <div className={styles.alignButton}>{children}</div>;
};

interface ISection {
  children: JSX.Element | JSX.Element[];
  width?: "full" | "half";
}
const Section = ({ children, width = "full" }: ISection) => {
  return (
    <section
      className={clsx([styles.section, styles[`section-width-${width}`]])}
    >
      {children}
    </section>
  );
};

const HorizontalRuler = () => {
  return <hr className={styles.horizontalRuler} />;
};

Form.Row = Row;
Form.Section = Section;
Form.SubmitButtonWrapper = SubmitButtonWrapper;
Form.HorizontalRuler = HorizontalRuler;

export default Form;
