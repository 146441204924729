import { format } from "@models/date-and-time";
import { isYesterday, isTomorrow, isToday } from "date-fns";

export const dateName = (date: Date) => {
  const dateAndMonth = format(date, "d MMMM");
  const dayDateAndMonth = format(date, "cccc, d MMMM");
  return isToday(date)
    ? `Idag, ${dateAndMonth}`
    : isTomorrow(date)
      ? `Imorgon, ${dateAndMonth}`
      : isYesterday(date)
        ? `Igår, ${dateAndMonth}`
        : dayDateAndMonth.charAt(0).toUpperCase() + dayDateAndMonth.slice(1);
};
