import { useEffect, useState } from "react";
import { sendReadReceipt } from "./matrixClient";
import { MatrixClient, MatrixEvent } from "matrix-js-sdk";

export const useSendReadReceipt = (
  matrixClient: MatrixClient | null,
  timelineEvents: MatrixEvent[],
  userId: string | null | undefined,
) => {
  const [chatIsActive, setChatIsActive] = useState(true);
  const [chatIsVisible, setChatIsVisible] = useState(true);

  const eventsFromOtherUsers = timelineEvents.filter(
    (event) => event.getSender() !== userId,
  );

  const eventToMarkAsRead =
    eventsFromOtherUsers.at(-1) ?? null;

  // useChatIsVisible
  // Essential for tablet and mobile devices since it detects screen lock and navigation to other apps
  useEffect(() => {
    const toggleChatVisibility = () => {
      setChatIsVisible(document.visibilityState === "visible");
    };

    window.addEventListener("visibilitychange", toggleChatVisibility);
    return () => {
      window.removeEventListener("visibilitychange", toggleChatVisibility);
    };
  }, []);

  // useChatIsActive
  // Essential for desktop devices since it detects if the user is currently active on the page
  useEffect(() => {
    const markChatAsActive = () => {
      setChatIsActive(true);
    };
    const markChatAsInactive = () => {
      setChatIsActive(false);
    };

    window.addEventListener("focus", markChatAsActive);
    window.addEventListener("blur", markChatAsInactive);
    return () => {
      window.removeEventListener("focus", markChatAsActive);
      window.removeEventListener("blur", markChatAsInactive);
    };
  }, []);

  useEffect(() => {
    if (!matrixClient || !chatIsActive || !chatIsVisible) return;
    sendReadReceipt(matrixClient, eventToMarkAsRead);
  }, [matrixClient, eventToMarkAsRead, chatIsActive, chatIsVisible]);
};
