import CaretRightIcon from "@components/icons/CaretRightIcon";
import { UnstyledLink, IUnstyledLink } from "@components/Link/Link";
import styles from "./NavCard.module.scss";

type INavCard = Omit<IUnstyledLink, "title"> & {
  icon?: JSX.Element;
  title: JSX.Element;
  titleWeight?: "normal" | "bold";
  children?: JSX.Element | JSX.Element[];
};

export const NavCard = ({
  icon,
  title,
  titleWeight = "normal",
  children,
  ...rest
}: INavCard) => {
  return (
    <UnstyledLink {...rest}>
      <article className={styles.navCard}>
        <div className={styles.titleRow}>
          <div className={styles.start}>
            {icon ? (
              <span className={styles.iconWrapper}>{icon}</span>
            ) : undefined}
            <span
              className={
                titleWeight === "bold" ? styles.titleBold : styles.title
              }
            >
              {title}
            </span>
          </div>
          <div className={styles.end}>
            <CaretRightIcon />
          </div>
        </div>
        {children ? (
          <div className={styles.content}>{children}</div>
        ) : undefined}
      </article>
    </UnstyledLink>
  );
};
