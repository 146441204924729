import {
  activityOccurrenceAndGroupKeys,
  allocateShiftsToActivityOccurrence,
  deallocateShiftsFromActivityOccurrence,
} from "@/api/Activities";
import styles from "./ActorsCell.module.scss";
import { RequirementChip } from "@/components/Chips/RequirementChip";
import { ShiftDropdownMenu } from "../ShiftDropdownMenu";
import { getUnfulfilledRequirements } from "../WorkBlocks/helpers";
import { AssignedShiftChip } from "@/components/Chips/AssignedShiftChip";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deducedError, displayErrorMessageAlert } from "@/Utils/ErrorUtils";
import { IActivityOccurrenceOrGroup, isGroup } from "@models/activities";
import { routeKeys } from "@/api/Routes";

type IActorsCell = {
  activityOccurrenceOrGroup: IActivityOccurrenceOrGroup;
};

export const ActorsCell = ({ activityOccurrenceOrGroup }: IActorsCell) => {
  const assignees = isGroup(activityOccurrenceOrGroup)
    ? activityOccurrenceOrGroup.assignees
    : activityOccurrenceOrGroup.status === "notReady" ||
        activityOccurrenceOrGroup.category === "PatientTask" ||
        activityOccurrenceOrGroup.category === "PatientMeasurementTask"
      ? []
      : activityOccurrenceOrGroup.assignees;

  const assignedShiftIds = assignees.map(({ id }) => id);

  const canBeAllocatedTo =
    !isGroup(activityOccurrenceOrGroup) &&
    (activityOccurrenceOrGroup.category === "VideoCall" ||
      activityOccurrenceOrGroup.category === "AdminTask") &&
    activityOccurrenceOrGroup.status !== "finished";
  const canBeDeallocatedFrom = canBeAllocatedTo;

  const queryClient = useQueryClient();
  const { mutate: allocateShiftsMutation, isPending: isAllocating } =
    useMutation({
      mutationFn: ({
        shiftIds,
        activityId,
        occurrenceId,
      }: {
        shiftIds: number[];
        activityId: string;
        occurrenceId: string;
      }) =>
        allocateShiftsToActivityOccurrence(shiftIds, activityId, occurrenceId),
      onError: (error) => {
        displayErrorMessageAlert(
          `Gick inte att allokera arbetspasset till aktivitetstillfället. ${deducedError(
            error,
          )}`,
        );
      },
      onSuccess: (_, { activityId, occurrenceId }) => {
        return Promise.all([
          queryClient.invalidateQueries({
            queryKey: activityOccurrenceAndGroupKeys.lists(),
          }),
          queryClient.invalidateQueries({
            queryKey: activityOccurrenceAndGroupKeys.detail(
              `${activityId}${occurrenceId}`,
            ),
          }),
          queryClient.invalidateQueries({
            queryKey: routeKeys.lists(),
          }),
        ]);
      },
    });

  const { mutate: deallocateShiftsMutation, isPending: isDeallocating } =
    useMutation({
      mutationFn: ({
        shiftIds,
        activityId,
        occurrenceId,
      }: {
        shiftIds: number[];
        activityId: string;
        occurrenceId: string;
      }) =>
        deallocateShiftsFromActivityOccurrence(
          shiftIds,
          activityId,
          occurrenceId,
        ),
      onError: (error) => {
        displayErrorMessageAlert(
          `Gick inte att ta bort arbetspasset från aktivitetstillfället. ${deducedError(
            error,
          )}`,
        );
      },
      onSuccess: (_, { activityId, occurrenceId }) => {
        return Promise.all([
          queryClient.invalidateQueries({
            queryKey: activityOccurrenceAndGroupKeys.lists(),
          }),
          queryClient.invalidateQueries({
            queryKey: activityOccurrenceAndGroupKeys.detail(
              `${activityId}${occurrenceId}`,
            ),
          }),
          queryClient.invalidateQueries({
            queryKey: routeKeys.lists(),
          }),
        ]);
      },
    });

  const appendShift = ({
    shiftIdToAdd,
    activityId,
    occurrenceId,
  }: {
    shiftIdToAdd: number;
    activityId: string;
    occurrenceId: string;
  }) => {
    allocateShiftsMutation({
      shiftIds: [shiftIdToAdd],
      activityId,
      occurrenceId,
    });
  };

  const removeShift = ({
    shiftIdToRemove,
    activityId,
    occurrenceId,
  }: {
    shiftIdToRemove: number;
    activityId: string;
    occurrenceId: string;
  }) => {
    deallocateShiftsMutation({
      shiftIds: [shiftIdToRemove],
      activityId,
      occurrenceId,
    });
  };

  if (!canBeAllocatedTo) {
    if (assignees.length === 0) {
      return null;
    }

    return (
      <ul className={styles.assignees}>
        {assignees.map((shift) => (
          <li key={shift.id}>
            <AssignedShiftChip
              medicalCompetence={shift.competence}
              shift={shift}
              size="small"
            />
          </li>
        ))}
      </ul>
    );
  }

  const activityOccurrence = activityOccurrenceOrGroup;

  const unfulfilledRequirements = getUnfulfilledRequirements({
    assignees,
    occurrences: [activityOccurrence],
  });

  return (
    <div className={styles.actorsCell}>
      {unfulfilledRequirements.length > 0 ? (
        <ul className={styles.competences}>
          {unfulfilledRequirements.map((unfulfilledRequirement) => (
            <li key={unfulfilledRequirement}>
              <RequirementChip
                requirement={unfulfilledRequirement}
                state="unfulfilled"
                size="small"
              />
            </li>
          ))}
        </ul>
      ) : null}
      {assignees.length > 0 ? (
        <ul className={styles.assignees}>
          {assignees.map((shift) => (
            <li key={shift.id}>
              <AssignedShiftChip
                medicalCompetence={shift.competence}
                shift={shift}
                size="small"
                {...(canBeDeallocatedFrom && {
                  onRemove: () =>
                    removeShift({
                      shiftIdToRemove: shift.id,
                      activityId: activityOccurrence.activityId,
                      occurrenceId: activityOccurrence.id,
                    }),
                })}
                disabled={isDeallocating}
              />
            </li>
          ))}
        </ul>
      ) : null}
      <div className={styles.addAssignee}>
        {canBeAllocatedTo ? (
          <ShiftDropdownMenu
            assignedShiftIds={assignedShiftIds}
            unfulfilledRequirements={unfulfilledRequirements}
            onAllocate={(shiftId: number) =>
              appendShift({
                shiftIdToAdd: shiftId,
                activityId: activityOccurrence.activityId,
                occurrenceId: activityOccurrence.id,
              })
            }
            isAllocating={isAllocating}
          />
        ) : null}
      </div>
    </div>
  );
};
