import PinIcon from "@components/icons/PinIcon";
import styles from "./Address.module.scss";
import { IAddress } from "@models/addresses";

const Address = ({
  icon = false,
  address,
  showPostalCode = false,
}: {
  icon?: boolean;
  address: IAddress;
  showPostalCode?: boolean;
}) => {
  const { addressLine1, city, postalCode } = address;
  const formattedPostalCode = `${postalCode.substring(
    0,
    3,
  )} ${postalCode.substring(3, 5)}`;
  return (
    <div className={styles.address}>
      {icon ? <PinIcon /> : <></>}
      <address>{`${addressLine1}, ${
        showPostalCode ? `${formattedPostalCode} ` : ""
      } ${city}`}</address>
    </div>
  );
};

export default Address;
