import { useFormContext } from "react-hook-form";
import Checkbox from "@/components/Checkbox/Checkbox";
import Checkboxes from "@/components/Checkbox/Checkboxes";
import { IWeekday } from "@/api/Activities";

export type IWeekdaysPickerFields = {
  weekdays: IWeekday[];
};

export const WeekdaysPicker = () => {
  const {
    formState: { errors },
    register,
  } = useFormContext<IWeekdaysPickerFields>();
  return (
    <Checkboxes
      errorMessage={errors.weekdays?.message}
      orientation="horizontal"
      legend="Upprepa på"
    >
      {/* Only one Checkbox has to hold additional logic, applies to whole registration. */}
      <Checkbox
        label={{ text: "Mån" }}
        {...register(`weekdays`, {
          required: {
            value: true,
            message: "Minst en veckodag behöver anges.",
          },
        })}
        value="Monday"
        visualStyle="framed"
      />
      <Checkbox
        label={{ text: "Tis" }}
        {...register(`weekdays`)}
        value="Tuesday"
        visualStyle="framed"
      />
      <Checkbox
        label={{ text: "Ons" }}
        {...register(`weekdays`)}
        value="Wednesday"
        visualStyle="framed"
      />
      <Checkbox
        label={{ text: "Tor" }}
        {...register(`weekdays`)}
        value="Thursday"
        visualStyle="framed"
      />
      <Checkbox
        label={{ text: "Fre" }}
        {...register(`weekdays`)}
        value="Friday"
        visualStyle="framed"
      />
      <Checkbox
        label={{ text: "Lör" }}
        {...register(`weekdays`)}
        value="Saturday"
        visualStyle="framed"
      />
      <Checkbox
        label={{ text: "Sön" }}
        {...register(`weekdays`)}
        value="Sunday"
        visualStyle="framed"
      />
    </Checkboxes>
  );
};
