import {
  IActivityOccurrenceOrGroup,
  isGroup,
  categorySchema,
} from "@models/activities";
import styles from "./UnplannedGroupTile.module.scss";
import { formattedTime } from "@/components/Time/timeUtils";
import { ActivityGroupTag } from "../TimelineTile/ActivityGroupTag/ActivityGroupTag";
import { CompetenceChip } from "@/components/Chips/CompetenceChip";
import RadioButton from "@/components/RadioButton/RadioButton";
import { medicalCompetenceDictionary } from "@models/shifts";
import { Text } from "@components/Text/Text";
import { activityCategoryDictionary } from "@/api/Activities";
import { TimeSpan } from "@/components/Time/TimeSpan";

export const UnplannedGroupTile = ({
  activityOccurrenceOrGroup,
  setActiveActivityId,
  isActiveActivity,
}: {
  activityOccurrenceOrGroup: IActivityOccurrenceOrGroup;
  setActiveActivityId: (uniqueId: string) => void;
  isActiveActivity: boolean;
}) => {
  const { start, end, timeOfDay, id } = activityOccurrenceOrGroup;

  const requiredCompetences =
    "requiredCompetences" in activityOccurrenceOrGroup
      ? activityOccurrenceOrGroup.requiredCompetences
      : undefined;

  const groupOrOccurrenceCategory = isGroup(activityOccurrenceOrGroup)
    ? categorySchema.Values.HomeVisit
    : activityOccurrenceOrGroup.category;

  const occurrences = isGroup(activityOccurrenceOrGroup)
    ? activityOccurrenceOrGroup.occurrences
    : undefined;
  const activityCount = occurrences ? occurrences.length : 0;

  const translatedRequiredCompetences = requiredCompetences
    ? requiredCompetences
        .map((competence) => medicalCompetenceDictionary[competence].sv.long)
        .join(", ")
    : undefined;

  const uniqueId = isGroup(activityOccurrenceOrGroup)
    ? activityOccurrenceOrGroup.id
    : activityOccurrenceOrGroup.id + activityOccurrenceOrGroup.activityId;

  return (
    <RadioButton
      name={id}
      value={id}
      onChange={() => setActiveActivityId(uniqueId)}
      checked={isActiveActivity}
      visualStyle="noRadioCircle"
      radioPosition="top"
      label={{
        text: `${activityOccurrenceOrGroup.patient?.name} - ${translatedRequiredCompetences} - ${activityCategoryDictionary[groupOrOccurrenceCategory]} - ${formattedTime(start)} - ${formattedTime(end)}`,
        component: (
          <div className={styles.tileContainer}>
            <ActivityGroupTag
              category={groupOrOccurrenceCategory}
              patient={activityOccurrenceOrGroup.patient?.name}
              count={activityCount}
            />
            <div className={styles.competencesAndTime}>
              {requiredCompetences && requiredCompetences.length > 0 ? (
                <ul className={styles.requiredCompetences}>
                  {requiredCompetences.map((competence) => (
                    <li key={`${competence}`}>
                      <CompetenceChip
                        competence={competence}
                        state="neutral"
                        key={`${competence}`}
                      />
                    </li>
                  ))}
                </ul>
              ) : null}
              <div className={styles.time}>
                <TimeSpan start={start} end={end} timeOfDay={timeOfDay} />
              </div>
            </div>
            {isActiveActivity && occurrences && occurrences.length > 0 ? (
              <ul>
                {occurrences.map((occurrence) => (
                  <li key={`${occurrence.id} ${occurrence.activityId}`}>
                    <Text element="span" size="small" key={occurrence.id}>
                      {occurrence.title}
                    </Text>
                  </li>
                ))}
              </ul>
            ) : null}
          </div>
        ),
      }}
    />
  );
};
