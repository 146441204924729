import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { Navigate, useLocation } from "react-router-dom";
import ErrorMessage from "@components/ErrorMessage/ErrorMessage";
import { DEVICES_URL, roles } from "@/Utils/EnvUtils";
import { AppSelector } from "./login/AppSelector";
import MedicalCircleIcon from "@components/icons/MedicalCircleIcon";
import AmbulanceCarIcon from "@components/icons/AmbulanceCarIcon";
import DevicesIcon from "@components/icons/DevicesIcon";

function Index() {
  const location = useLocation();
  const { accounts } = useMsal();
  const userRoles = accounts[0]?.idTokenClaims?.roles;
  const isAuthenticated = useIsAuthenticated();

  if (
    userRoles?.includes(roles.commandCenter) &&
    userRoles?.includes(roles.ambulating)
  ) {
    return (
      <AppSelector
        routes={[
          {
            icon: <MedicalCircleIcon />,
            label: "Center för ledningscentralen",
            to: "/commandcenter",
          },
          {
            icon: <MedicalCircleIcon />,
            label: "Center för övriga",
            to: "/center",
          },
          {
            icon: <AmbulanceCarIcon />,
            label: "Go",
            to: "/ambulating",
          },
          {
            icon: <DevicesIcon />,
            label: "Utrustning",
            to: DEVICES_URL,
          },
        ]}
      />
    );
  }
  if (userRoles?.includes(roles.commandCenter)) {
    return <Navigate to="/commandcenter" replace />;
  }
  if (userRoles?.includes(roles.ambulating)) {
    return <Navigate to="/ambulating" replace />;
  }

  if (isAuthenticated) {
    return (
      <ErrorMessage
        message={`Du har inte blivit tilldelad någon roll som ger dig behörighet att använda den här applikationen ännu. Prata med teknisk support för att lösa det.`}
      />
    );
  }

  return <Navigate to="/login" state={{ from: location }} replace />;
}

export default Index;
