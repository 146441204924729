import { ITimeOfDay, timeOfDayDictionary } from "@models/activities";
import { IText, Text } from "@components/Text/Text";
import clsx from "clsx";
import styles from "./TimeSpan.module.scss";
import { formattedTime } from "./timeUtils";

export const TimeSpan = ({
  start,
  end,
  timeOfDay,
  layout = "grid",
  color,
  size = "small",
  weight,
}: {
  start: Date;
  end: Date;
  timeOfDay: ITimeOfDay;
  layout?: "grid" | "inline";
} & Pick<IText, "color" | "size" | "weight">) => {
  if (timeOfDay === "Any") {
    return (
      <time className={clsx(styles.timespan)}>
        <Text element="span" size={size} weight={weight} color={color}>
          {timeOfDayDictionary.Any.short}
        </Text>
      </time>
    );
  }

  return (
    <time className={clsx(styles.timespan, styles[`layout-${layout}`])}>
      <Text element="span" size={size} weight={weight} color={color}>
        {formattedTime(start)}
      </Text>
      <Text element="span" size={size} weight={weight} color={color}>
        -
      </Text>
      <Text element="span" size={size} weight={weight} color={color}>
        {formattedTime(end)}
      </Text>
    </time>
  );
};
