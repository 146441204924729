import { useSearchParams } from "react-router-dom";
import { addDays, subDays } from "date-fns";
import { IconButton } from "@components/Button/Button";
import CaretLeftIcon from "@components/icons/CaretLeftIcon";
import { Heading, IHeading } from "@components/Heading/Heading";
import CaretRightIcon from "@components/icons/CaretRightIcon";
import styles from "./DaySwitcher.module.scss";
import { dateName } from "@/Utils/DateUtils";
import { useSelectedDate } from "@/Utils/useSelectedDate";
import { format } from "@models/date-and-time";

export const DaySwitcher = ({ size = "h1" }: Pick<IHeading, "size">) => {
  const [, setSearchParams] = useSearchParams();
  const selectedDate = new Date(useSelectedDate());
  const setSelectedDate = (dateTime: Date) => {
    setSearchParams({ day: format(dateTime, "yyyy-MM-dd") });
  };
  const goToPreviousDay = () => setSelectedDate(subDays(selectedDate, 1));
  const goToNextDay = () => setSelectedDate(addDays(selectedDate, 1));

  return (
    <div className={styles.dayToggle}>
      <IconButton
        aria-label="Visa aktiviteter för föregående dag"
        onClick={goToPreviousDay}
      >
        <CaretLeftIcon />
      </IconButton>
      <Heading level="h2" size={size}>
        {dateName(selectedDate)}
      </Heading>
      <IconButton
        aria-label="Visa aktiviteter för nästa dag"
        onClick={goToNextDay}
      >
        <CaretRightIcon />
      </IconButton>
    </div>
  );
};
