import clsx from "clsx";
import { forwardRef, HTMLInputTypeAttribute, useId } from "react";
import ErrorMessage from "@components/ErrorMessage/ErrorMessage";
import styles from "./InputField.module.scss";

interface IInputField {
  autoComplete?: string;
  errorMessage?: string;
  formatHint?: string;
  icon?: JSX.Element;
  name: string;
  label: string | JSX.Element;
  description?: string | JSX.Element;
  suggestions?: string[];
  type?: HTMLInputTypeAttribute;
  width?: "full" | "fit";
}

const InputField = forwardRef<HTMLInputElement | null, IInputField>(
  (
    {
      autoComplete,
      errorMessage,
      formatHint,
      name,
      label,
      description,
      icon,
      suggestions,
      type,
      width = ["date", "number", "time"].includes(type ?? "") ? "fit" : "full",
      ...rest
    },
    ref,
  ) => {
    const id = useId();
    const datalistId = useId();
    const descriptionId = useId();

    return (
      <div className={clsx(styles.inputFieldWrapper, styles[width])}>
        {icon ? (
          <div className={styles.labelWithIcon}>
            {icon}
            <label className={styles.label} htmlFor={id}>
              {label}
            </label>
          </div>
        ) : (
          <label className={styles.label} htmlFor={id}>
            {label}
          </label>
        )}
        {description ? (
          <p id={descriptionId} className={styles.description}>
            {description}
          </p>
        ) : undefined}
        {errorMessage ? (
          <div className={styles.errorMessage}>
            <ErrorMessage message={errorMessage} weight="bold" />
          </div>
        ) : undefined}
        <input
          aria-invalid={Boolean(errorMessage) || undefined}
          aria-describedby={
            description !== undefined ? descriptionId : undefined
          }
          autoComplete={autoComplete || "off"}
          className={styles.input}
          formNoValidate={true}
          id={id}
          list={suggestions ? datalistId : undefined}
          name={name}
          placeholder={formatHint}
          ref={ref}
          type={type}
          {...rest}
        />
        {suggestions ? (
          <datalist id={datalistId}>
            {suggestions.map((suggestion) => (
              <option key={suggestion} value={suggestion} />
            ))}
          </datalist>
        ) : undefined}
      </div>
    );
  },
);
InputField.displayName = "InputField";

export default InputField;
