import styles from "./Details.module.scss";
import { Text } from "@components/Text/Text";
import { formattedTimeSpan } from "@/components/Time/timeUtils";
import {
  IActivityOccurrence,
  getActivityOccurrenceStatusTimestamp,
  timeOfDayDictionary,
} from "@models/activities";
import { StatusTag } from "@/components/StatusTag/StatusTag";
import {
  IVisit,
  calculateVisitTimes,
  getVisitStatusTimestamp,
} from "@models/visits";
import { ActivityGroupTag } from "./ActivityGroupTag/ActivityGroupTag";
import { IHomeVisitActivityOccurrenceWithoutPatientAndAssignees } from "@models/activities";
import { ActivityTitle } from "@/components/ActivityTitle/ActivityTitle";
import { StatusTagWithDropdown } from "@/components/StatusTagWithDropdown/StatusTagWithDropdown";

const ActivityOccurrence = ({
  activityOccurrence,
}: {
  activityOccurrence: IHomeVisitActivityOccurrenceWithoutPatientAndAssignees;
}) => {
  const { status } = activityOccurrence;

  return (
    <div className={styles.occurrenceListItem}>
      {status === "finished" || status === "expired" ? (
        <StatusTag
          status={status}
          timestamp={getActivityOccurrenceStatusTimestamp(activityOccurrence)}
          size="tiny"
        />
      ) : null}
      <ActivityTitle activityOccurrence={activityOccurrence} />
    </div>
  );
};

type IDetails = {
  visit: IVisit;
};

export const Details = ({ visit }: IDetails) => {
  const { patient, occurrences, status } = visit;
  const statusTimestamp = getVisitStatusTimestamp(visit);
  const { start, end, isAnyTimeOfDay } = calculateVisitTimes(visit);
  const visitHasWellDefinedTime = start && end;

  return (
    <div className={styles.details}>
      <ActivityGroupTag category={"HomeVisit"} patient={patient.name} />
      <div className={styles.titleTimeDescription}>
        <Text element="div" size="large" weight="medium">
          Hembesök
        </Text>
        {isAnyTimeOfDay
          ? timeOfDayDictionary.Any.long
          : visitHasWellDefinedTime
            ? formattedTimeSpan(start, end)
            : "-"}
      </div>
      <ul title="Aktiviteter">
        {occurrences.map((occurrence) => (
          <li key={occurrence.id}>
            <ActivityOccurrence activityOccurrence={occurrence} />
          </li>
        ))}
      </ul>
      <StatusTag
        status={status}
        timestamp={statusTimestamp}
        variant="icon-and-text-with-timestamp"
      />
    </div>
  );
};

export const ActivityDetails = ({
  activityOccurrence,
}: {
  activityOccurrence: IActivityOccurrence;
}) => {
  const {
    category,
    title,
    description,
    start,
    end,
    status,
    patient,
    timeOfDay,
    activityId,
    id,
  } = activityOccurrence;
  const isAnyTimeOfDay = timeOfDay === "Any";
  const statusTimestamp =
    getActivityOccurrenceStatusTimestamp(activityOccurrence);

  return (
    <div className={styles.details}>
      <ActivityGroupTag category={category} patient={patient?.name} />
      <div className={styles.titleTimeDescription}>
        <Text element="div" size="large" weight="medium">
          {title}
        </Text>
        {isAnyTimeOfDay
          ? timeOfDayDictionary.Any.long
          : formattedTimeSpan(start, end)}
        {description ? <Text element="p">{description}</Text> : null}
      </div>
      <StatusTagWithDropdown
        status={status}
        category={category}
        activityId={activityId}
        occurrenceId={id}
        timestamp={statusTimestamp}
        variant="icon-and-text-with-timestamp"
      />
    </div>
  );
};
