import { FilledButton, PlainButton } from "../Button/Button";
import CheckCircleIcon from "../icons/CheckCircleIcon";
import CircledCrossIcon from "../icons/CircledCrossIcon";
import InformationIcon from "../icons/InformationIcon";
import WarningTriangleIcon from "../icons/WarningTriangleIcon";
import styles from "./InlineAlert.module.scss";
import clsx from "clsx";

type IInlineAlert = {
  type: "info" | "success" | "warning" | "error";
  title: string;
  message?: string;
  actions?: {
    label: string;
    onClick: () => void;
    isPrimary?: boolean;
  }[];
  placement?: "center" | "fill" | "hug";
};

export const InlineAlert = ({
  type,
  title,
  message,
  actions,
  placement = "center",
}: IInlineAlert) => {
  const renderIcon = () => {
    switch (type) {
      case "info":
        return <InformationIcon />;
      case "success":
        return <CheckCircleIcon />;
      case "warning":
        return <WarningTriangleIcon />;
      case "error":
        return <CircledCrossIcon />;
    }
  };

  return (
    <dialog
      open
      className={clsx([
        styles.inlineAlert,
        styles[`type-${type}`],
        styles[`placement-${placement}`],
      ])}
    >
      <div className={styles.icon}>{renderIcon()}</div>
      <div className={styles.content}>
        <div className={styles.header}>
          <div className={styles.title}>{title}</div>
          {message ? <p>{message}</p> : null}
        </div>
        {actions ? (
          <div className={styles.actions}>
            {actions.map((action) =>
              action.isPrimary ? (
                <FilledButton key={action.label} onClick={action.onClick}>
                  {action.label}
                </FilledButton>
              ) : (
                <PlainButton key={action.label} onClick={action.onClick}>
                  {action.label}
                </PlainButton>
              ),
            )}
          </div>
        ) : null}
      </div>
    </dialog>
  );
};
