import { recurringDictionary } from "@/forms/AddActivityForm/Scheduling/recurrenceUtils";
import { ITemplate } from "./templates";
import { timeSensitivityMap } from "@/forms/AddActivityForm/Scheduling/timeSlotsUtils";
import {
  IWeekdaysPickerFields,
  WeekdaysPicker,
} from "@/forms/AddActivityForm/Scheduling/WeekdaysPicker";
import styles from "./Template.module.scss";
import { Text } from "@components/Text/Text";
import { timeOfDayDictionary, videoTypeSchema } from "@models/activities";
import {
  DateInput,
  IDateInputFields,
} from "@/forms/AddActivityForm/Scheduling/DateInput";
import {
  ITimeSlotsFields,
  TimeSlots,
} from "@/forms/AddActivityForm/Scheduling/TimeSlots";
import { FormProvider, useForm } from "react-hook-form";
import { format } from "@models/date-and-time";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { useState } from "react";
import { z } from "zod";
import {
  ANY_TIME_OF_DAY,
  INewActivity,
  INewAdminTaskActivity,
  INewHomeVisitActivity,
  INewPatientMeasurementTaskActivity,
  INewPatientTaskActivity,
  INewVideoActivity,
  activityKeys,
  addActivity,
} from "@/api/Activities";
import { deducedError } from "@/Utils/ErrorUtils";
import { generateRandomUUID } from "@/Utils/UniqueId";
import {
  getTimespan,
  getTimeFields,
} from "@/forms/AddActivityForm/activityTimeUtils";
import { ExpansionPanel } from "@components/ExpansionPanel/ExpansionPanel";
import Form from "@/components/Form/Form";
import { FilledButton } from "@components/Button/Button";
import ErrorMessage from "@components/ErrorMessage/ErrorMessage";
import { TemplateHeading } from "./TemplateHeading";

type ITemplateWithDate = IDateInputFields;
type ITemplateWithTimeSlotsAndDate = ITimeSlotsFields & IDateInputFields;
type ITemplateWithWeekdaysAndDate = IWeekdaysPickerFields & IDateInputFields;
type ITemplateFormFields =
  | ITemplateWithDate
  | ITemplateWithTimeSlotsAndDate
  | ITemplateWithWeekdaysAndDate;

const Template = ({ template }: { template: ITemplate }) => {
  const hasTimeSlots = "timeslots" in template;
  const showWeekdaysInput = template.recurrence === recurringDictionary.custom;
  const isRecurring = template.recurrence !== recurringDictionary.never;

  const methods = useForm<ITemplateFormFields>({
    defaultValues: {
      startDate: format(new Date(), "yyyy-MM-dd"),
      timeslots: hasTimeSlots ? template.timeslots : undefined,
      weekdays: template.weekdays,
    },
  });

  const queryClient = useQueryClient();
  const { patientId } = z
    .object({ patientId: z.string().uuid() })
    .parse(useParams());
  const [templateIsExpanded, setTemplateIsExpanded] = useState(false);

  const {
    formState: { errors, isDirty },
    handleSubmit,
    reset,
    setError,
    getValues,
  } = methods;

  const { mutate, isPending: isAdding } = useMutation({
    mutationFn: (newActivity: INewActivity) => addActivity(newActivity),
    onError: (error) => {
      setError("root.server", {
        message: deducedError(error),
      });
      // Reset `isDirty` to support only showing server error when the form is not changed.
      reset(getValues(), {
        keepErrors: true,
        keepIsSubmitted: true,
        keepTouched: true,
        keepIsValid: true,
        keepSubmitCount: true,
      });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: activityKeys.all });
      setTemplateIsExpanded(false);
    },
  });

  const validateAndSubmit = handleSubmit((validatedFormData) => {
    // 1st, get the values from the template object.
    const {
      title,
      category,
      description,
      duration,
      doubleStaffing,
      recurrence,
      requiredCompetences,
      timeCategory,
      hidden,
      measurements,
      templateId,
      templateRevision,
    } = template;
    const timeSensitivity =
      "timeSensitivity" in template ? template.timeSensitivity : undefined;

    // 2nd, get the values from the form, which are the final values
    // If the form has a value, use that, otherwise use the template value.
    const { startDate, endDate } = validatedFormData;
    const timeslots =
      "timeslots" in validatedFormData
        ? validatedFormData.timeslots
        : "timeslots" in template
          ? template.timeslots
          : [];
    const weekdays =
      "weekdays" in validatedFormData
        ? validatedFormData.weekdays
        : template.weekdays;

    const isAnyTimeOfDay = timeCategory === timeOfDayDictionary.Any.long;
    const isRecurring = recurrence !== recurringDictionary.never;
    const isRecurringAsInterval =
      recurrence !== recurringDictionary.custom &&
      recurrence !== recurringDictionary.never;

    const commonActivityFields: Pick<
      INewActivity,
      | "activityId"
      | "category"
      | "description"
      | "duration"
      | "hidden"
      | "startDate"
      | "timespan"
      | "title"
    > = {
      activityId: generateRandomUUID(),
      category,
      description,
      duration: duration ?? 15,
      hidden,
      startDate,
      timespan: getTimespan({
        isAnyTimeOfDay,
        timeSensitivity,
      }),
      title,
    };

    const timeFields = isRecurring
      ? {
          ...getTimeFields({
            isRecurringAsInterval,
            isAnyTimeOfDay,
            timeslots,
            weekdays,
            recurrence,
          }),
          endDate: endDate === "" ? undefined : endDate,
        }
      : // @ts-expect-error we know that there is exactly one timeslot, but TS doesn't.
        { time: isAnyTimeOfDay ? ANY_TIME_OF_DAY : timeslots[0].time }; // We only have one timeslot if it's not recurring.

    if (category === "VideoCall") {
      const newActivity: INewVideoActivity = {
        ...commonActivityFields,
        patientId,
        category: "VideoCall",
        requiredCompetences,
        ...timeFields,
        type: videoTypeSchema.Values.DigitalVisit,
        templateId,
        templateRevision,
      };

      mutate(newActivity);
    }

    if (category === "HomeVisit") {
      const newActivity: INewHomeVisitActivity = {
        ...commonActivityFields,
        patientId,
        category: "HomeVisit",
        requiredCompetences,
        ...timeFields,
        doubleStaffing,
        templateId,
        templateRevision,
      };

      mutate(newActivity);
    }

    if (category === "PatientTask") {
      const newActivity: INewPatientTaskActivity = {
        ...commonActivityFields,
        patientId,
        category: "PatientTask",
        ...timeFields,
        templateId,
        templateRevision,
      };

      mutate(newActivity);
    }

    if (category === "AdminTask") {
      const newActivity: INewAdminTaskActivity = {
        ...commonActivityFields,
        patientId: patientId ? patientId : null, // Represent lack of patient id as null.
        category: "AdminTask",
        requiredCompetences: requiredCompetences ? requiredCompetences : [],
        ...timeFields,
        templateId,
        templateRevision,
      };

      mutate(newActivity);
    }

    if (category === "PatientMeasurementTask") {
      const newActivity: INewPatientMeasurementTaskActivity = {
        ...commonActivityFields,
        patientId,
        category: "PatientMeasurementTask",
        measurements: measurements,
        ...timeFields,
        templateId,
        templateRevision,
      };

      mutate(newActivity);
    }
  });

  return (
    <ExpansionPanel
      trigger={
        <TemplateHeading
          template={template}
          variant={templateIsExpanded ? "title-and-info" : "title-only"}
        />
      }
      triggerAriaLabel={template.title}
      isExpanded={templateIsExpanded}
      setIsExpanded={setTemplateIsExpanded}
    >
      <FormProvider {...methods}>
        <Form onSubmit={validateAndSubmit}>
          <div className={styles.form}>
            {showWeekdaysInput ? <WeekdaysPicker /> : null}
            {hasTimeSlots ? (
              <div className={styles.times}>
                <TimeSlots recurrences={Number(template.recurrencesPerDay)} />
                <Text element="p" color="faded">
                  ({timeSensitivityMap[template.timeSensitivity]}{" "}
                  tidsflexibilitet)
                </Text>
              </div>
            ) : null}

            <DateInput hasEndDate={isRecurring} />

            {errors.root?.server?.message && !isDirty ? (
              <ErrorMessage
                message={errors.root.server.message}
                weight="bold"
              />
            ) : null}

            <FilledButton type="submit" disabled={isAdding}>
              Lägg till aktivitet
            </FilledButton>
          </div>
        </Form>
      </FormProvider>
    </ExpansionPanel>
  );
};

export { Template };
