import { medicalCompetenceSchema, scheduledShiftsSchema } from "./shifts";
import { dateSchema, timestampSchema } from "./date-and-time";
import { z } from "zod";
import { listPatientSchema } from "./patients";
import { addMinutes } from "date-fns";

export const categorySchema = z.enum([
  "HomeVisit",
  "VideoCall",
  "PatientTask",
  "AdminTask",
  "PatientMeasurementTask",
]);
export type IActivityCategory = z.infer<typeof categorySchema>;
export const videoTypeSchema = z.enum(["Round", "DigitalVisit"]);

export type IVideoType = z.infer<typeof videoTypeSchema>;
export const videoTypeDictionary: Record<IVideoType, { sv: string }> = {
  Round: { sv: "Rond" },
  DigitalVisit: { sv: "Digitalt besök" },
};

export const timeOfDaySchema = z.enum(["Specific", "Any"]);
export type ITimeOfDay = z.infer<typeof timeOfDaySchema>;
export const timeOfDayDictionary = {
  Specific: {
    long: "Vid specifik tid",
    short: "Specifik tid",
  } as const,
  Any: {
    long: "När som helst under dagen",
    short: "Under dagen",
  } as const,
};

export const orderedStatuses = [
  "notReady",
  "ready",
  "ongoing",
  "finished",
  "expired",
] as const;

export const activityOccurrenceStatusSchema = z.enum(orderedStatuses);
export type IActivityOccurrenceStatus = z.infer<
  typeof activityOccurrenceStatusSchema
>;

const readyStatuses = ["ready", "ongoing", "finished", "expired"] as const;
export const readyActivityOccurrenceStatusSchema = z.enum(readyStatuses);
export const readyActivityOccurrenceStatusParser = z.preprocess(
  (backendStatus) => {
    if (backendStatus === "assigned") {
      return "ready";
    }
    return backendStatus;
  },
  readyActivityOccurrenceStatusSchema,
);
export const activityOccurrenceStatusParser = z.preprocess((backendStatus) => {
  if (backendStatus === "assigned") {
    return "ready";
  }
  return backendStatus;
}, activityOccurrenceStatusSchema);

export const activityOccurrenceStatusDictionary: Record<
  IActivityOccurrenceStatus,
  { sv: string }
> = {
  notReady: { sv: "Att planera" },
  ready: { sv: "Planerat" },
  ongoing: { sv: "Pågående" },
  finished: { sv: "Utfört" },
  expired: { sv: "Ej utfört" },
};

export const getActivityOccurrenceStatusTimestamp = (
  occurrence: Pick<
    IActivityOccurrence,
    "status" | "finishedAt" | "end" | "duration"
  >,
) => {
  const { status, finishedAt, end, duration } = occurrence;

  if (status === "finished" && finishedAt) {
    return finishedAt;
  }

  // Calculate timestamp for expired activities by adding duration to end time
  if (status === "expired") {
    if (end) {
      return addMinutes(end, duration);
    }
  }

  return undefined;
};

export const orderedMeasurementsList = [
  "bloodPressure",
  "pulse",
  "saturation",
  "temperature",
  "weight",
  "bloodGlucose",
] as const;
export const measurementSchema = z.enum(orderedMeasurementsList);

export type IMeasurementsType = z.infer<typeof measurementSchema>;

export const measurementsDictionary: Record<
  | "bloodPressure"
  | "pulse"
  | "saturation"
  | "temperature"
  | "weight"
  | "bloodGlucose",
  { sv: string }
> = {
  bloodPressure: { sv: "Blodtryck" },
  pulse: { sv: "Puls" },
  saturation: { sv: "Syresättning" },
  temperature: { sv: "Temperatur" },
  weight: { sv: "Vikt" },
  bloodGlucose: { sv: "Blodsocker" },
};

const activityOccurrenceWithPatientIdBaseSchema = z.object({
  activityId: z.string().uuid(),
  category: categorySchema,
  description: z.string().nullish(),
  duration: z.number(),
  end: dateSchema,
  finishedAt: timestampSchema,
  hidden: z.boolean(),
  id: z.string(),
  recurring: z.boolean(),
  start: dateSchema,
  timeOfDay: timeOfDaySchema,
  title: z.string(),
});

// Standardized extenders
const assignees = { assignees: scheduledShiftsSchema };
const requiredPatientId = { patientId: z.string().uuid() };
const optionalPatientId = { patientId: z.string().uuid().nullish() };
const requiredCompetences = {
  requiredCompetences: z.array(medicalCompetenceSchema),
};
const anyStatus = { status: activityOccurrenceStatusParser };
const readyStatus = { status: readyActivityOccurrenceStatusParser };

// Standardized omitters
const patientId = {
  patientId: true,
} as const;
const patientIdAndAssignees = {
  assignees: true,
  patientId: true,
} as const;
const listPatient = {
  patient: listPatientSchema,
} as const;
const nullishListPatient = {
  patient: listPatientSchema.nullish(),
} as const;

export const isGroup = (
  // Shared type allowing any combination of activityOccurrence and group
  // patientId does not exist on some activityOccurrences
  activityOccurrenceOrGroup: Omit<
    IActivityOccurrenceOrGroupWithPatientId,
    "patientId"
  >,
) => "occurrences" in activityOccurrenceOrGroup;

export const homeVisitActivityOccurrenceWithPatientIdSchema =
  activityOccurrenceWithPatientIdBaseSchema.extend({
    ...requiredPatientId,
    ...requiredCompetences,
    ...anyStatus,
    ...assignees,
    category: z.literal("HomeVisit"),
    doubleStaffing: z.boolean(),
  });
export type IHomeVisitActivityOccurrenceWithPatientId = z.infer<
  typeof homeVisitActivityOccurrenceWithPatientIdSchema
>;

export const groupOfHomeVisitActivityOccurrenceWithPatientIdSchema = z.object({
  id: z.string(),
  start: dateSchema,
  end: dateSchema,
  occurrences: z.array(homeVisitActivityOccurrenceWithPatientIdSchema),
  timeOfDay: timeOfDaySchema,
  category: categorySchema,
  doubleStaffing: z.boolean(),
  ...requiredPatientId,
  ...requiredCompetences,
  ...assignees,
});
export type IGroupOfHomeVisitActivityOccurrenceWithPatientId = z.infer<
  typeof groupOfHomeVisitActivityOccurrenceWithPatientIdSchema
>;

export const homeVisitActivityOccurrenceWithoutPatientAndAssigneesSchema =
  homeVisitActivityOccurrenceWithPatientIdSchema.omit(patientIdAndAssignees);

export type IHomeVisitActivityOccurrenceWithoutPatientAndAssignees = z.infer<
  typeof homeVisitActivityOccurrenceWithoutPatientAndAssigneesSchema
>;

export const homeVisitActivityOccurrenceSchema =
  homeVisitActivityOccurrenceWithPatientIdSchema
    .omit(patientId)
    .extend(listPatient);

export type IHomeVisitActivityOccurrence = z.infer<
  typeof homeVisitActivityOccurrenceSchema
>;

export const groupOfHomeVisitActivityOccurrenceSchema =
  groupOfHomeVisitActivityOccurrenceWithPatientIdSchema
    .omit({ occurrences: true, patientId: true })
    .extend({
      occurrences: z.array(homeVisitActivityOccurrenceSchema),
      ...listPatient,
    });
export type IGroupOfHomeVisitActivityOccurrence = z.infer<
  typeof groupOfHomeVisitActivityOccurrenceSchema
>;

export const videoActivityOccurrenceWithPatientIdSchema =
  activityOccurrenceWithPatientIdBaseSchema.extend({
    ...assignees,
    ...requiredPatientId,
    ...requiredCompetences,
    ...anyStatus,
    category: z.literal("VideoCall"),
    type: videoTypeSchema,
  });

export const videoActivityOccurrenceSchema =
  videoActivityOccurrenceWithPatientIdSchema
    .omit(patientId)
    .extend(listPatient);
export type IVideoActivityOccurrence = z.infer<
  typeof videoActivityOccurrenceSchema
>;

export const patientTaskActivityOccurrenceWithPatientIdSchema =
  activityOccurrenceWithPatientIdBaseSchema.extend({
    category: z.literal("PatientTask"),
    ...requiredPatientId,
    ...readyStatus,
  });

export const patientTaskActivityOccurrenceSchema =
  patientTaskActivityOccurrenceWithPatientIdSchema
    .omit(patientId)
    .extend(listPatient);
export type IPatientTaskActivityOccurrence = z.infer<
  typeof patientTaskActivityOccurrenceSchema
>;

export const adminTaskOccurrenceWithPatientIdSchema =
  activityOccurrenceWithPatientIdBaseSchema.extend({
    ...assignees,
    ...optionalPatientId,
    ...requiredCompetences,
    ...anyStatus,
    category: z.literal("AdminTask"),
  });

export const adminTaskOccurrenceWithPatientSchema =
  adminTaskOccurrenceWithPatientIdSchema.omit(patientId).extend(listPatient);

export const adminTaskOccurrenceSchema = adminTaskOccurrenceWithPatientIdSchema
  .omit(patientId)
  .extend(nullishListPatient);
export type IAdminTaskOccurrence = z.infer<typeof adminTaskOccurrenceSchema>;

export const patientMeasurementTaskActivityOccurrenceWithPatientIdSchema =
  activityOccurrenceWithPatientIdBaseSchema.extend({
    ...requiredPatientId,
    ...readyStatus,
    category: z.literal("PatientMeasurementTask"),
    measurements: z.array(measurementSchema),
  });

export const patientMeasurementTaskActivityOccurrenceSchema =
  patientMeasurementTaskActivityOccurrenceWithPatientIdSchema
    .omit(patientId)
    .extend(listPatient);
export type IPatientMeasurementTaskActivityOccurrence = z.infer<
  typeof patientMeasurementTaskActivityOccurrenceSchema
>;

export const activityOccurrenceWithPatientIdSchema = z.union([
  homeVisitActivityOccurrenceWithPatientIdSchema,
  videoActivityOccurrenceWithPatientIdSchema,
  patientTaskActivityOccurrenceWithPatientIdSchema,
  adminTaskOccurrenceWithPatientIdSchema,
  patientMeasurementTaskActivityOccurrenceWithPatientIdSchema,
]);
export type IActivityOccurrenceWithPatientId = z.infer<
  typeof activityOccurrenceWithPatientIdSchema
>;

export const activityOccurrenceOrGroupWithPatientIdSchema = z.union([
  homeVisitActivityOccurrenceWithPatientIdSchema,
  groupOfHomeVisitActivityOccurrenceWithPatientIdSchema,
  videoActivityOccurrenceWithPatientIdSchema,
  patientTaskActivityOccurrenceWithPatientIdSchema,
  adminTaskOccurrenceWithPatientIdSchema,
  patientMeasurementTaskActivityOccurrenceWithPatientIdSchema,
]);
export type IActivityOccurrenceOrGroupWithPatientId = z.infer<
  typeof activityOccurrenceOrGroupWithPatientIdSchema
>;

export const activityOccurrenceWithPatientSchema = z.union([
  homeVisitActivityOccurrenceSchema,
  videoActivityOccurrenceSchema,
  patientTaskActivityOccurrenceSchema,
  adminTaskOccurrenceWithPatientSchema,
  patientMeasurementTaskActivityOccurrenceSchema,
]);
export type IActivityOccurrenceWithPatient = z.infer<
  typeof activityOccurrenceWithPatientSchema
>;

export const activityOccurrenceOrGroupWithPatientSchema = z.union([
  homeVisitActivityOccurrenceSchema,
  groupOfHomeVisitActivityOccurrenceSchema,
  videoActivityOccurrenceSchema,
  patientTaskActivityOccurrenceSchema,
  adminTaskOccurrenceWithPatientSchema,
  patientMeasurementTaskActivityOccurrenceSchema,
]);
export type IActivityOccurrenceOrGroupWithPatient = z.infer<
  typeof activityOccurrenceOrGroupWithPatientSchema
>;

export const activityOccurrenceSchema = z.union([
  homeVisitActivityOccurrenceSchema,
  videoActivityOccurrenceSchema,
  patientTaskActivityOccurrenceSchema,
  adminTaskOccurrenceSchema,
  patientMeasurementTaskActivityOccurrenceSchema,
]);
export type IActivityOccurrence = z.infer<typeof activityOccurrenceSchema>;

export const activityOccurrenceOrGroupSchema = z.union([
  homeVisitActivityOccurrenceSchema,
  groupOfHomeVisitActivityOccurrenceSchema,
  videoActivityOccurrenceSchema,
  patientTaskActivityOccurrenceSchema,
  adminTaskOccurrenceSchema,
  patientMeasurementTaskActivityOccurrenceSchema,
]);
export type IActivityOccurrenceOrGroup = z.infer<
  typeof activityOccurrenceOrGroupSchema
>;
export const activityOccurrencesAndGroupsSchema = z.array(
  activityOccurrenceOrGroupSchema,
);
export type IActivityOccurrencesAndGroups = z.infer<
  typeof activityOccurrencesAndGroupsSchema
>;

export const activityOccurrenceWithoutPatientSchema = z.union([
  homeVisitActivityOccurrenceWithPatientIdSchema.omit(patientId),
  videoActivityOccurrenceWithPatientIdSchema.omit(patientId),
  patientTaskActivityOccurrenceWithPatientIdSchema.omit(patientId),
  adminTaskOccurrenceWithPatientIdSchema.omit(patientId),
  patientMeasurementTaskActivityOccurrenceWithPatientIdSchema.omit(patientId),
]);
export type IActivityOccurrenceWithoutPatient = z.infer<
  typeof activityOccurrenceWithoutPatientSchema
>;

export const activityOccurrenceOrGroupWithoutPatientSchema = z.union([
  homeVisitActivityOccurrenceWithPatientIdSchema.omit(patientId),
  groupOfHomeVisitActivityOccurrenceWithPatientIdSchema.omit(patientId),
  videoActivityOccurrenceWithPatientIdSchema.omit(patientId),
  patientTaskActivityOccurrenceWithPatientIdSchema.omit(patientId),
  adminTaskOccurrenceWithPatientIdSchema.omit(patientId),
  patientMeasurementTaskActivityOccurrenceWithPatientIdSchema.omit(patientId),
]);

export const activityOccurrencesAndGroupsWithPatientIdsSchema = z.array(
  activityOccurrenceOrGroupWithPatientIdSchema,
);

export const activityRequirementStatusSchema = z.enum([
  "neutral",
  "unfulfilled",
  "unassigned",
  "assigned",
]);
export type IActivityRequirementStatus = z.infer<
  typeof activityRequirementStatusSchema
>;
