import { useContext, useEffect, useRef, useState } from "react";
import Chat from "@components/Chat/Chat";
import { Loading } from "@components/Loading/Loading";
import ChatContext from "@chat/ChatContext";
import {
  formatTimestamp,
  makeMessages,
  useMatrixTimelineEvents,
} from "@chat/useMatrixEvents";
import { useMatrixRoom } from "@chat/useMatrixRoom";
import { useParams } from "react-router-dom";
import { z } from "zod";
import {
  ROOM_ALIAS_PREFIX_EMPLOYEE,
  sendTextMessage,
} from "@chat/matrixClient";
import { useSendReadReceipt } from "@chat/useSendReadReceipt";
import { useChatScroll } from "@chat/useChatScroll";
import { SyncState } from "matrix-js-sdk";
import { useMatrixConnectionStatus } from "@chat/useMatrixConnectionStatus";
import {
  lastEventReadByUserOtherThanUserId,
  useLastReadEventPerUser,
} from "@chat/useLastReadEvent";
import { useCurrentEmployee, useEmployee } from "@/api/Employee";
import styles from "./ChatWithGo.module.scss";
import Portrait from "@components/Portrait/Portrait";
import { Heading } from "@components/Heading/Heading";
import { Text } from "@components/Text/Text";
import ErrorMessage from "@components/ErrorMessage/ErrorMessage";
import { deducedError } from "@/Utils/ErrorUtils";
import { getHistory } from "@chat/getHistory";
import { employeeName, medicalTitle } from "@models/shifts";
import * as Sentry from "@sentry/react";
import { Link } from "@components/Link/Link";
import CaretLeftIcon from "@components/icons/CaretLeftIcon";
import { useOtherUserIsTyping } from "@chat/useOtherUserIsTyping";
import { useSendTypingNotification } from "@chat/useSendTypingNotification";

const ChatWithGo = () => {
  const { employeeId } = z
    .object({ employeeId: z.string() })
    .parse(useParams());
  const { matrixClient } = useContext(ChatContext);

  const [pendingMessage, setPendingMessage] = useState("");
  const { data: centerUser } = useCurrentEmployee();

  const { data: goEmployee, isError, error } = useEmployee(employeeId);
  const roomAlias = `#${ROOM_ALIAS_PREFIX_EMPLOYEE}${employeeId}:${matrixClient?.getDomain()}`;
  const chatConnection = useMatrixConnectionStatus(matrixClient);
  const { roomId, getRoom } = useMatrixRoom(matrixClient, roomAlias);
  const matrixUserId = matrixClient?.getUserId();
  const employeeMatrixUserId = `@${goEmployee?.matrixUserId}:${matrixClient?.getDomain()}`;
  const { otherUserIsTyping } = useOtherUserIsTyping({
    matrixClient,
    otherUserMatrixId: employeeMatrixUserId,
  });
  const { sendNoLongerTypingNotification, updateTypingState } =
    useSendTypingNotification({
      matrixClient,
      roomId,
    });

  // Reset all room specific state when chatting with another employee
  useEffect(() => {
    setPendingMessage("");
    return () => {
      sendNoLongerTypingNotification();
    };
  }, [employeeId, sendNoLongerTypingNotification]);

  const timelineEvents = useMatrixTimelineEvents(matrixClient, roomId);

  useSendReadReceipt(matrixClient, timelineEvents, matrixUserId);

  const messages = matrixUserId
    ? makeMessages(timelineEvents, matrixUserId)
    : [];

  const messagePanelRef = useRef<HTMLDivElement>(null);
  const inputTextAreaRef = useRef<HTMLTextAreaElement>(null);
  const inputTextAreaScrollHeight = inputTextAreaRef.current?.scrollHeight ?? 0;
  const { scrollMessagePanelToBottom } = useChatScroll({
    messagePanelRef,
    inputTextAreaScrollHeight,
    messages,
  });

  const sendMessage = () => {
    if (!matrixClient || !roomId) return;
    sendTextMessage(
      matrixClient,
      roomId,
      pendingMessage,
      () => setPendingMessage(""),
      centerUser ? employeeName(centerUser) : "Medoma",
      centerUser?.imageURL ?? null,
      centerUser?.competence ?? undefined,
    );
    sendNoLongerTypingNotification();
  };

  const lastReadEventBySomeNonCenterUser = lastEventReadByUserOtherThanUserId(
    useLastReadEventPerUser({ getRoom }),
    matrixUserId ?? "",
  );

  const shouldRenderInfoPanel =
    otherUserIsTyping || chatConnection === SyncState.Error;

  const room = getRoom();
  if (!matrixClient || !room || !goEmployee) {
    return <Loading message="Laddar chatt" />;
  }

  if (isError) {
    Sentry.captureException(error);
    return (
      <ErrorMessage
        message={`Kunde inte hämta anställd. ${deducedError(error)}`}
      />
    );
  }

  const goEmployeeShortDisplayName = employeeName(goEmployee, {
    format: "short",
  });
  const goEmployeeLongDisplayName = employeeName(goEmployee, {
    format: "long",
  });

  return (
    <div className={styles.chatWithGoContainer}>
      <Link to=".." className={styles.backLink}>
        <CaretLeftIcon />
        Tillbaka
      </Link>
      <div className={styles.chatConversationContainer}>
        <Chat key={goEmployee.id}>
          <Chat.HeaderPanel>
            <div className={styles.chatHeader}>
              <div className={styles.person}>
                <Portrait
                  name={goEmployeeLongDisplayName}
                  photoUrl={goEmployee.imageURL}
                  size="large"
                />
                <div className={styles.text}>
                  <Text element="span">Chatt med</Text>
                  <Heading level="h2">{goEmployeeLongDisplayName}</Heading>
                </div>
              </div>
            </div>
          </Chat.HeaderPanel>
          <Chat.MessagePanel
            getHistory={() => getHistory(matrixClient, room)}
            ref={messagePanelRef}
          >
            {messages.map((message) =>
              message.sender.isMe ? (
                <Chat.MessageGroup
                  key={message.id}
                  placement="right"
                  hideAvatar={true}
                  status={message.status}
                  timestamp={formatTimestamp(message.epochTimestamp)}
                  senderName={message.sender.name ?? "Medoma"}
                  senderTitle={medicalTitle(message.sender.title, {
                    format: "short",
                  })}
                  readIndicator={
                    message.id === lastReadEventBySomeNonCenterUser?.eventId
                      ? lastReadEventBySomeNonCenterUser.timestamp
                      : undefined
                  }
                >
                  <Chat.Message type="sent-by-me" message={message.text} />
                </Chat.MessageGroup>
              ) : (
                <Chat.MessageGroup
                  key={message.id}
                  placement="left"
                  senderName={goEmployeeShortDisplayName}
                  senderPhotoURL={goEmployee.imageURL}
                  senderTitle={medicalTitle(goEmployee.competence, {
                    format: "short",
                  })}
                  status={message.status}
                  timestamp={formatTimestamp(message.epochTimestamp)}
                >
                  <Chat.Message type="received" message={message.text} />
                </Chat.MessageGroup>
              ),
            )}
          </Chat.MessagePanel>
          {shouldRenderInfoPanel ? (
            <Chat.InfoPanel
              type={chatConnection === SyncState.Error ? "error" : "info"}
            >
              {chatConnection === SyncState.Error ? (
                "Chatten fungerar inte just nu."
              ) : (
                <Chat.IsTyping text={`${goEmployeeShortDisplayName} skriver`} />
              )}
            </Chat.InfoPanel>
          ) : (
            <></>
          )}

          <Chat.InputPanel>
            <Chat.InputTextArea
              inputTextAreaRef={inputTextAreaRef}
              value={pendingMessage}
              onChange={(event) => {
                setPendingMessage(event.currentTarget.value);
                updateTypingState({ event, pendingMessage });
              }}
              onFocus={scrollMessagePanelToBottom}
              placeholder={`Skriv ett meddelande${
                goEmployee ? ` till ${goEmployeeShortDisplayName}` : ""
              }`}
            />
            <Chat.SendMessageButton
              aria-label="Skicka"
              disabled={pendingMessage.trim() === ""}
              onClick={() => sendMessage()}
            />
          </Chat.InputPanel>
        </Chat>
      </div>
    </div>
  );
};

export default ChatWithGo;
